import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Container, Row, Col } from 'react-grid-system'
import styled from 'styled-components'
import Moment from 'react-moment'
import moment from 'moment'

import { Link, useParams, useHistory } from 'react-router-dom'

import { H2, H3 } from 'styles/Heading'
import Text from 'styles/Text'
import Box from 'styles/Box'
import Button from 'styles/Button'
import { ArrowLeftIcon, DownloadIcon, InvoiceIcon } from 'styles/Icon'
import Alert from 'styles/Alert'

import useCompany from 'hooks/useCompany'

import currency from 'helpers/currency'

import { QuoteDataType } from 'types'

import { ApplicationState } from 'reducers'

const Card = styled.div`
  padding: 2rem;
  background-color: #fff;
  border: none;
  background: white;
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.02), 0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
    0 0 17.9px rgba(0, 0, 0, 0.05);
  margin-bottom: 2rem;
`

const BookingSuccess: React.FC = () => {
  const { itineraryId }: any = useParams()
  const { itinerary, invoices }: QuoteDataType = useSelector((state: ApplicationState) => state.quote.data)
  const history = useHistory()
  const application = useCompany()

  useEffect(() => {
    if (itinerary?.status === 'open' && itinerary?.optionBookingPaid === false) {
      history.replace(`/${itineraryId}/checkout/payment?status=failed`)
    } else if (itinerary?.status === 'open' && itinerary?.optionBookingPaid === true) {
      history.replace(`/${itineraryId}/itinerary?status=option`)
    }
  }, [history])

  if (!itinerary) {
    return null
  }
  const days = moment(itinerary.endDate).diff(itinerary.startDate, 'days') + 1
  const { outstanding } = itinerary

  return (
    <Container>
      <Box m={0} mt="4rem">
        <H2>Uw {itinerary.isExcursion ? "extra's zijn" : 'reis is'} geboekt</H2>
      </Box>
      <H3>We gaan voor u aan de slag!</H3>

      <Card>
        <Row>
          <Col sm={3}>
            <strong>Naam:</strong>
          </Col>
          <Col sm={9}>
            <span>{itinerary.name}</span>
          </Col>
        </Row>
        <Row>
          <Col sm={3}>
            <strong>Vertrek:</strong>
          </Col>
          <Col sm={9}>
            <span>
              <Moment format="D MMMM YYYY">{itinerary.startDate}</Moment>
            </span>
          </Col>
        </Row>
        <Row>
          <Col sm={3}>
            <strong>Terugkomst:</strong>
          </Col>
          <Col sm={9}>
            <span>
              <Moment format="D MMMM YYYY">{itinerary.endDate}</Moment>
            </span>
          </Col>
        </Row>
        <Row>
          <Col sm={3}>
            <strong>Reisduur:</strong>
          </Col>
          <Col sm={9}>
            <span>
              {days} dag{days !== 1 && 'en'}
            </span>
          </Col>
        </Row>
        <Box m={0} mt="1rem">
          <Row>
            <Col sm={3}>
              <strong>Aantal personen:</strong>
            </Col>
            <Col sm={9}>
              <span>{itinerary.travellers}</span>
            </Col>
          </Row>
          {itinerary.travellers > 1 && (
            <Row>
              <Col sm={3}>
                <strong>Prijs per persoon:</strong>
              </Col>
              <Col sm={9}>
                <span>{currency.format(itinerary.totalCosts / (itinerary.travellers || 1))}</span>
              </Col>
            </Row>
          )}
          <Row>
            <Col sm={3}>
              <strong>Totaalkosten:</strong>
            </Col>
            <Col sm={9}>
              <strong>
                <Text color="neutral">{currency.format(itinerary.totalCosts)}</Text>
              </strong>
            </Col>
          </Row>
          {invoices.length > 0 ? (
            <Row>
              <Col sm={3}>
                <strong>Totaal voldaan:</strong>
              </Col>
              <Col sm={9}>
                <strong>{currency.format(itinerary.paid)}</strong>
              </Col>
            </Row>
          ) : (
            <></>
          )}
        </Box>

        <Box m={0} mt="1rem">
          {outstanding > 0 && invoices.length > 0 && (
            <Row>
              <Col sm={3}>
                <strong>Openstaand:</strong>
              </Col>
              <Col sm={9}>
                <Text size="lg" color="primary">
                  {currency.format(outstanding)}
                </Text>
              </Col>
            </Row>
          )}
        </Box>
        <Box m={0} mt="1rem">
          {itinerary.paid < itinerary.totalCosts && invoices.length > 0 && (
            <Link to={`/${itineraryId}/outstanding`}>
              <Button type="primary" beforeIcon={<InvoiceIcon size="1rem" />}>
                Restantbetaling voldoen
              </Button>
            </Link>
          )}
          &nbsp;
          {/* Invoice selection via book/success is currently limited to a single invoice. */}
          {invoices.length > 0 ? (
            <Link to={`/${itineraryId}/invoices/${invoices[0].invoiceNumber}`}>
              <Button type="neutral" beforeIcon={<DownloadIcon size="1rem" />}>
                Download factuur
              </Button>
            </Link>
          ) : (
            <Box m={0} mt="2rem">
              <Alert>Uw factuur wordt momenteel aangemaakt. U ontvangt hier bericht van ons over.</Alert>
            </Box>
          )}
        </Box>
      </Card>

      {!itinerary.isExcursion && (
        <Box m={0} mb="8rem" inline>
          <Box m={0} inline mr="1rem">
            <Link to={`/${itineraryId}/itinerary`}>
              <Button type="neutral" variant="dark" small beforeIcon={<ArrowLeftIcon size="1rem" />}>
                Programma
              </Button>
            </Link>
          </Box>
        </Box>
      )}
    </Container>
  )
}

export default BookingSuccess
