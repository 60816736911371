import React, { ReactElement } from 'react'
import styled, { css } from 'styled-components'

type ButtonType = {
  children: any
  type?: 'primary' | 'neutral' | 'danger'
  variant?: 'base' | 'dark' | 'light' | 'lighter' | 'lightest'
  outline?: boolean
  small?: boolean
  onClick?: () => void
  disabled?: boolean
  beforeIcon?: ReactElement | Element
  afterIcon?: ReactElement | Element
  style?: React.CSSProperties
}

const ButtonStyle = styled.button<any>`
  height: fit-content;
  cursor: pointer;
  border-radius: none;
  outline: none;
  transition: 0.25s ease-out;
  display: inline-flex;
  align-items: center;
  padding: ${({ small }) => (small ? '0.5rem 1.5rem' : '1rem 2rem')};

  background-color: ${({ theme, type, variant }) => theme.color[type!][variant!]};
  border: 2px solid ${({ theme, type, variant }) => theme.color[type!][variant!]};
  color: white;

  ${({ theme, type }) =>
    type === 'primary' &&
    css`
      :hover {
        background-color: ${theme.color[type!].light};
        border: 2px solid ${theme.color[type!].light};
        color: white;
      }
    `}

  ${({ theme, type }) =>
    type === 'neutral' &&
    css`
      :hover {
        background-color: ${theme.color[type!].base};
        border: 2px solid ${theme.color[type!].base};
        color: white;
      }
    `}

  ${({ outline, theme, type, variant }) =>
    outline &&
    css`
      background-color: transparent;
      border: 2px solid ${theme.color[type!][variant!]};
      color: ${theme.color[type!][variant!]};

      :hover:enabled {
        background-color: ${theme.color[type!][variant!]};
        border: 2px solid ${theme.color[type!][variant!]};
        color: ${variant === 'lightest' ? 'black' : 'white'};

        & svg {
          transition: 0.25s ease-out;
          fill: ${variant === 'lightest' ? 'black' : 'white'};
        }
      }
    `}

  :disabled {
    cursor: not-allowed;
    background-color: ${({ theme, type }) => theme.color[type!].lightest};
    border: 2px solid ${({ theme, type }) => theme.color[type!].lightest};
    color: ${({ theme, type }) => theme.color[type!].light};

    & svg {
      fill: ${({ theme, type }) => theme.color[type!].light};
    }
  }
`

const ButtonText = styled.span`
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  font-size: 0.8rem;
`

const BeforeIcon = styled.span`
  margin-right: 1rem;
`

const AfterIcon = styled.span`
  margin-left: 1rem;
`

const Button: React.FC<ButtonType> = ({
  children,
  type,
  variant,
  outline,
  small,
  onClick,
  disabled,
  beforeIcon,
  afterIcon,
  style
}: ButtonType) => (
  <ButtonStyle style={style} type={type} variant={variant!} outline={outline} small={small} onClick={onClick} disabled={disabled}>
    {beforeIcon && <BeforeIcon>{beforeIcon}</BeforeIcon>}
    <ButtonText>{children}</ButtonText>
    {afterIcon && <AfterIcon>{afterIcon}</AfterIcon>}
  </ButtonStyle>
)

Button.defaultProps = {
  type: 'primary',
  variant: 'base',
  small: false,
  disabled: false,
  outline: false,
}

export default Button
