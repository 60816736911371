import React, { ReactNode } from 'react'
import styled from 'styled-components'

interface RadioProps {
  label?: ReactNode
  name: string
  value: string
}

const Checkbox = styled.input`
  display: inline-block;
  margin-right: 1rem;
  margin-bottom: 0;
  width: 20px;
  height: 20px;
  accent-color: #373737;
  border: 2px solid #373737;
`

// Destructure checked from input to allow for defaultChecked to propagate to input field.
const Check: React.FC<any> = ({ input: { checked, ...input }, label, ...props }: any) => {
  return (
    <div>
      <Checkbox type="checkbox" {...input} {...props} />
      <label htmlFor={input.value}>{label}</label>
    </div>
  )
}

export default Check
