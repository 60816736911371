import React from 'react'
import Tippy, { TippyProps } from '@tippy.js/react'
import styled from 'styled-components/macro'

import 'tippy.js/dist/tippy.css'

const ThemedTippy = styled(Tippy)`
  &.tippy-tooltip {
    background-color: ${({ theme }) => theme.color.neutral.darker};
    font-weight: 500;
    letter-spacing: 1.5px;
    line-height: 1.5rem;
  }

  &.tippy-tooltip[data-placement^='top'] .tippy-arrow {
    border-top-color: ${({ theme }) => theme.color.neutral.darker};
  }
  &.tippy-tooltip[data-placement^='bottom'] .tippy-arrow {
    border-bottom-color: ${({ theme }) => theme.color.neutral.darker};
  }
  &.tippy-tooltip[data-placement^='left'] .tippy-arrow {
    border-left-color: ${({ theme }) => theme.color.neutral.darker};
  }
  &.tippy-tooltip[data-placement^='right'] .tippy-arrow {
    border-right-color: ${({ theme }) => theme.color.neutral.darker};
  }

  &.tippy-tooltip .tippy-content {
    padding: 1rem 1.5rem;
  }
`

const Tooltip: React.FC<TippyProps> = ({ children, ...props }: any) => (
  <ThemedTippy duration={300} arrow boundary="window" {...props}>
    {children}
  </ThemedTippy>
)

export default Tooltip
