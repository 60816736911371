import { combineReducers } from 'redux'
import { QuoteStateType } from 'types'
import { default as quote } from './Quote'
import { default as notifications } from './Notifications'
import { CheckoutState, default as checkout } from './Checkout'

export interface ApplicationState {
  quote: QuoteStateType
  notifications: any
  checkout: CheckoutState
}

export default combineReducers<ApplicationState>({
  quote,
  notifications,
  checkout,
})
