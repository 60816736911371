import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import useCompany from 'hooks/useCompany'

import { fetchQuote, fetchHighlightedExcursionsAndInsurances, fetchIdealIssuers } from 'actions/Quote'

import Loader from 'pages/Loader'
import Error from 'pages/Error'

import Header from 'components/Header'
import Footer from 'components/Footer'
import ContactSection from 'components/ContactSection'

import { ItineraryType, QuoteStateType } from 'types'
import { ApplicationState } from 'reducers'
import currency from 'helpers/currency'
import { Container, Row, Hidden, Col, Visible } from 'react-grid-system'
import Box from 'styles/Box'
import Text from 'styles/Text'
import moment from 'moment'
import SummaryCard from 'styles/SummaryCard'
import Moment from 'react-moment'
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion'
import styled from 'styled-components'
import { CheckoutState } from 'reducers/Checkout'
import { CREDITCARD_MULTIPLIER } from 'constants/constants'

const SummaryAccordion = styled(Accordion)`
  border: none;
  width: 100%;
  margin-bottom: 1rem;
  .summary-text {
    font-family: 'Oswald', 'Raleway', sans-serif;
    padding: 0 15px;
    font-size: 18px;
    color: #000;
    text-transform: uppercase;
    font-size: 18px;
  }
  .accordion__button {
    background-color: #f2f2f2;
  }
  .accordion__button:after {
    transform: rotate(45deg);
  }

  .accordion__button[aria-expanded='true']::after,
  .accordion__button[aria-selected='true']::after {
    margin-top: 12px;
    transform: rotate(-135deg);
  }
`

type SummaryPropsType = {
  itinerary: ItineraryType
}
const Summary: React.FC<SummaryPropsType> = ({ itinerary }: SummaryPropsType) => {
  const {
    paymentInfo: { paymentMethod },
  }: CheckoutState = useSelector((state: ApplicationState) => state.checkout)
  const days = moment(itinerary.endDate).diff(itinerary.startDate, 'days') + 1

  const isCreditcard = paymentMethod === 'creditcard'
  let restant: number = itinerary.outstanding
  const transactionCosts = isCreditcard ? restant * CREDITCARD_MULTIPLIER - restant : 0
  restant += transactionCosts
  return (
    <SummaryCard>
      <Row>
        <Col sm={6} xs={6}>
          <Box m={0} mb="0.5rem" mt="0.1rem">
            <Text size="sm" color="neutral" variant="darker">
              Naam:
            </Text>
          </Box>
        </Col>
        <Col sm={6} xs={6}>
          <Box m={0} mb="0.5rem">
            <Text size="md" color="neutral" variant="darker">
              {itinerary.name}
            </Text>
          </Box>
        </Col>
      </Row>
      <Row>
        <Col sm={6} xs={6}>
          <Box m={0} mb="0.5rem" mt="0.1rem">
            <Text size="sm" color="neutral" variant="darker">
              Vertrek:
            </Text>
          </Box>
        </Col>
        <Col sm={6} xs={6}>
          <Box m={0} mb="0.5rem">
            <Text size="md" color="neutral" variant="darker">
              <Moment format="D MMMM YYYY">{itinerary.startDate}</Moment>
            </Text>
          </Box>
        </Col>
      </Row>
      <Row>
        <Col sm={6} xs={6}>
          <Box m={0} mb="0.5rem" mt="0.1rem">
            <Text size="sm" color="neutral" variant="darker">
              Terugkomst:
            </Text>
          </Box>
        </Col>
        <Col sm={6} xs={6}>
          <Box m={0} mb="0.5rem">
            <Text size="md" color="neutral" variant="darker">
              <Moment format="D MMMM YYYY">{itinerary.endDate}</Moment>
            </Text>
          </Box>
        </Col>
      </Row>
      <Row>
        <Col sm={6} xs={6}>
          <Box m={0} mb="0.5rem" mt="0.1rem">
            <Text size="sm" color="neutral" variant="darker">
              Reisduur:
            </Text>
          </Box>
        </Col>
        <Col sm={6} xs={6}>
          <Box m={0} mb="0.5rem">
            <Text size="md" color="neutral" variant="darker">
              {days} dag{days !== 1 && 'en'}
            </Text>
          </Box>
        </Col>
      </Row>
      <Row>
        <Col sm={6} xs={6}>
          <Box m={0} mb="0.5rem" mt="0.1rem">
            <Text size="sm" color="neutral" variant="darker">
              Aantal personen:
            </Text>
          </Box>
        </Col>
        <Col sm={6} xs={6}>
          <Box m={0} mb="0.5rem">
            <Text size="md" color="neutral" variant="darker">
              {itinerary.travellers}
            </Text>
          </Box>
        </Col>
      </Row>

      <Box m={0} mt="2rem">
        <Row>
          <Col sm={6} xs={6}>
            <Box m={0} mb="0.5rem" mt="0.1rem">
              <Text size="sm" color="neutral" variant="darker">
                Prijs per persoon:
              </Text>
            </Box>
          </Col>
          <Col sm={6} xs={6}>
            <Box m={0} mb="0.5rem">
              <Text size="md" color="neutral" variant="darker">
                {currency.format(itinerary.totalCosts / (itinerary.travellers || 1))}
              </Text>
            </Box>
          </Col>
        </Row>
        <Row>
          <Col sm={6} xs={6}>
            <Box m={0} mb="0.5rem" mt="0.1rem">
              <Text size="sm" color="neutral" variant="darker">
                Totaalkosten:
              </Text>
            </Box>
          </Col>
          <Col sm={6} xs={6}>
            <Box m={0} mb="0.5rem">
              <Text size="md" color="neutral" variant="darker">
                {currency.format(itinerary.totalCosts)}
              </Text>
            </Box>
          </Col>
        </Row>
      </Box>
      <Box m={0} mt="2rem">
        <Row>
          <Col sm={6} xs={6}>
            <Box m={0} mb="0.5rem" mt="0.1rem">
              <Text size="sm" color="neutral" variant="darker">
                Totaal voldaan
              </Text>
            </Box>
          </Col>
          <Col sm={6} xs={6}>
            <Box m={0} mb="0.5rem">
              <Text size="md" color="neutral" variant="darker">
                {currency.format(itinerary.paid)}
              </Text>
            </Box>
          </Col>
        </Row>
      </Box>
    </SummaryCard>
  )
}

const SinglePageContainer = ({ children, isLandingPage }: any) => {
  const dispatch = useDispatch()
  const { itineraryId }: any = useParams()
  const { company, short } = useCompany()
  const { data, isFetching, fetchingError }: QuoteStateType = useSelector((state: ApplicationState) => state.quote)

  useEffect(() => {
    async function handleFetchQuote() {
      await dispatch(fetchQuote(itineraryId, company))
      await dispatch(fetchHighlightedExcursionsAndInsurances(itineraryId, company))
      await dispatch(fetchIdealIssuers(short))
    }
    handleFetchQuote()
  }, [dispatch, itineraryId, company, short])

  if (isFetching) {
    return <Loader />
  }

  if (fetchingError) {
    return <Error />
  }

  const { itinerary } = data

  if (!itinerary) {
    return null
  }

  return (
    <>
      <Header name="Restantbetaling" small={!isLandingPage} isCheckout={false} />
      <Container>
        <Box mt="2rem">
          <Row>
            <Col sm={12} xl={8}>
              <Visible xs sm md lg>
                <SummaryAccordion allowZeroExpanded>
                  <AccordionItem uuid="summary">
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        <span className="summary-text">Samenvatting</span>
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <Summary itinerary={itinerary}></Summary>
                    </AccordionItemPanel>
                  </AccordionItem>
                </SummaryAccordion>
              </Visible>
              <Row style={{ minHeight: '500px' }}>
                <Col>{children}</Col>
              </Row>
            </Col>
            <Hidden xs sm md lg>
              <Col sm={12} md={12} xl={4}>
                <Summary itinerary={itinerary} />
              </Col>
            </Hidden>
          </Row>
        </Box>
      </Container>
      <Footer />
      <ContactSection />
    </>
  )
}

export default SinglePageContainer
