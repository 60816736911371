import { NotificationType } from 'reducers/Notifications'

export const NOTIFICATION_HIDE = 'NOTIFICATIONS_HIDE'
export const NOTIFICATION_CREATE = 'NOTIFICATIONS_CREATE'

export const hideNotification = (id: number): any => ({
  type: NOTIFICATION_HIDE,
  id,
})

export const createNotification = ({ content, status, timeout }: Partial<NotificationType>): any => ({
  type: NOTIFICATION_CREATE,
  content,
  status,
  timeout,
})
