import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import styled from 'styled-components'

import { Hidden, Row, Col, useScreenClass } from 'react-grid-system'

import { createNotification } from 'actions/Notifications'
import { fetchQuote, selectFlight } from 'actions/Quote'
import currency from 'helpers/currency'
import useCompany from 'hooks/useCompany'

import Text from 'styles/Text'
import Box from 'styles/Box'
import { H2 } from 'styles/Heading'
import { AddIcon } from 'styles/Icon'
import Alignment from 'styles/Alignment'
import Button from 'styles/Button'

import { ApplicationState } from 'reducers'
import Tooltip from 'styles/Tooltip'
import { CheckoutCard } from 'components/Checkout/CheckoutCard/CheckoutCard'
import LabelButton from 'styles/LabelButton'
import SelectedButton from 'styles/SelectedButton/SelectedButton'
import Dropdown from 'components/Dropdown'
import { FlightOptionType, QuoteDataType } from 'types'
import LuggageDropdown from 'components/LuggageDropdown/LuggageDropdown'
import Separator from 'styles/Separator'

interface CheckoutLuggageOptionProps {
  option: FlightOptionType
  flightId: string
}

const ImageCol = styled(Col)`
  overflow: hidden;
  display: flex;
  align-items: center;
  padding: 0px !important;
  justify-content: center;
  img {
    overflow: hidden;
    object-fit: cover;
    height: 100%;
    min-width: 100%;
  }

  @media (max-width: 992px) {
    height: 300px;
  }
`

const CheckoutLuggageOption: React.FC<CheckoutLuggageOptionProps> = ({
  flightId,
  option: { id, selected, holdLuggage, holdLuggageWeight, holdLuggageUnits, holdLuggageSellAmount },
}: CheckoutLuggageOptionProps) => {
  const dispatch = useDispatch()
  const screenClass = useScreenClass()
  const { itineraryId }: any = useParams()
  const { company } = useCompany()
  const isDesktop = ['md', 'lg', 'xl', 'xxl'].includes(screenClass)
  const { itinerary }: QuoteDataType = useSelector((state: ApplicationState) => state.quote.data)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const updateLuggage = async (luggageCount?: number | Event) => {
    if (typeof luggageCount !== 'number') luggageCount = holdLuggageUnits
    setIsSubmitting(true)
    const action = await dispatch(selectFlight(flightId, id, luggageCount, true))
    setIsSubmitting(false)

    if (!action.payload || action.payload.status !== 200) {
      dispatch(
        createNotification({
          content: 'Vlucht optie selecteren mislukt. Neem contact met ons op om uw vluchtvoorkeur door te geven.',
          status: 'danger',
        })
      )
    } else {
      dispatch(fetchQuote(itineraryId, company))
    }
  }

  return (
    <CheckoutCard>
      <Row style={{ minHeight: '250px' }}>
        <ImageCol lg={3}>
          <img
            style={{ objectFit: 'contain' }}
            src="https://middenoostenreizen.s3.eu-west-1.amazonaws.com/options/koffer.webp"
            alt=""
          />
        </ImageCol>
        <Col lg={9} style={{ padding: '2rem' }}>
          <Row>
            <Col>
              <Hidden xs sm md>
                <Text color="neutral" fontWeight="bold" style={{ marginBottom: '1rem' }}>
                  Ruimbagage {holdLuggageWeight ? ` | ${holdLuggageWeight} KG` : ''}
                </Text>
              </Hidden>
              <Hidden lg xl xxl>
                <Box m={0} mb="1rem">
                  <H2>Ruimbagage {holdLuggageWeight ? ` | ${holdLuggageWeight} KG` : ''}</H2>
                </Box>
              </Hidden>

              <Box m={0} mb="1rem">
                <Text color="neutral">
                  Selecteer het aantal stuks ruimbagage dat u mee wilt nemen tijdens deze reis.
                </Text>
              </Box>
              <Hidden lg xl xxl>
                <Separator></Separator>
              </Hidden>
              {!!holdLuggageSellAmount && !holdLuggage && itinerary?.status === 'open' && (
                <Box m={0} mb="1rem">
                  <Text color="neutral" fontWeight="bold">
                    + {currency.format(holdLuggageSellAmount / (holdLuggageUnits || 1))} per stuk
                  </Text>
                </Box>
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              {!holdLuggage && (
                <>
                  <Alignment justify={isDesktop ? 'flex-end' : 'center'}>
                    <LuggageDropdown
                      disabled={isSubmitting || itinerary?.status === 'expired' || !selected}
                      count={holdLuggageUnits}
                      setCount={updateLuggage}
                      holdLuggageWeight={holdLuggageWeight || 0}
                      holdLuggageSellAmount={holdLuggageSellAmount}
                    ></LuggageDropdown>

                    {!selected && itinerary?.status === 'expired' && (
                      <Tooltip content="Deze offerte is verlopen. Neem contact met uw reisadviseur op om deze offerte bij te werken.">
                        <Button
                          type="neutral"
                          variant="lighter"
                          outline
                          beforeIcon={<AddIcon color="neutral" size="1.2rem" />}
                          disabled
                          small
                        >
                          Toevoegen
                        </Button>
                      </Tooltip>
                    )}
                  </Alignment>
                </>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </CheckoutCard>
  )
}

export default CheckoutLuggageOption
