type HumanJoin = {
  list: string[]
}

const HumanJoin: any = ({ list }: HumanJoin) => {
  if (list && list.length > 1) {
    const reduced = list.slice(0, list.length - 1)
    const last = list[list.length - 1]

    return `${reduced.toString().replace(/,/g, ', ')} en ${last}`
  }

  return list
}

export default HumanJoin
