import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'

import { fetchQuote } from 'actions/Quote'
import useCompany from 'hooks/useCompany'

import Loader from 'pages/Loader'
import Error from 'pages/Error'

import { QuoteStateType } from 'types'
import { ApplicationState } from 'reducers'

const Container = styled.div`
  width: 100%;
  height: 100vh;
`

const EmptyPageContainer: React.FC = ({ children }: any) => {
  const dispatch = useDispatch()
  const { company } = useCompany()
  const { itineraryId }: any = useParams()
  const { data, isFetching, fetchingError }: QuoteStateType = useSelector((state: ApplicationState) => state.quote)

  // Retrieve quote on mount
  useEffect(() => {
    if (!data.itinerary) {
      dispatch(fetchQuote(itineraryId, company))
    }
  }, [dispatch, itineraryId, company, data])

  if (isFetching) {
    return <Loader />
  }

  if (fetchingError) {
    return <Error />
  }

  return <Container>{children}</Container>
}

export default EmptyPageContainer
