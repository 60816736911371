export function formatList(list: string[], maxAmount = -1): string {
  if (!list.length) return ''
  const formatter = new Intl.ListFormat('nl', { style: 'long', type: 'conjunction' })
  let parts: string[] = list
  if (maxAmount > 0 && list.length > maxAmount) {
    parts = parts.slice(0, maxAmount)
    parts.push('meer')
  }
  return formatter.format(parts)
}

export function uppercaseFirst(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1)
}
