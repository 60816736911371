import React from 'react'

import Icon, { IconType } from '../Icon'

const StarIcon: React.FC<IconType> = (props) => (
  <Icon
    {...props}
    viewBox="0 0 24 24"
    width="24"
    height="24"
    preserveAspectRatio="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g transform="translate(0, -1)">
      <polygon points="12 19.0620226 4.94657697 22.7702265 6.2936609 14.9161246 0.587321804 9.35381866 8.47328849 8.20792062 12 1.06202259 15.5267115 8.20792062 23.4126782 9.35381866 17.7063391 14.9161246 19.053423 22.7702265" />
    </g>
  </Icon>
)

export default StarIcon
