import React from 'react'

import Icon, { IconType } from '../Icon'

const CreditcardIcon: React.FC<IconType> = (props) => (
  <Icon
    {...props}
    viewBox="0 0 24 24"
    width="24"
    height="24"
    preserveAspectRatio="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g transform="translate(0, 2.5)">
      <path d="M21.6,0 L2.4,0 C1.068,0 0.012,1.068 0.012,2.4 L0,16.8 C0,18.132 1.068,19.2 2.4,19.2 L21.6,19.2 C22.932,19.2 24,18.132 24,16.8 L24,2.4 C24,1.068 22.932,0 21.6,0 Z M21.6,16.8 L2.4,16.8 L2.4,9.6 L21.6,9.6 L21.6,16.8 Z M21.6,4.8 L2.4,4.8 L2.4,2.4 L21.6,2.4 L21.6,4.8 Z" />
    </g>
  </Icon>
)

export default CreditcardIcon
