import styled from 'styled-components'

const Card = styled.div`
  padding: 2rem;
  background-color: white;
  border: none;
  box-shadow: 0 12.5px 10px rgba(0, 0, 0, 0.04), 0 0 17.9px rgba(0, 0, 0, 0.05);
  margin-bottom: 2rem;
`

export default Card
