import React from 'react'
import { QuoteDataType } from 'types'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'reducers'
import { quickValidateContact } from 'helpers/validators'
import { TravellerAccordion, TravellerAccordionComponent } from 'components/TravellerAccordion/TravellerAccordion'
import { Redirect, useParams } from 'react-router-dom'
import Box from 'styles/Box'
import Alert from 'styles/Alert'

const CheckoutTravellers: React.FC = () => {
  const { itinerary }: QuoteDataType = useSelector((state: ApplicationState) => state.quote.data)
  const { itineraryId }: any = useParams()

  if (itinerary?.isExcursion) {
    return <Redirect to={`/${itineraryId}/checkout/extras`}></Redirect>
  }
  const firstInvalidTraveller: number =
    itinerary?.contacts.findIndex((contact) => {
      return !quickValidateContact(contact)
    }) ?? -1
  if (!itinerary) return null
  let travellerCount = []
  if (itinerary.onlyMainBooker) travellerCount = [0]
  else travellerCount = Array.from(Array(itinerary?.travellers ?? 0).keys())
  return (
    <>
      {itinerary?.status === 'open' && travellerCount.length && (
        <TravellerAccordion preExpanded={[firstInvalidTraveller]} allowZeroExpanded>
          {travellerCount.map((index) => {
            return (
              <TravellerAccordionComponent
                key={index}
                index={index}
                traveller={itinerary.contacts[index] ?? null}
                opportunityId={itinerary.opportunityId}
                contacts={itinerary.contacts}
                travellerCount={itinerary.travellers}
              ></TravellerAccordionComponent>
            )
          })}
        </TravellerAccordion>
      )}
      {!travellerCount?.length && (
        <Box m={0} mb="2rem">
          {itinerary?.status === 'open' && (
            <Box m={0} mt="2rem" mb="3rem">
              <Alert>
                Deze reis mist nog reizigers. Neem contact op met onze klantenservice om u verder te helpen.
              </Alert>
            </Box>
          )}
        </Box>
      )}
    </>
  )
}

export default CheckoutTravellers
