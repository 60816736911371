import styled from 'styled-components/macro'

export type LogoType = {
  variant?: 'base' | 'dark' | 'light'
  width?: string
  height?: string
}

const LogoWrapper = styled.svg<LogoType>`
  color: ${({ variant, theme }) => (variant ? theme.color.neutral[variant!] : 'white')};
  fill: ${({ variant, theme }) => (variant ? theme.color.neutral[variant!] : 'white')};
  width: ${({ width }) => width ?? '100%'};
  display: inline-block;
  max-width: 400px;
`

export default LogoWrapper
