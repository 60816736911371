import React, { useEffect, useMemo } from 'react'
import { ThemeProvider } from 'styled-components'
import { setConfiguration } from 'react-grid-system'
import { Provider } from 'react-redux'

import buildStore from 'store'

import { Helmet, HelmetProvider } from 'react-helmet-async'

import { BrowserRouter as Router } from 'react-router-dom'

import theme from 'theme'
import useCompany from 'hooks/useCompany'

import Routes from 'components/Routes'
import Notifications from 'components/Notifications'
import ScrollToTop from 'components/ScrollToTop'
import PriceWatcher from 'components/PriceWatcher'

import faviconTRL from 'assets/images/favicon-trl.svg'
import faviconMOR from 'assets/images/favicon-mor.svg'
import faviconSLR from 'assets/images/favicon-slr.svg'
import faviconTRW from 'assets/images/favicon-trw.svg'
import useItinerary from 'hooks/useItinerary'
import useZRATheme from 'hooks/useZRATheme'

setConfiguration({ containerWidths: [540, 740, 960, 1140, 1300] })

const store = buildStore()

const App: React.FC = () => {
  const application = useCompany()
  const itinerary = useItinerary()
  const zraTheme = useZRATheme()
  const applicationTheme = useMemo(
    () => (itinerary && itinerary.isZRA && itinerary.zra.color ? zraTheme : theme[application.short]),
    [itinerary]
  )

  useEffect(() => {
    if (application.short === 'trl') {
      const link = document.querySelector("link[rel~='icon']")
      if (!link) return
        ; (link as any).href = faviconTRL
    } else if (application.short === 'mor') {
      const link = document.querySelector("link[rel~='icon']")
      if (!link) return
        ; (link as any).href = faviconMOR
    } else if (application.short === 'slr') {
      const link = document.querySelector("link[rel~='icon']")
      if (!link) return
        ; (link as any).href = faviconSLR
    } else if (application.short === 'trw') {
      const link = document.querySelector("link[rel~='icon']")
      if (!link) return
        ; (link as any).href = faviconMOR
    } else if (application.short === 'bkr') {
      const link = document.querySelector("link[rel~='icon']")
      if (!link) return
        ; (link as any).href = faviconTRW
    }
  }, [])

  return (
    <>
      <HelmetProvider>
        <ThemeProvider theme={applicationTheme}>
          <Helmet>
            <title>{application.company}</title>
          </Helmet>
          <Router>
            <ScrollToTop>
              <PriceWatcher>
                <Routes />
              </PriceWatcher>
            </ScrollToTop>
          </Router>
          <Notifications />
        </ThemeProvider>
      </HelmetProvider>
    </>
  )
}

const WrappedApp: React.FC = () => (
  <Provider store={store}>
    <App />
  </Provider>
)

export default WrappedApp
