import React from 'react'

import Icon, { IconType } from '../Icon'

const MoneyIcon: React.FC<IconType> = (props) => (
  <Icon
    {...props}
    viewBox="0 0 24 24"
    width="24"
    height="24"
    preserveAspectRatio="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.125,0.6875 L0.125,17.3125 L23.875,17.3125 L23.875,0.6875 L0.125,0.6875 Z M6.0625,14.9375 C6.0625,12.9699856 4.46751442,11.375 2.5,11.375 L2.5,6.625 C4.46751442,6.625 6.0625,5.03001442 6.0625,3.0625 L17.9375,3.0625 C17.9375,5.03001442 19.5324856,6.625 21.5,6.625 L21.5,11.375 C19.5324856,11.375 17.9375,12.9699856 17.9375,14.9375 L6.0625,14.9375 Z M12,5.4375 C13.30625,5.4375 14.375,6.98125 14.375,9 C14.375,11.01875 13.30625,12.5625 12,12.5625 C10.69375,12.5625 9.625,11.01875 9.625,9 C9.625,6.98125 10.69375,5.4375 12,5.4375 Z"
      transform="translate(0, 3)"
    />
  </Icon>
)

export default MoneyIcon
