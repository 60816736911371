import React from 'react'

import Icon, { IconType } from '../Icon'

const InfoIcon: React.FC<IconType> = (props) => (
  <Icon {...props} viewBox="0 0 24 24" width="24" height="24" xmlns="http://www.w3.org/2000/svg">
    <path d="M12,24 C5.3724,24 0,18.6276 0,12 C0,5.3724 5.3724,0 12,0 C18.6276,0 24,5.3724 24,12 C24,18.6276 18.6276,24 12,24 Z M12,21.6 C17.3019336,21.6 21.6,17.3019336 21.6,12 C21.6,6.6980664 17.3019336,2.4 12,2.4 C6.6980664,2.4 2.4,6.6980664 2.4,12 C2.4,17.3019336 6.6980664,21.6 12,21.6 Z M12,9.6 C12.6627417,9.6 13.2,10.1372583 13.2,10.8 L13.2,16.8 C13.2,17.4627417 12.6627417,18 12,18 C11.3372583,18 10.8,17.4627417 10.8,16.8 L10.8,10.8 C10.8,10.1372583 11.3372583,9.6 12,9.6 Z M12,8.4 C11.3372583,8.4 10.8,7.8627417 10.8,7.2 C10.8,6.5372583 11.3372583,6 12,6 C12.6627417,6 13.2,6.5372583 13.2,7.2 C13.2,7.8627417 12.6627417,8.4 12,8.4 Z" />
  </Icon>
)

export default InfoIcon
