import styled from 'styled-components'

type TextProps = {
  color?: string
  variant?: string
  inline?: boolean
  size?: 'lg' | 'md' | 'sm'
  fontWeight?: 'light' | 'normal' | 'bold'
  textDecoration?: 'underline' | 'none'
}

const fontSizes = {
  lg: '1.5rem',
  md: '1rem',
  sm: '0.8rem',
}

const fontWeights = {
  light: 300,
  normal: 500,
  bold: 800,
}

const Text = styled.div<TextProps>`
  color: ${({ theme, color = 'primary', variant = 'base' }) => theme.color[color!][variant!]};
  font-weight: ${({ fontWeight = 'normal' }) => fontWeights[fontWeight] || 500};
  display: ${({ inline = false }) => (inline ? 'inline-block' : 'block')};
  font-size: ${({ size = 'md' }) => fontSizes[size] || '1rem'};
  text-decoration: ${({ textDecoration = 'none' }) => textDecoration};
`

export default Text
