import styled from 'styled-components'

type DotType = {
  active: boolean
}

const Dot = styled.div<DotType>`
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  display: inline-block;
  background-color: ${({ active, theme }) => (active ? theme.color.primary.base : theme.color.neutral.lighter)};
  cursor: pointer;

  & + div {
    margin-left: 1rem;
  }
`

export default Dot
