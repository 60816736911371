import React, { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'

import { Row, Col } from 'react-grid-system'
import { Field, Form as FinalForm } from 'react-final-form'

import useCompany from 'hooks/useCompany'

import { fetchQuote, updateTravellers, UPDATE_TRAVELLERS_SUCCESS } from 'actions/Quote'

import { createNotification } from 'actions/Notifications'

import { composeValidators, isRequired, isValidDate, isSingleName, isEmailAddress } from 'helpers/validators'

import { InputField, SelectField } from 'styles/Form'
import Card from 'styles/Card'
import Button from 'styles/Button'
import Tooltip from 'styles/Tooltip'

import { ItineraryContactType } from 'types'
import styled from 'styled-components'
import { setTravellersEdited, setTravellerValid } from 'actions/Checkout'
import { useParams } from 'react-router-dom'

const InputCol = styled(Col)`
  display: flex;
  align-items: flex-end;
`

type TravellerFormProps = {
  traveller?: ItineraryContactType
  index: number
  style?: React.CSSProperties
  contacts: ItineraryContactType[]
  opportunityId: string
  disabled?: boolean
  travellerCount: number
}

const TravellerForm: React.FC<TravellerFormProps> = ({
  traveller,
  index,
  style,
  opportunityId,
  contacts,
  disabled = false,
  travellerCount,
}: TravellerFormProps) => {
  const dispatch = useDispatch()
  const { short, company } = useCompany()
  const { itineraryId }: any = useParams()
  const [valid, setValid] = useState(false)
  const [modified, setModified] = useState(false)

  const formRef: any = useRef(null)

  useEffect(() => {
    dispatch(setTravellersEdited(index, false))
  }, [dispatch, index])

  useEffect(() => {
    if (modified) dispatch(setTravellersEdited(index, modified))
  }, [modified, dispatch, index])

  useEffect(() => {
    if (formRef.current) {
      setValid(!formRef.current?.getState()?.invalid)
    }
    dispatch(setTravellerValid(index, valid))
  }, [dispatch, index, valid])
  const onSubmit = async (fields: any) => {
    const newTraveller = fields.traveller
    const travellers = contacts.map((contact: ItineraryContactType) => {
      if (contact.contactId === newTraveller.contactId) return newTraveller
      return contact
    })
    const travellerExists = travellers.find(({ contactId }) => contactId === newTraveller.contactId)
    if (travellers.length < travellerCount && !travellerExists) travellers.push(newTraveller)
    const payload = {
      short,
      isTest: process.env.NODE_ENV !== 'production',
      travellers,
      opportunityId,
    }
    try {
      const res = await dispatch(updateTravellers(payload))
      if (res.type !== UPDATE_TRAVELLERS_SUCCESS) throw new Error('Error updating travellers')
      dispatch(
        createNotification({
          content: 'De gegevens zijn succesvol bijgewerkt',
          status: 'success',
        })
      )
      await dispatch(setTravellersEdited(index, false))
      setModified(false)
      await dispatch(fetchQuote(itineraryId, company))
    } catch (e) {
      dispatch(
        createNotification({
          content:
            'De gegevens zijn niet bijgewerkt. Probeer het later nog eens of neem contact op met de klantenservice.',
          status: 'danger',
        })
      )
    }
  }

  const parseValue = (value: string) => {
    setModified(true)
    return value
  }

  return (
    <FinalForm
      onSubmit={onSubmit}
      initialValues={{ traveller }}
      render={({ handleSubmit, submitting, invalid, form }) => {
        formRef.current = form
        return (
          <form>
            <Card style={{ marginBottom: '0px', boxShadow: 'none', ...style }}>
              <Row>
                <InputCol xs={12} md={6}>
                  <Field
                    parse={parseValue}
                    name="traveller.firstname"
                    label="Voornaam (conform paspoort)"
                    type="text"
                    disabled={disabled}
                    isRequired
                    component={InputField}
                    validate={composeValidators(isRequired, isSingleName)}
                  />
                </InputCol>

                <InputCol xs={12} md={6}>
                  <Field
                    parse={parseValue}
                    name="traveller.middlename"
                    label="Tussenvoegsel (conform paspoort)"
                    type="text"
                    disabled={disabled}
                    component={InputField}
                  />
                </InputCol>

                <InputCol xs={12} md={6}>
                  <Field
                    parse={parseValue}
                    name="traveller.lastname"
                    label="Achternaam (conform paspoort)"
                    type="text"
                    disabled={disabled}
                    isRequired
                    component={InputField}
                    validate={isRequired}
                  />
                </InputCol>
                <InputCol xs={12} md={6}>
                  <Field
                    parse={parseValue}
                    name="traveller.gender"
                    label="Geslacht"
                    component={SelectField}
                    isRequired
                    disabled={disabled}
                    validate={isRequired}
                  >
                    <option value="">Geen keuze</option>
                    <option value="Man">Man</option>
                    <option value="Vrouw">Vrouw</option>
                  </Field>
                </InputCol>

                <InputCol xs={12} md={12}>
                  <Field
                    parse={parseValue}
                    name="traveller.email"
                    label="E-mailadres"
                    type="text"
                    isRequired={index === 0}
                    component={InputField}
                    disabled={disabled}
                    validate={index === 0 ? composeValidators(isRequired, isEmailAddress) : undefined}
                  />
                </InputCol>

                <InputCol xs={12} md={6}>
                  <Field
                    parse={parseValue}
                    name="traveller.birthdate"
                    label="Geboortedatum"
                    placeholder="Bijv. dd-mm-jjjj"
                    type="text"
                    validate={composeValidators(isRequired, isValidDate)}
                    isRequired
                    disabled={disabled}
                    component={InputField}
                  />
                </InputCol>
                <InputCol xs={12} md={6}>
                  <Field
                    parse={parseValue}
                    name="traveller.nationality"
                    label="Nationaliteit"
                    type="text"
                    isRequired
                    disabled={disabled}
                    validate={isRequired}
                    component={InputField}
                  />
                </InputCol>

                <InputCol xs={12} md={12} lg={6}>
                  <Field
                    parse={parseValue}
                    style={{ backgroundInputColor: 'red' }}
                    name="traveller.documentNumber"
                    label="Documentnummer (conform paspoort)"
                    type="text"
                    disabled={disabled}
                    validate={isRequired}
                    component={InputField}
                    isRequired
                  />
                </InputCol>

                <InputCol xs={12} md={12} lg={6}>
                  <Field
                    parse={parseValue}
                    name="traveller.documentExpirationDate"
                    label="Paspoort vervaldatum"
                    type="text"
                    isRequired
                    disabled={disabled}
                    validate={composeValidators(isRequired, isValidDate)}
                    component={InputField}
                  />
                </InputCol>

                <InputCol xs={12} md={12} lg={12}>
                  <Field
                    parse={parseValue}
                    name="traveller.note"
                    label="Opmerking"
                    type="text"
                    disabled={disabled}
                    component={InputField}
                  />
                </InputCol>
              </Row>

              <Row justify="end">
                {!disabled && (
                  <>
                    {invalid ? (
                      <Tooltip content={<span>U heeft nog niet alle verplichte velden ingevuld.</span>}>
                        <span>
                          <Button onClick={handleSubmit} disabled small outline>
                            opslaan
                          </Button>
                        </span>
                      </Tooltip>
                    ) : (
                      <Button onClick={handleSubmit} disabled={submitting} small outline>
                        opslaan
                      </Button>
                    )}
                  </>
                )}
              </Row>
            </Card>
          </form>
        )
      }}
    />
  )
}

export default TravellerForm
