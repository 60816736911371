import { ThemeProps } from 'types'

const theme: ThemeProps = {
  color: {
    primary: {
      dark: '#586A1A',
      base: '#677C1E',
      light: '#8eab29',
      lighter: '#C5DA81',
      lightest: '#f6f9eb',
    },
    neutral: {
      darkest: '#000000',
      darker: '#2A2A2A',
      dark: '#373737',
      base: '#606060',
      light: '#B0B0B0',
      lighter: '#ebebeb',
      lightest: '#f6f6f6',
    },
    success: {
      base: '#378323',
    },
    danger: {
      base: '#A33327',
      light: '#d66256',
      lighter: '#f5d7d4',
      lightest: '#fcf5f4',
    },
  },
}

export default theme
