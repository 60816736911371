import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useScreenClass } from 'react-grid-system'
import { useParams } from 'react-router-dom'

import { QuoteDataType } from 'types'
import { ApplicationState } from 'reducers'
import { ArrowRightIcon, SuitcaseIcon, TravellersIcon } from 'styles/Icon'
import styled from 'styled-components'
import LabelButton from 'styles/LabelButton/LabelButton'
import currency from 'helpers/currency'
import LuggageDropdownPanel from './LuggageDropdownPanel'

const LuggageDropdownHoverContainer = styled.div`
  position: absolute;
  z-index: 9999999;
`

type LuggageDropdownProps = {
  count: number
  setCount: (count: number) => void
  disabled?: boolean
  holdLuggageWeight?: number
  holdLuggageSellAmount?: number
  inclusive?: boolean
}

const LuggageDropdown: React.FC<LuggageDropdownProps> = ({
  setCount,
  count,
  disabled,
  holdLuggageWeight,
  holdLuggageSellAmount,
  inclusive,
}: LuggageDropdownProps) => {
  const { itinerary }: QuoteDataType = useSelector((state: ApplicationState) => state.quote.data)
  const [active, setActive] = useState(false)
  const ref = useRef(null)
  const hidePrices = itinerary?.hidePrices && !itinerary?.showUpsell

  useEffect(() => {
    const handleClickOutside = (event: { target: any }) => {
      const current = ref.current as any
      if (current && current.contains(event.target)) return
      setActive(false)
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref])

  if (!itinerary) return null

  const open = () => {
    setActive(true)
  }

  const option = count
    ? `${count}x ${holdLuggageWeight || 0} kg ${
        !hidePrices ? `| + ${currency.format(holdLuggageSellAmount || 0)}` : ''
      }`
    : 'Geen ruimbagage'
  return (
    <div ref={ref} style={{ display: 'flex', flexDirection: 'column', marginTop: '1rem' }}>
      <LabelButton
        small
        type="neutral"
        iconMargin="0.5rem"
        style={{ width: 200 }}
        textStyle={{
          fontSize: '0.75rem',
          letterSpacing: '0px',
          ...(!!count && !inclusive && { textTransform: 'none' }),
        }}
        disabled={disabled || inclusive}
        beforeIcon={<SuitcaseIcon color="neutral" size="1rem" />}
        afterIcon={
          <ArrowRightIcon
            style={{
              transition: 'fill 0.25s ease-out 0s',
              transform: active ? 'rotate(-90deg)' : 'rotate(90deg)',
              ...(!active && { marginBottom: '-8px' }),
              ...(active && { marginTop: '-4px' }),
            }}
            color="neutral"
            size="1rem"
          />
        }
        onClick={open}
        outline
      >
        {inclusive ? 'Incl. Ruimbagage' : option}
      </LabelButton>
      {active && (
        <div style={{ position: 'relative' }}>
          <LuggageDropdownHoverContainer>
            <LuggageDropdownPanel
              maxCount={itinerary.travellers * 2}
              count={count}
              setCount={setCount}
              setActive={setActive}
              holdLuggageWeight={holdLuggageWeight}
              holdLuggageSellAmount={holdLuggageSellAmount}
              hidePrices={hidePrices}
            ></LuggageDropdownPanel>
          </LuggageDropdownHoverContainer>
        </div>
      )}
    </div>
  )
}

export default LuggageDropdown
