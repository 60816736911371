import React from 'react'

import Card from 'styles/Card'
import styled from 'styled-components'
import currency from 'helpers/currency'
import DropdownButton from './LuggageDropdownButton'

export type DropdownPanelProps = {
  setCount: (count: number) => void
  maxCount: number
  setActive: (active: boolean) => void
  holdLuggageWeight?: number
  holdLuggageSellAmount?: number
  count: number
  hidePrices?: boolean
}

const IncrementerCard = styled(Card)`
  padding: 0;
  width: 198px;
  margin-top: -3px;
  background-color: white;
  border-left: 1px solid ${({ theme }) => theme.color.neutral.base};
  border-right: 1px solid ${({ theme }) => theme.color.neutral.base};
  border-bottom: 1px solid ${({ theme }) => theme.color.neutral.base};
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
`

const Options = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0;
`

const LuggageDropdownPanel: React.FC<DropdownPanelProps> = ({
  setCount,
  maxCount,
  setActive,
  count,
  holdLuggageSellAmount,
  holdLuggageWeight,
  hidePrices,
}: DropdownPanelProps) => {
  const select = (amount: number) => {
    setCount(amount)
    setActive(false)
  }

  const cancel = () => {
    setActive(false)
  }

  return (
    <IncrementerCard>
      <Options>
        {Array.from(Array(maxCount + 1).keys()).map((i) => (
          <DropdownButton
            key={i}
            count={i}
            onClick={() => {
              select(i)
            }}
            disabled={i === count}
          >
            {i
              ? `${i}x ${holdLuggageWeight || 0} kg ${!hidePrices ? ` | + ${currency.format(((holdLuggageSellAmount || 0) / (count || 1)) * (i || 1))}` : ''
              }`
              : 'Geen Ruimbagage'}
          </DropdownButton>
        ))}
      </Options>
    </IncrementerCard>
  )
}

export default LuggageDropdownPanel
