import { createStore, applyMiddleware } from 'redux'
import axios from 'axios'
import { composeWithDevTools } from 'redux-devtools-extension'
import axiosMiddleware from 'redux-axios-middleware'
import thunk from 'redux-thunk'

import rootReducer from 'reducers'

const client = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 10000,
  responseType: 'json',
})

const buildStore = () =>
  createStore<any, any, any, any>(rootReducer, composeWithDevTools(applyMiddleware(thunk, axiosMiddleware(client))))

export default buildStore
