import styled from 'styled-components'

const Alignment = styled.a`
  color: ${({ theme }) => theme.color.primary.base};

  &:hover {
    text-decoration: underline;
  }
`

export default Alignment
