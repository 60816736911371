import { NOTIFICATION_CREATE, NOTIFICATION_HIDE } from 'actions/Notifications'
import { ReactElement } from 'react'

export interface NotificationType {
  id: number
  content: string | ReactElement | HTMLElement | Element
  status: 'success' | 'danger'
  timeout?: number
}
export interface NotificationsState {
  notifications: NotificationType[]
}

export const INITIAL_NOTIFICATIONS = {
  notifications: [],
}

const NotificationsReducer = (state: NotificationsState = INITIAL_NOTIFICATIONS, action: any): NotificationsState => {
  switch (action.type) {
    case NOTIFICATION_HIDE:
      return {
        ...state,
        notifications: state.notifications.filter((notification) => notification.id !== action.id),
      }

    case NOTIFICATION_CREATE:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          { id: new Date().getTime(), content: action.content, status: action.status, timeout: action.timeout },
        ],
      }

    default:
      return state
  }
}

export default NotificationsReducer
