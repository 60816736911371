import React, { ReactElement } from 'react'
import styled from 'styled-components'

const Line = styled.div<any>`
  width: 100%;
  height: 1px;
  padding-bottom: 1rem;
  ${({ top }) => (top ? `border-top: 2px solid #e6e6e6;` : `border-bottom: 2px solid #e6e6e6;`)}
  ${({ both }) =>
    both &&
    `margin-bottom: 1rem;
  `}
`

const Separator: React.FC<any> = ({ top, both }) => <Line top={top}></Line>

export default Line
