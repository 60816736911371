import React from 'react'
import styled, { css } from 'styled-components/macro'

import { InfoIcon, RemoveIcon, CheckIconRounded } from 'styles/Icon'
import Box from 'styles/Box'
import TransparentButton from 'styles/TransparentButton'
import { NotificationType } from 'reducers/Notifications'

interface ToastProps extends NotificationType {
  children: any
  onClose: () => void
}

const Button = styled(TransparentButton)`
  margin-right: 1rem;
`

const ToastWrapper = styled.div<any>`
  width: 100%;
  word-break: break-word;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.02), 0 6.7px 5.3px rgba(0, 0, 0, 0.048);

  ${({ status, theme }) => {
    if (status === 'success') {
      return css`
        background-color: #fff;
        font-weight: 500;
        color: ${theme.color.neutral.dark};
      `
    }

    return css`
      background-color: ${theme.color.danger.base};
      color: white;
    `
  }}
`

const ToastInner = styled.div`
  width: 100%;
  word-break: break-word;
  padding: 1rem;
  display: flex;
  align-items: center;
`

const Toast: React.FC<any> = ({ children, status, timeout, onClose }: ToastProps) => {
  if (timeout) {
    setTimeout(() => {
      onClose()
    }, timeout)
  }

  return (
    <ToastWrapper status={status}>
      <ToastInner>
        <Box m={0} mr="1rem" inline>
          {status === 'danger' && <InfoIcon color="neutral" variant="lighter" />}
          {status === 'success' && <CheckIconRounded color="success" variant="base" />}
        </Box>
        {children}
      </ToastInner>

      {onClose && (
        <Button onClick={onClose}>
          {status === 'danger' && <RemoveIcon color="neutral" variant="lighter" size="0.8rem" />}
          {status === 'success' && <RemoveIcon color="neutral" variant="dark" size="0.8rem" />}
        </Button>
      )}
    </ToastWrapper>
  )
}

export default Toast
