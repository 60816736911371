import React from 'react'

import Icon, { IconType } from '../Icon'

const ArrowRightIcon: React.FC<IconType> = (props) => (
  <Icon
    {...props}
    viewBox="0 0 24 24"
    width="24"
    height="24"
    preserveAspectRatio="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g transform="translate(-5, 0)">
      <polygon
        transform="translate(12, 12) rotate(180) translate(-12, -12)"
        points="16.5987429 23.9958485 5.33333333 11.9979242 16.5987429 1.77635684e-15 18.6666667 2.20238713 9.46723189 12 18.6666667 21.7976129 16.5987429 24"
      />
    </g>
  </Icon>
)

export default ArrowRightIcon
