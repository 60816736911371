import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { Document, Font, Page, View, StyleSheet, Text, PDFViewer as PDFViewerDefault } from '@react-pdf/renderer'

import { QuoteDataType } from 'types'
import { ApplicationState } from 'reducers'

import RalewayBold from 'assets/fonts/Raleway-Bold.ttf'
import OswaldLight from 'assets/fonts/Oswald-Light.ttf'

import useTheme from 'hooks/useTheme'

Font.register({
  family: 'OswaldLight',
  format: 'truetype',
  src: OswaldLight,
})

Font.register({
  family: 'RalewayBold',
  format: 'truetype',
  src: RalewayBold,
})

const PDFViewer = styled(PDFViewerDefault)`
  width: 100%;
  height: 100%;
  border: 0;
`

const PaymentReceipt = ({ theme }: any) => {
  const styles = StyleSheet.create({
    page: {
      flexDirection: 'row',
      backgroundColor: '#FFF',
    },
    section: {
      color: theme.color.neutral.dark,
      margin: 50,
      flexGrow: 1,
    },
    h1: {
      fontFamily: 'OswaldLight',
      color: theme.color.primary.base,
      fontSize: 25,
    },
  })

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Text style={styles.h1}>Hello World!</Text>
        </View>
      </Page>
    </Document>
  )
}

const QuotePDF: React.FC = () => {
  const theme = useTheme()
  const { itinerary }: QuoteDataType = useSelector((state: ApplicationState) => state.quote.data)

  if (!itinerary) {
    return null
  }

  return (
    <PDFViewer>
      <PaymentReceipt theme={theme} />
    </PDFViewer>
  )
}

export default QuotePDF
