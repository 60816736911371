import styled from 'styled-components'

type HeadingType = {
  theme: any
}

export const H1 = styled.h1<HeadingType>`
  font-family: 'Oswald', 'Raleway', sans-serif;
  font-weight: 300;
  color: ${({ theme }) => theme.color.primary.base};
  text-transform: uppercase;
  font-size: 3rem;
  line-height: 3.5rem;
  letter-spacing: 1.25px;
  margin: 0;
  margin-bottom: 1rem;
`

export const H2 = styled.h2<HeadingType>`
  font-family: 'Oswald', 'Raleway', sans-serif;
  font-weight: 300;
  color: ${({ theme }) => theme.color.primary.base};
  text-transform: uppercase;
  font-size: 2.25rem;
  line-height: 3rem;
  letter-spacing: 1.25px;
  margin: 0;
  margin-bottom: 1rem;

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`

export const H3 = styled.h3<HeadingType>`
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
  color: ${({ theme }) => theme.color.neutral.darker};
  font-size: 1.25rem;
  line-height: 1.25rem;
  letter-spacing: 1.25px;
  margin: 0;
  margin-bottom: 1.5rem;
`

export const H4 = styled.h4<HeadingType>`
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
  color: ${({ theme }) => theme.color.primary.base};
  font-size: 1.1rem;
  line-height: 1.1rem;
  letter-spacing: 1.25px;
  margin: 0;
  margin-bottom: 2rem;
`
