import React from 'react'
import styled from 'styled-components'

import { BrandLoaderIcon as BrandLoaderIconDefault } from 'styles/Icon'

import { LayoutCenter } from 'styles/Layout'

const BrandLoaderIcon = styled(BrandLoaderIconDefault)`
  width: 150px;
  height: 150px;
`

const Loader: React.FC = () => {
  return (
    <LayoutCenter>
      <BrandLoaderIcon color="primary" />
    </LayoutCenter>
  )
}

export default Loader
