import mor from './mor'
import otc from './otc'
import slr from './slr'
import trl from './trl'
import trw from './trw'
import bkr from './bkr'

export type ThemeProps = {
  color: Color
}

export type Color = {
  primary: ColorVariant
  neutral: ColorVariant
  success: ColorVariant
  danger: ColorVariant
}

export type ColorVariant = {
  darkest?: string
  darker?: string
  dark?: string
  base?: string
  light?: string
  lighter?: string
  lightest?: string
}

export default { mor, otc, slr, trl, trw, bkr }
