import React from 'react'
import { useSelector } from 'react-redux'
import { Link, useLocation, useParams } from 'react-router-dom'
import styled, { css } from 'styled-components'

import { QuoteDataType } from 'types'
import { ApplicationState } from 'reducers'

import getMenuItems, { MenuItem } from 'helpers/menuItems'

type MenuProps = {
  onClick: () => void
}
const ItemName = styled.strong<any>`
  font-size: ${({ small }) => (small ? '0.75rem' : '0.85rem')};
  font-weight: 600;
  text-transform: uppercase;
  text-decoration: none;
  margin-right: 1rem;
`

const ItemWrapper = styled(Link)<any>`
  text-decoration: none;
  display: flex;
  align-items: center;
  padding: 1rem 3rem;
  border-top: thin solid ${({ theme }) => theme.color.neutral.lighter};

  & ${ItemName} {
    transition: 0.25s ease-out;
    color: ${({ theme }) => theme.color.neutral.dark};
  }

  & svg {
    transition: 0.25s ease-out;
    fill: ${({ theme }) => theme.color.neutral.dark};
    stroke: ${({ theme }) => theme.color.neutral.dark};
    margin-right: 0.5rem;
  }

  ${({ theme, active }) =>
    active &&
    css`
      & ${ItemName} {
        color: ${theme.color.primary.base};
      }

      & svg {
        fill: ${theme.color.primary.base};
        stroke: ${theme.color.primary.base};
      }
    `}

  :hover {
    & ${ItemName} {
      color: ${({ theme }) => theme.color.primary.base};
    }

    & svg {
      fill: ${({ theme }) => theme.color.primary.base};
      stroke: ${({ theme }) => theme.color.primary.base};
    }
  }
`

const Menu: React.FC<MenuProps> = ({ onClick }: MenuProps) => {
  const { pathname } = useLocation()
  const { itineraryId }: any = useParams()

  const { itinerary }: QuoteDataType = useSelector((state: ApplicationState) => state.quote.data)

  if (!itinerary) {
    return null
  }

  const menuItems = getMenuItems(
    itinerary?.status === 'booked',
    itinerary?.isZRA,
    itinerary?.adjustTravellers,
    itinerary?.hidePrices,
    itinerary?.hideDates,
    itinerary?.isExcursion
  )

  const currentPageIndex: number = menuItems.findIndex((item: MenuItem) => pathname.includes(item.link))

  return (
    <>
      {menuItems.map((item, index) => {
        if (item.display) {
          return (
            <React.Fragment key={index}>
              <ItemWrapper to={`/${itineraryId}/${item.link}`} active={index === currentPageIndex} onClick={onClick}>
                {item.icon}
                <ItemName>{item.name}</ItemName>
              </ItemWrapper>
            </React.Fragment>
          )
        }
      })}
    </>
  )
}

export default Menu
