import React, { ReactElement } from 'react'
import styled from 'styled-components'

import Box from 'styles/Box'

type LabelProps = {
  icon?: ReactElement
  text: string
  color?: 'primary' | 'neutral' | 'success' | 'danger'
  variant?: 'base' | 'light' | 'dark'
}

const LabelWrapper = styled.div<any>`
  display: flex;
  align-content: center;
  align-items: center;
  text-align: right;
  justify-content: flex-end;
  font-size: 1rem;
  color: ${({ theme, color, variant }) => theme.color[color][variant]};

  & > svg {
    color: ${({ theme, color, variant }) => theme.color[color][variant]};
    fill: ${({ theme, color, variant }) => theme.color[color][variant]};
  }
`

const Label: React.FC<LabelProps> = ({ icon, text, color, variant }: LabelProps) => (
  <LabelWrapper color={color} variant={variant}>
    {icon ?? <></>}
    <Box m={0} ml="0.5rem">
      <strong>{text}</strong>
    </Box>
  </LabelWrapper>
)

Label.defaultProps = {
  color: 'neutral',
  variant: 'base',
}

export default Label
