import React from 'react'

import Icon, { IconType } from '../Icon'

const BrandLoaderIcon: React.FC<IconType> = (props) => (
  <Icon
    {...props}
    viewBox="0 0 24 24"
    width="24"
    height="24"
    preserveAspectRatio="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g transform="translate(0.000000, -5.000000)">
      <path
        d="M95.5,30.8l-77.9-5.2c-0.8,0-1.5-0.4-2-1C10.6,19,6,13.2,1.8,7.3C0.3,5.2,2.1,2.5,4.6,2.9l91.7,16.5
        c1.5,0.3,2.5,1.6,2.4,3.1c-0.1,1.7-0.2,3.5-0.2,5.2C98.6,29.6,97.2,31,95.5,30.8L95.5,30.8z"
        transform="translate(2.5, 9) scale(0.16)"
      ></path>
      <path
        d="M100.6,46.6c-0.4-2.1-0.8-4.1-1.1-6.2c-0.2-1.4-1.4-2.5-2.8-2.6l-63.3-2.2c-2.7-0.1-4,3.1-2,4.9
        c4.4,4,9.1,7.9,13.9,11.7c0.5,0.4,1.2,0.6,1.9,0.6l50.6-2.6C99.7,50.1,101,48.4,100.6,46.6L100.6,46.6z"
        transform="translate(2.6, 9.2) scale(0.16)"
      ></path>
      <path
        d="M105.7,63.5c-0.8-1.9-1.5-3.8-2.2-5.8c-0.3-0.8-1.1-1.4-1.9-1.3l-36.4,3.7c-2.7,0.3-3.6,3.7-1.3,5.2
        c4,2.5,8.1,5,12.3,7.3c0.6,0.3,1.3,0.4,2,0.3l25.3-5.3C105.4,67.2,106.4,65.2,105.7,63.5L105.7,63.5z"
        transform="translate(2.7, 9.4) scale(0.16)"
      ></path>
      <path
        d="M111,74.1c-0.7-1.1-2-1.7-3.3-1.4l-21,5.1c-0.1,0-0.1,0.2,0,0.2c5.4,2.6,10.8,5,16.2,7.2
        c0.5,0.2,0.9,0.2,1.4,0.2c1.5-0.2,4.3-0.7,8.4-1.6c2-0.5,2.8-2.8,1.7-4.4C113.2,77.6,112,75.7,111,74.1L111,74.1z"
        transform="translate(2.8, 9.6) scale(0.16)"
      ></path>
    </g>
  </Icon>
)

export default BrandLoaderIcon
