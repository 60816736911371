import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { Hidden } from 'react-grid-system'
import { Swipeable } from 'react-swipeable'
import { TransitionGroup, CSSTransition } from 'react-transition-group'

import { H2 } from 'styles/Heading'
import Dot from 'styles/Dot'
import SummaryImage from 'styles/SummaryImage'
import Box from 'styles/Box'

type SummaryProps = {
  summary: SummaryType[]
}

type SummaryType = {
  images: string[]
  title: string
  description: string
}

const SummaryWrapper = styled.div`
  margin: 0 auto;
  width: 800px;
  margin-top: 10rem;
  position: relative;
  margin-bottom: 10rem;
  text-align: center;
  max-width: 100%;

  @media (max-width: 768px) {
    max-width: 100%;
  }
`

const CSSTransitionStyle = styled(CSSTransition)`
  transition: opacity 0.5s;

  &.summary-enter {
    opacity: 0;
    position: absolute;
    margin: 0 auto;
    text-align: center;
    width: 100%;
    max-width: 100%;

    @media (max-width: 768px) {
      max-width: 100%;
    }
  }

  &.summary-enter-active {
    opacity: 1;
  }

  &.summary-exit {
    opacity: 1;
  }

  &.summary-exit-active {
    opacity: 0;
  }
`

const ImagesWrapper = styled.div<any>`
  display: grid;
  height: 30rem;
  margin-bottom: 3rem;
  width: 100%;
  overflow: hidden;
  

  ${({ length }) =>
    length === 2 &&
    css`
      grid-template-columns: calc(50% - 1rem) calc(50% - 1rem);
      grid-gap: 2rem;
    `}

  ${({ length }) =>
    length === 3 &&
    css`
      & :first-child {
        grid-row-end: span 2;
      }

      grid-template-columns: calc(50% - 1rem) calc(50% - 1rem);
      grid-template-rows: calc(50% - 1rem);
      grid-gap: 2rem;
    `}

  ${({ length }) =>
    length === 4 &&
    css`
      grid-template-columns: calc(50% - 1rem) calc(50% - 1rem);
      grid-template-rows: calc(50% - 1rem);
      grid-gap: 2rem;
    `}

  @media (max-width: 768px) {
    & :first-child {
        grid-row-end: auto;
      }

    grid-template-columns: 100%;
    grid-template-rows: 20rem;
    height: ${({ length }) => `${20 * length}rem`};
  }
`

const Summary: any = ({ summary }: SummaryProps) => {
  const [itemIndex, setItemIndex] = useState<number>(0)

  const { images, title, description } = summary[itemIndex]

  return (
    <SummaryWrapper>
      <Swipeable
        onSwipedLeft={() => {
          if (itemIndex < summary.length - 1) {
            setItemIndex(itemIndex + 1)
          }
        }}
        onSwipedRight={() => {
          if (itemIndex) {
            setItemIndex(itemIndex - 1)
          }
        }}
        delta={50}
        trackMouse
      >
        <Hidden xl xxl>
          <Box mb="10rem" align="center">
            <H2>{title}</H2>
            <p>{description}</p>
            <div>
              {summary.length > 1 &&
                summary.map((_item: any, index: number) => (
                  <Dot active={itemIndex === index} key={index} onClick={() => setItemIndex(index)} />
                ))}
            </div>
          </Box>
        </Hidden>

        <TransitionGroup>
          <CSSTransitionStyle classNames="summary" key={itemIndex} timeout={500} unmountOnExit>
            <ImagesWrapper length={images.length}>
              {images && images.map((image: string, index: number) => <SummaryImage key={index} source={image} />)}
            </ImagesWrapper>
          </CSSTransitionStyle>
        </TransitionGroup>

        <Hidden xs sm md lg>
          <H2>{title}</H2>
          <p>{description}</p>
          <div>
            {summary.length > 1 &&
              summary.map((_item: any, index: number) => (
                <Dot active={itemIndex === index} key={index} onClick={() => setItemIndex(index)} />
              ))}
          </div>
        </Hidden>
      </Swipeable>
    </SummaryWrapper>
  )
}

export default Summary
