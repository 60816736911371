import React from 'react'

import Icon, { IconType } from '../Icon'

const PhoneIcon: React.FC<IconType> = (props) => (
  <Icon
    {...props}
    viewBox="0 0 24 24"
    width="24"
    height="24"
    preserveAspectRatio="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M13.1834545,13.183809 C10.9220259,15.443809 8.30202589,17.6066662 7.2663116,16.5709519 C5.78488303,15.0895233 4.87059732,13.7980947 1.60202589,16.4252376 C-1.66511697,19.0509519 0.844883031,20.8023805 2.28059732,22.2366662 C3.93774017,23.893809 10.114883,22.3252376 16.2205973,16.2209519 C22.324883,10.1152376 23.8891687,3.93809474 22.2305973,2.28095189 C20.794883,0.84380903 19.0520259,-1.6647624 16.4263116,1.60238046 C13.7991687,4.86952332 15.0891687,5.78380903 16.5734545,7.26666617 C17.604883,8.30238046 15.4434545,10.9223805 13.1834545,13.183809 Z" />
  </Icon>
)

export default PhoneIcon
