import React from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { PDFViewer as PDFViewerDefault } from '@react-pdf/renderer'

import { QuoteDataType, InvoiceType } from 'types'
import { ApplicationState } from 'reducers'

import PaymentReceipt from 'components/PaymentReceipt'

import useTheme from 'hooks/useTheme'

const PDFViewer = styled(PDFViewerDefault)`
  width: 100%;
  height: 100%;
  border: 0;
`

const InvoicePDF: React.FC = () => {
  const theme = useTheme()
  const { invoiceId }: any = useParams()
  const { itinerary, invoices }: QuoteDataType = useSelector((state: ApplicationState) => state.quote.data)

  if (!itinerary || !invoices) {
    return null
  }

  const selectedInvoice = invoices.find((invoice: InvoiceType) => invoiceId === invoice.invoiceNumber)

  if (!selectedInvoice) {
    return null
  }

  return (
    <PDFViewer>
      <PaymentReceipt theme={theme} itinerary={itinerary} invoice={selectedInvoice} />
    </PDFViewer>
  )
}

export default InvoicePDF
