import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 2rem;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 1rem;
  padding-right: 1rem;
  text-align: center;
  transition: background-color 0.25s ease-out;

  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.color.neutral.light};
  }

  &.disabled {
    background-color: ${({ theme }) => theme.color.neutral.light};
  }
`

const TextSpan = styled.span`
  text-align: center;
  width: 100%;
`

const LuggageDropdownButton: React.FC<any> = ({ children, onClick, disabled }: any) => {
  return (
    <Container className={disabled ? 'disabled' : ''} onClick={disabled ? () => {} : onClick}>
      <TextSpan>{children}</TextSpan>
    </Container>
  )
}

export default LuggageDropdownButton
