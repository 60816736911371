import React from 'react'
import { useSelector } from 'react-redux'
import { useParams, Link, useLocation } from 'react-router-dom'
import { Hidden, useScreenClass } from 'react-grid-system'
import styled, { css } from 'styled-components'

import { QuoteDataType } from 'types'
import { ApplicationState } from 'reducers'

import getMenuItems, { MenuItem } from 'helpers/menuItems'
import Box from 'styles/Box'

type ItemLinkProps = {
  to: string
}

const MenuWrapper = styled.div<any>`
  display: inline-flex;
`
const ArrowTail = styled.span<any>`
  width: 0;
  height: 0;
  border-top: 2rem solid transparent;
  border-left: 1rem solid white;
  border-bottom: 2rem solid transparent;
  margin-right: 1rem;
`

const ArrowPoint = styled.span<any>`
  width: 0;
  height: 0;
  border-top: 2rem solid transparent;
  border-left: 1rem solid ${({ theme }) => theme.color.primary.base};
  border-bottom: 2rem solid transparent;
  margin-right: 1rem;
`

const ItemName = styled.strong<any>`
  font-size: ${({ small }) => (small ? '0.75rem' : '0.85rem')};
  font-weight: 600;
  text-transform: uppercase;
  text-decoration: none;
  margin-right: ${({ small }) => (small ? '0.75rem' : '1rem')};
  letter-spacing: ${({ small }) => (small ? '0.75px' : '1px')};

  ${({ tail }) =>
    tail &&
    css`
      margin-right: 0.5rem;
    `}
`

const ActiveItemWrapper = styled(Link)<ItemLinkProps>`
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  background-color: ${({ theme }) => theme.color.primary.base};

  & ${ItemName} {
    color: white;
  }

  & svg {
    fill: white;
    stroke: white;
    margin-right: 0.5rem;
  }
`

const ItemWrapper = styled(Link)<ItemLinkProps>`
  text-decoration: none;
  margin-left: 0.5rem;
  display: inline-flex;
  align-items: center;

  & ${ItemName} {
    transition: 0.25s ease-out;
    color: ${({ theme }) => theme.color.neutral.dark};
  }

  & svg {
    transition: 0.25s ease-out;
    fill: ${({ theme }) => theme.color.neutral.dark};
    margin-right: 0.5rem;
  }

  :hover {
    & ${ItemName} {
      color: ${({ theme }) => theme.color.primary.base};
    }

    & svg {
      fill: ${({ theme }) => theme.color.primary.base};
    }
  }
`

const Menu: React.FC = () => {
  const { pathname } = useLocation()
  const { itineraryId }: any = useParams()
  const screenClass = useScreenClass()

  const { itinerary }: QuoteDataType = useSelector((state: ApplicationState) => state.quote.data)

  if (!itinerary) {
    return null
  }

  const largeScreen = ['lg', 'xl'].includes(screenClass)

  const menuItems = getMenuItems(
    itinerary?.status === 'booked',
    itinerary?.isZRA,
    itinerary?.adjustTravellers,
    itinerary.hidePrices,
    itinerary.hideDates,
    itinerary.isExcursion
  )

  const currentPageIndex: number = menuItems.findIndex((item: MenuItem) => pathname.includes(item.link))

  return (
    <MenuWrapper>
      <Hidden xl>
        <Box m={0} ml="3rem">
          &nbsp;
        </Box>
      </Hidden>
      <Hidden sm md lg>
        <Box m={0} ml="2rem">
          &nbsp;
        </Box>
      </Hidden>

      {menuItems.map((item, index) => {
        if (index === currentPageIndex) {
          if (item.display) {
            return (
              <React.Fragment key={index}>
                <ActiveItemWrapper to={`/${itineraryId}/${item.link}`}>
                  <ArrowTail />
                  <Hidden sm md lg xl>
                    {item.icon}
                  </Hidden>
                  <ItemName small={largeScreen} tail>
                    {item.name}
                  </ItemName>
                </ActiveItemWrapper>
                <ArrowPoint />
              </React.Fragment>
            )
          }
        }

        if (item.display) {
          return (
            <React.Fragment key={index}>
              <ItemWrapper to={`/${itineraryId}/${item.link}`}>
                <ItemName small={largeScreen}>{item.name}</ItemName>
              </ItemWrapper>
            </React.Fragment>
          )
        }

        return <React.Fragment key={index}></React.Fragment>
      })}
    </MenuWrapper>
  )
}

export default Menu
