import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 2rem;
  padding: 0 0.5rem;

  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.color.neutral.light};
  }
`

const TextSpan = styled.span`
  text-align: left;
  width: 100%;
`

const DropdownButton: React.FC<any> = ({ amount, onClick }: any) => {
  return (
    <Container onClick={onClick}>
      <TextSpan>
        {amount} {amount === 1 ? 'persoon' : 'personen'}
      </TextSpan>
    </Container>
  )
}

export default DropdownButton
