import React from 'react'
import styled from 'styled-components'

import { BrandIcon as BrandIconDefault } from 'styles/Icon'
import Text from 'styles/Text'

const ErrorWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Centered = styled.div`
  text-align: center;
  font-weight: 600;
`

const BrandIcon = styled(BrandIconDefault)`
  width: 150px;
  height: 150px;
  margin-right: 45px;
  margin-bottom: -15px;
`

const Error: React.FC = () => (
  <ErrorWrapper>
    <Centered>
      <BrandIcon color="primary" />
      <Text color="neutral" variant="darker">
        Uw reis kan niet geladen worden
      </Text>
    </Centered>
  </ErrorWrapper>
)

export default Error
