import React from 'react'

import Icon, { IconType } from '../Icon'

const DownloadIcon: React.FC<IconType> = (props) => (
  <Icon
    {...props}
    viewBox="0 0 24 24"
    width="24"
    height="24"
    preserveAspectRatio="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g transform="translate(-1.000000, 0.000000)">
      <path d="M2.41304348,21.4736842 L21.5869565,21.4736842 C22.3433465,21.4736842 22.9565217,22.0392193 22.9565217,22.7368421 C22.9565217,23.4344649 22.3433465,24 21.5869565,24 L2.41304348,24 C1.65665349,24 1.04347826,23.4344649 1.04347826,22.7368421 C1.04347826,22.0392193 1.65665349,21.4736842 2.41304348,21.4736842 Z M13.3695652,14.1157895 L17.8110652,10.0206316 L19.7476304,11.8067368 L12,18.9524211 L4.25236957,11.8067368 L6.18893478,10.0206316 L10.6304348,14.1157895 L10.6304348,0 L13.3695652,0 L13.3695652,14.1157895 Z" />
    </g>
  </Icon>
)

export default DownloadIcon
