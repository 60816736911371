import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'

import { MailIcon, PhoneIcon } from 'styles/Icon'

import { QuoteDataType } from 'types'

import { ApplicationState } from 'reducers'

const ContactButtonWrapper = styled.div<any>`
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  z-index: 8000;
`
const ContactButton = styled.div<any>`
  background-color: ${({ theme }) => theme.color.neutral.dark};
  color: #fff;
  width: 3.2rem;
  height: 3.2rem;
  border-radius: 1.25rem;
  font-weight: 500;
  display: block;
  box-shadow: 0 12.5px 10px rgba(0, 0, 0, 0.04), 0 0 17.9px rgba(0, 0, 0, 0.05);
  cursor: pointer;

  svg {
    margin-left: 0.9rem;
    margin-top: 0.9rem;
  }

  &:hover {
    background-color: ${({ theme }) => theme.color.neutral.darker};
  }
`

const ZRAImage = styled.div`
  background-color: unset;
  color: #fff;
  height: 100%;
  width: 100%;
  border-radius: 1.125rem;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  &:hover {
    background-color: ${({ theme }) => theme.color.neutral.darker};
  }
`

const QuestionText = styled.div`
  background-color: white;
  color: #000;
  right: 5.8rem;
  border-radius: 1.25rem;
  padding: 0.8rem 1rem;
  font-weight: 500;
  display: block;
  margin: 0 auto;
  box-shadow: 0 12.5px 10px rgba(0, 0, 0, 0.04), 0 0 17.9px rgba(0, 0, 0, 0.05);
  cursor: pointer;
  display: flex;
  align-items: center;

  svg {
    margin-left: 1.1rem;
    margin-top: 1.1rem;
  }

  &:hover {
    background-color: ${({ theme }) => theme.color.neutral.lighter};
  }
`

const ContactMenu = styled.ul`
  background-color: white;
  position: absolute;
  width: 20rem;
  right: 0rem;
  bottom: 4rem;
  text-align: left;
  border-radius: 1.25rem;
  box-shadow: 0 12.5px 10px rgba(0, 0, 0, 0.04), 0 0 17.9px rgba(0, 0, 0, 0.05);
  margin: 0;
  padding: 1.8rem;
  margin-bottom: 1rem;

  h2 {
    font-size: 1rem;
    margin-bottom: 0;
    margin-top: 0;
  }

  h3 {
    font-size: 0.9rem;
    margin-top: 0.5rem;
  }

  li {
    list-style-type: none;
    cursor: pointer;
    color: #000;

    svg {
      color: #000;
      fill: #000;
      width: 0.9rem;
      height: 0.9rem;
      margin-right: 0.5rem;
      display: inline-block;
      vertical-align: middle;
    }

    &:hover {
      color: ${({ theme }) => theme.color.neutral.lighter};
    }
  }

  li:first-child {
    border-bottom: 1px solid ${({ theme }) => theme.color.neutral.lighter};
  }

  a {
    color: ${({ theme }) => theme.color.neutral.dark};
    font-weight: 500;
  }
`

const ContactSection: any = () => {
  const [hover, setHover] = useState<boolean>(false)
  const [isImageValid, setIsImageValid] = useState(false)
  const { itinerary }: QuoteDataType = useSelector((state: ApplicationState) => state.quote.data)

  useEffect(() => {
    if (itinerary?.guide?.image) {
      const img = new Image()
      img.onload = () => setIsImageValid(true)
      img.onerror = () => setIsImageValid(false)
      img.src = itinerary.guide.image
    }
  }, [itinerary?.guide?.image])

  if (!itinerary) return <></>

  const { phone, email, name } = itinerary?.isZRA ? itinerary.zra : itinerary.guide

  return (
    <ContactButtonWrapper onClick={() => setHover(!hover)}>
      {hover && (
        <ContactMenu>
          <h2>Bel of mail met {name}</h2>
          {itinerary.destinations && <h3>Uw reisadviseur voor {itinerary.destinations}.</h3>}
          {phone && (
            <li>
              <a href={`tel:${phone}`} onClick={() => setHover(false)}>
                <PhoneIcon />
                Bel {phone}
              </a>
            </li>
          )}
          {email && (
            <li>
              <MailIcon />
              <a href={`mailto:${email}`} onClick={() => setHover(false)}>
                Stuur een e-mail
              </a>
            </li>
          )}
        </ContactMenu>
      )}

      <QuestionText>
        {hover && <span>Sluiten&nbsp;&nbsp;X</span>}
        {!hover && <span>Heeft u een vraag?</span>}
      </QuestionText>
      <ContactButton>
        {itinerary.isZRA === true && itinerary.zra.ZRAProfilePicture && (
          <ZRAImage>
            <img src={itinerary.zra.ZRAProfilePicture} alt={itinerary.zra.name} />
          </ZRAImage>
        )}
        {itinerary?.isZRA === true && !itinerary.zra.ZRAProfilePicture && <PhoneIcon />}
        {itinerary?.isZRA === false && isImageValid && (
          <ZRAImage>
            <img src={itinerary.guide.image} alt={itinerary.guide.name} />
          </ZRAImage>
        )}
        {itinerary?.isZRA === false && !isImageValid && <PhoneIcon />}
      </ContactButton>
    </ContactButtonWrapper>
  )
}

export default ContactSection
