import React from 'react'

import Icon, { IconType } from '../Icon'

const SuitcaseIcon: React.FC<IconType> = (props) => (
  <Icon
    {...props}
    viewBox="0 0 24 24"
    width="24"
    height="24"
    preserveAspectRatio="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M21.5831382,5.03703704 C22.2576112,5.03703704 22.8290398,5.28395062 23.2974239,5.77777778 C23.765808,6.27160494 24,6.87407407 24,7.58518519 L24,21.4518519 C24,22.162963 23.765808,22.7654321 23.2974239,23.2592593 C22.8290398,23.7530864 22.2576112,24 21.5831382,24 L2.41686183,24 C1.74238876,24 1.17096019,23.7530864 0.702576112,23.2592593 C0.234192037,22.7654321 0,22.162963 0,21.4518519 L0,7.58518519 C0,6.87407407 0.234192037,6.27160494 0.702576112,5.77777778 C1.17096019,5.28395062 1.74238876,5.03703704 2.41686183,5.03703704 L6.01405152,5.03703704 L6.01405152,2.48888889 C6.01405152,1.81728395 6.24824356,1.2345679 6.71662763,0.740740741 C7.18501171,0.24691358 7.73770492,0 8.37470726,0 L15.5690867,0 C16.2435597,0 16.8149883,0.24691358 17.2833724,0.740740741 C17.7517564,1.2345679 17.9859485,1.81728395 17.9859485,2.48888889 L17.9859485,5.03703704 L21.5831382,5.03703704 Z M8.37470726,2.48888889 L8.37470726,5.03703704 L15.5690867,5.03703704 L15.5690867,2.48888889 L8.37470726,2.48888889 Z M21.5831382,21.4518519 L21.5831382,18.962963 L2.41686183,18.962963 L2.41686183,21.4518519 L21.5831382,21.4518519 Z M21.5831382,15.1703704 L21.5831382,7.58518519 L17.9859485,7.58518519 L17.9859485,10.0740741 L15.5690867,10.0740741 L15.5690867,7.58518519 L8.37470726,7.58518519 L8.37470726,10.0740741 L6.01405152,10.0740741 L6.01405152,7.58518519 L2.41686183,7.58518519 L2.41686183,15.1703704 L21.5831382,15.1703704 Z" />
  </Icon>
)

export default SuitcaseIcon
