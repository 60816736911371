import styled from 'styled-components'

export type FlexValues =
  | 'flex-start'
  | 'flex-end'
  | 'center'
  | 'baseline'
  | 'space-around'
  | 'space-between'
  | 'space-evenly'
  | 'stretch'

type AlignmentProps = {
  v?: FlexValues
  justify?: FlexValues
}

const Alignment = styled.span<AlignmentProps>`
  display: inline-flex;
  align-items: ${({ v }) => v};
  justify-content: ${({ justify }) => justify};
  width: 100%;
  height: 100%;
`

Alignment.defaultProps = {
  v: 'center',
  justify: 'flex-start',
}

export default Alignment
