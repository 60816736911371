import styled from 'styled-components'

type ImageType = {
  source: string
}

const SummaryImage = styled.div<ImageType>`
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  background-image: ${({ source }) => `url('${source}')`};
`

export default SummaryImage
