import React, { ReactElement } from 'react'
import styled from 'styled-components'
import Alignment from 'styles/Alignment'
import { FlexValues } from 'styles/Alignment/Alignment'

import Box from 'styles/Box'
import Icon, { CheckIconRounded } from 'styles/Icon'
import TrashcanIcon from 'styles/Icon/icons/TrashcanIcon'
import Label from 'styles/Label'

type Props = {
  justify?: FlexValues
  v?: FlexValues
  disabled?: boolean
  onClick?: (a?: any) => any
  style?: any
}

const Wrapper = styled.div<any>`
  display: flex;
  align-items: center;
  justify-content: ${({ onClick }) => (onClick ? 'space-between' : 'center')};
  @media (max-width: 992px) {
    justify-content: flex-start;
  }
`

const FirstElement = styled.div`
  width: 30px;
  @media (max-width: 992px) {
    display: none;
  }
`

const Container = styled.div<any>`
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.color.neutral.base};
  padding: 1.5px 12px;
  min-width: 134px;
  font-family: Raleway, sans-serif;
  text-transform: uppercase;

  & > * > * {
    font-size: 0.9rem !important;
  }
`

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  padding-left: 4px;
  width: 26px;
  & > svg:hover > * {
    transition: stroke 0.25s ease-in-out;
    stroke: ${({ theme }) => theme.color.neutral.base};
  }
  rect {
    display: none;
  }
`

const SelectedButton: React.FC<Props> = ({ disabled, onClick, style }: Props) => (
  <Wrapper style={style}>
    {onClick && <FirstElement></FirstElement>}
    <Container style={style}>
      <Alignment v="center" justify="center">
        <Label icon={<CheckIconRounded size="1rem" color="success" />} text="Gekozen" color="success" />
      </Alignment>
    </Container>
    {onClick && (
      <IconContainer>
        <TrashcanIcon color="neutral" transparant onClick={disabled ? () => { } : onClick} size="1.6rem" />
      </IconContainer>
    )}
  </Wrapper>
)

export default SelectedButton
