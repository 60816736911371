import { CREDITCARD_MULTIPLIER, CREDITCARD_MULTIPLIER_AMEX } from 'constants/constants'

export function calculateWithFee(paymentInfo: any, amountValue: number): string {
  if (paymentInfo.paymentMethod === 'creditcard') {
    if (paymentInfo.creditcardIssuer === 'visa' || paymentInfo.creditcardIssuer === 'mastercard') {
      return (amountValue * CREDITCARD_MULTIPLIER).toFixed(2)
    }
    if (paymentInfo.creditcardIssuer === 'amex') {
      return (amountValue * CREDITCARD_MULTIPLIER_AMEX).toFixed(2)
    }
  }

  return amountValue.toFixed(2)
}
