import { ThemeProps } from 'types'
import useItinerary from 'hooks/useItinerary'

const useZRATheme = (): ThemeProps => {
  const itinerary = useItinerary()

  const theme: ThemeProps = {
    color: {
      primary: {
        dark: '#B7995E',
        base: itinerary?.zra.color ? itinerary.zra.color : '#A33327',
        light: '#cdb078',
        lighter: '#e8dac0',
        lightest: '#f8f3eb',
      },
      neutral: {
        darkest: '#000000',
        darker: '#2A2A2A',
        dark: '#373737',
        base: '#606060',
        light: '#B0B0B0',
        lighter: '#ebebeb',
        lightest: '#f6f6f6',
      },
      success: {
        base: '#378323',
      },
      danger: {
        base: '#A33327',
        light: '#d66256',
        lighter: '#f5d7d4',
        lightest: '#fcf5f4',
      },
    },
  }

  return theme
}

export default useZRATheme
