import React from 'react'

import Icon, { IconType } from '../Icon'

const CalenderIcon: React.FC<IconType> = (props) => (
  <Icon
    {...props}
    viewBox="0 0 24 24"
    width="24"
    height="24"
    preserveAspectRatio="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M20.4,2.4 L18,2.4 L18,1.2 C18,0.5372583 17.4627417,0 16.8,0 C16.1372583,0 15.6,0.5372583 15.6,1.2 L15.6,2.4 L8.4,2.4 L8.4,1.2 C8.4,0.5372583 7.8627417,0 7.2,0 C6.5372583,0 6,0.5372583 6,1.2 L6,2.4 L3.6,2.4 C1.6117749,2.4 -1.42108547e-14,4.0117749 -1.42108547e-14,6 L-1.42108547e-14,20.4 C-1.42108547e-14,22.3882251 1.6117749,24 3.6,24 L20.4,24 C22.3882251,24 24,22.3882251 24,20.4 L24,6 C24,4.0117749 22.3882251,2.4 20.4,2.4 Z M21.6,20.4 C21.6,21.0627417 21.0627417,21.6 20.4,21.6 L3.6,21.6 C2.9372583,21.6 2.4,21.0627417 2.4,20.4 L2.4,12 L21.6,12 L21.6,20.4 Z M21.6,9.6 L2.4,9.6 L2.4,6 C2.4,5.3372583 2.9372583,4.8 3.6,4.8 L6,4.8 L6,6 C6,6.6627417 6.5372583,7.2 7.2,7.2 C7.8627417,7.2 8.4,6.6627417 8.4,6 L8.4,4.8 L15.6,4.8 L15.6,6 C15.6,6.6627417 16.1372583,7.2 16.8,7.2 C17.4627417,7.2 18,6.6627417 18,6 L18,4.8 L20.4,4.8 C21.0627417,4.8 21.6,5.3372583 21.6,6 L21.6,9.6 Z" />
  </Icon>
)

export default CalenderIcon
