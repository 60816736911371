import React from 'react'

import styled from 'styled-components'
import Box from 'styles/Box'

const FormLabelWrapper = styled.span<any>`
  letter-spacing: 1.25px;
  font-size: 0.85rem;
  font-weight: 600;
  text-transform: uppercase;
  color: ${({ theme }) => theme.color.neutral.base};
`

const FormLabel = ({ children, noLabelMargin }: any) => (
  <Box m={0} mb={noLabelMargin ? 0 : '0.75rem'} inline>
    <FormLabelWrapper>{children}</FormLabelWrapper>
  </Box>
)

export default FormLabel
