import React, { useState } from 'react'
import { Visible, useScreenClass } from 'react-grid-system'
import { Link, useParams } from 'react-router-dom'
import styled from 'styled-components'

import { Logo } from 'styles/Logo'
import Menu, { MobileMenu } from 'styles/Menu'
import { ListIcon, RemoveIcon } from 'styles/Icon'
import Box from 'styles/Box'
import TransparentButton from 'styles/TransparentButton'
import Text from 'styles/Text'
import { QuoteStateType } from 'types'
import { ApplicationState } from 'reducers'
import { useSelector } from 'react-redux'
import currency from 'helpers/currency'

const Nav = styled.div`
  position: fixed;
  z-index: 9000;
  top: 0;
  width: 100%;
`

const HeaderMenu = styled.div<any>`
  display: flex;
  align-items: center;
  background-color: white;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.1);
  padding: ${({ small }) => (small ? '0 1.5rem' : '0 3rem')};
`

const Divider = styled.div`
  flex-grow: 2;
  width: auto;
`

const MobileWrapper = styled.div`
  background-color: white;
  position: fixed;
  width: 100%;
  z-index: 9000;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.1);
`

const PriceWrapper = styled.div`
  text-align: right;
`

const LogoWrapper = styled.div`
  & > svg {
    height: 30px;
    width: auto;
  }
`

const Navigation: React.FC<any> = () => {
  const [showMenu, setShowMenu] = useState<boolean>(false)
  const { data }: QuoteStateType = useSelector((state: ApplicationState) => state.quote)
  const { itineraryId }: any = useParams()
  const { itinerary }: any = useSelector((state: ApplicationState) => state.quote.data)
  const screenClass = useScreenClass()
  const smallScreen = ['xs', 'sm'].includes(screenClass)
  const mediumScreen = ['md', 'xl'].includes(screenClass)

  const costPerPerson = (data?.itinerary?.totalCosts || 0) / (data?.itinerary?.travellers || 1)
  const additionalCostPerPerson =
    ((data?.itinerary?.totalCosts || 0) - (data?.itinerary?.basePrice || 0)) / (data?.itinerary?.travellers || 1)
  const shouldRenderTotalPrice = !itinerary.hidePrices && costPerPerson
  const shouldRenderAdditionalPrice = !!itinerary?.hidePrices && !!itinerary?.showUpsell && !!additionalCostPerPerson

  return (
    <Nav>
      <HeaderMenu small={smallScreen} style={itinerary.isExcursion ? { minHeight: '64px' } : {}}>
        <Link to={`/${itineraryId}/itinerary`}>
          <LogoWrapper>
            <Logo width="100%" variant="dark" />
          </LogoWrapper>
        </Link>
        <Visible xl xxl>
          <Menu />
          <Divider />

          {shouldRenderTotalPrice && (
            <PriceWrapper>
              <Text color="neutral" variant="light" size={mediumScreen ? 'sm' : 'md'} inline>
                <span>Totaalprijs:&nbsp;</span>
              </Text>
              <Text color="neutral" variant="base" size={mediumScreen ? 'sm' : 'md'} inline>
                <strong>{currency.format(costPerPerson)}&nbsp;</strong>
                <strong>p.p.</strong>
              </Text>
            </PriceWrapper>
          )}

          {shouldRenderAdditionalPrice && (
            <PriceWrapper>
              <Text color="neutral" variant="light" size={mediumScreen ? 'sm' : 'md'} inline>
                <span>Meerprijs:&nbsp;</span>
              </Text>
              <Text color="neutral" variant="base" size={mediumScreen ? 'sm' : 'md'} inline>
                <strong>{currency.format(additionalCostPerPerson)}&nbsp;</strong>
                <strong>p.p.</strong>
              </Text>
            </PriceWrapper>
          )}
        </Visible>
        {!itinerary.isExcursion && (
          <Visible xs sm md lg>
            <Divider />
            <Box p="1.5rem" pr={0} m={0}>
              {showMenu ? (
                <TransparentButton onClick={() => setShowMenu(false)}>
                  <RemoveIcon color="neutral" variant="dark" size="1rem" />
                </TransparentButton>
              ) : (
                <TransparentButton onClick={() => setShowMenu(true)}>
                  <ListIcon color="neutral" variant="dark" size="1rem" />
                </TransparentButton>
              )}
            </Box>
          </Visible>
        )}
      </HeaderMenu>
      {showMenu && (
        <Visible xs sm md lg>
          <MobileWrapper>
            <MobileMenu
              onClick={() => {
                setShowMenu(false)
              }}
            />
          </MobileWrapper>
        </Visible>
      )}
    </Nav>
  )
}

export default Navigation
