import React from 'react'
import { Link } from 'react-router-dom'
import Alignment from 'styles/Alignment'
import Button from 'styles/Button'
import { ArrowLeftIcon, ArrowRightIcon } from 'styles/Icon'
import { CheckoutTabType } from './CheckoutTabs'

type CheckoutArrowButtonsProps = {
  tabs: CheckoutTabType[]
}
const CheckoutArrowButtons: React.FC<CheckoutArrowButtonsProps> = ({ tabs }: CheckoutArrowButtonsProps) => {
  const filteredTabs = tabs.filter(({ hidden, name }) => !hidden || name === 'Betalen' || name === 'Boeking')

  let tabIndex = filteredTabs.findIndex(
    (tab) => tab.path && window.location.pathname.endsWith(tab.path.split('/').slice(-1).join('/'))
  )
  tabIndex = tabIndex === -1 ? 0 : tabIndex

  const currentTab: CheckoutTabType = filteredTabs[tabIndex]
  const disabled = currentTab.disabledNextTab ? currentTab.disabledNextTab() : false
  const prevTab = filteredTabs[tabIndex - 1]
  const nextTab = filteredTabs[tabIndex + 1]
  return (
    <Alignment justify="space-between">
      <Alignment justify="flex-start">
        {prevTab && prevTab.path && (
          <Link to={prevTab.path}>
            <Button type="neutral" beforeIcon={<ArrowLeftIcon size="1rem" />}>
              {prevTab.name}
            </Button>
          </Link>
        )}
        {prevTab && prevTab.onClick && (
          <Button type="neutral" onClick={prevTab.onClick} beforeIcon={<ArrowLeftIcon size="1rem" />}>
            {prevTab.name}
          </Button>
        )}
      </Alignment>

      <Alignment justify="flex-end">
        {nextTab && nextTab.path && !disabled && (
          <Link to={nextTab.path}>
            <Button type="primary" disabled={disabled} afterIcon={<ArrowRightIcon size="1rem" />}>
              {nextTab.name}
            </Button>
          </Link>
        )}

        {nextTab && nextTab.path && disabled && (
          <Button type="primary" disabled={disabled} afterIcon={<ArrowRightIcon size="1rem" />}>
            {nextTab.name}
          </Button>
        )}

        {nextTab && nextTab.onClick && (
          <Button
            type="primary"
            onClick={nextTab.onClick}
            disabled={disabled}
            afterIcon={<ArrowRightIcon size="1rem" />}
          >
            {nextTab.name}
          </Button>
        )}
      </Alignment>
    </Alignment>
  )
}

export { CheckoutArrowButtons }
