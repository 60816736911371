import React from 'react'

import Icon, { IconType } from '../Icon'

const ChecklistIcon: React.FC<IconType> = (props) => (
  <Icon
    {...props}
    viewBox="0 0 24 24"
    width="24"
    height="24"
    preserveAspectRatio="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g transform="translate(3, 0)">
      <path d="M15.75,3 L12,3 C12,1.3453125 10.6546875,0 9,0 C7.3453125,0 6,1.3453125 6,3 L2.25,3 C1.0078125,3 0,4.0078125 0,5.25 L0,21.75 C0,22.9921875 1.0078125,24 2.25,24 L15.75,24 C16.9921875,24 18,22.9921875 18,21.75 L18,5.25 C18,4.0078125 16.9921875,3 15.75,3 Z M4.5,19.875 C3.8765625,19.875 3.375,19.3734375 3.375,18.75 C3.375,18.1265625 3.8765625,17.625 4.5,17.625 C5.1234375,17.625 5.625,18.1265625 5.625,18.75 C5.625,19.3734375 5.1234375,19.875 4.5,19.875 Z M4.5,15.375 C3.8765625,15.375 3.375,14.8734375 3.375,14.25 C3.375,13.6265625 3.8765625,13.125 4.5,13.125 C5.1234375,13.125 5.625,13.6265625 5.625,14.25 C5.625,14.8734375 5.1234375,15.375 4.5,15.375 Z M4.5,10.875 C3.8765625,10.875 3.375,10.3734375 3.375,9.75 C3.375,9.1265625 3.8765625,8.625 4.5,8.625 C5.1234375,8.625 5.625,9.1265625 5.625,9.75 C5.625,10.3734375 5.1234375,10.875 4.5,10.875 Z M9,1.875 C9.6234375,1.875 10.125,2.3765625 10.125,3 C10.125,3.6234375 9.6234375,4.125 9,4.125 C8.3765625,4.125 7.875,3.6234375 7.875,3 C7.875,2.3765625 8.3765625,1.875 9,1.875 Z M15,19.125 C15,19.33125 14.83125,19.5 14.625,19.5 L7.875,19.5 C7.66875,19.5 7.5,19.33125 7.5,19.125 L7.5,18.375 C7.5,18.16875 7.66875,18 7.875,18 L14.625,18 C14.83125,18 15,18.16875 15,18.375 L15,19.125 Z M15,14.625 C15,14.83125 14.83125,15 14.625,15 L7.875,15 C7.66875,15 7.5,14.83125 7.5,14.625 L7.5,13.875 C7.5,13.66875 7.66875,13.5 7.875,13.5 L14.625,13.5 C14.83125,13.5 15,13.66875 15,13.875 L15,14.625 Z M15,10.125 C15,10.33125 14.83125,10.5 14.625,10.5 L7.875,10.5 C7.66875,10.5 7.5,10.33125 7.5,10.125 L7.5,9.375 C7.5,9.16875 7.66875,9 7.875,9 L14.625,9 C14.83125,9 15,9.16875 15,9.375 L15,10.125 Z" />
    </g>
  </Icon>
)

export default ChecklistIcon
