import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, useParams } from 'react-router-dom'

import { Row, Col } from 'react-grid-system'
import { Field, Form as FinalForm } from 'react-final-form'

import currency from 'helpers/currency'

import Box from 'styles/Box'
import Text from 'styles/Text'
import { RadioField, SelectField } from 'styles/Form'
import { QuoteDataType } from 'types'
import { CheckoutCard } from 'components/Checkout/CheckoutCard/CheckoutCard'
import styled from 'styled-components'
import { H3 } from 'styles/Heading'
import idealLogo from 'assets/images/ideal-logo.png'
import bancontactLogo from 'assets/images/bancontact-logo.png'
import americanExpressLogo from 'assets/images/american-express-logo.png'
import visaLogo from 'assets/images/visa-logo.png'
import mastercardLogo from 'assets/images/mastercard-logo.png'
import { isRequired } from 'helpers/validators'
import { setPaymentInfo } from 'actions/Checkout'
import { ApplicationState } from 'reducers'
import { CheckoutTabButton } from 'components/Checkout/CheckoutTabs'
import Button from 'styles/Button'
import Alignment from 'styles/Alignment'
import useCompany from 'hooks/useCompany'
import { createPaymentLink } from 'actions/Quote'
import { createNotification } from 'actions/Notifications'
import { ArrowRightIcon } from 'styles/Icon'
import { CREDITCARD_MULTIPLIER, CREDITCARD_MULTIPLIER_AMEX, CREDITCARD_ISSUERS } from 'constants/constants'

const PaymentMethodRow = styled(Row)`
  border-top: 1px solid #e5e5e5;
  padding: 8px !important;
`

const PaymentFormContainer = styled.div`
  padding: 0px;
  input[type='radio'] {
    width: 20px;
    height: 20px;
    accent-color: ${({ theme }) => theme.color.neutral.dark};
    border: 2px solid ${({ theme }) => theme.color.neutral.dark};
  }
  line-height: 1.75rem;
`

const PaymentFormCol = styled(Col)`
  padding: 0px !important;
`
const PaymentFormRow = styled(Row)`
  padding: 8px 16px !important;
`

const creditcardIssuers = CREDITCARD_ISSUERS

const OutstandingPayment: React.FC = () => {
  const { itineraryId }: any = useParams()
  const dispatch = useDispatch()
  const { itinerary, paymentMethods }: QuoteDataType = useSelector((state: ApplicationState) => state.quote.data)
  const { short, company } = useCompany()
  const ideal = paymentMethods?.find(({ id }) => id === 'ideal')
  const creditcard = paymentMethods?.find(({ id }) => id === 'creditcard')
  const bancontact = paymentMethods?.find(({ id }) => id === 'bancontact')
  const formRef: React.MutableRefObject<any> = useRef()
  const amountValue = (itinerary?.totalCosts ?? 0) - (itinerary?.paid ?? 0)
  const paymentMethod = formRef?.current?.getState()?.values?.paymentMethod

  useEffect(() => {
    if (!formRef.current || !paymentMethods || !paymentMethods.length) return
    dispatch(
      setPaymentInfo({
        idealIssuer: formRef?.current?.getState()?.values?.idealIssuer,
        creditcardIssuer: formRef?.current?.getState()?.values?.creditcardIssuer,
        paymentAmount: formRef?.current?.getState()?.values?.paymentAmount,
        paymentMethod: formRef?.current?.getState()?.values?.paymentMethod ?? paymentMethods[0],
      })
    )
  }, [paymentMethods, dispatch])

  if (itinerary?.status !== 'booked' || (itinerary && itinerary?.paid >= itinerary?.totalCosts)) {
    return <Redirect to={`/${itineraryId}/itinerary`}></Redirect>
  }
  if (!itinerary) return null

  const onChange = (value: string, name: string) => {
    if (!itinerary || !formRef.current) return value

    if (name === 'paymentMethod') {
      if (value === 'creditcard') {
        formRef.current.change('idealIssuer', undefined)
      } else if (value === 'ideal') {
        formRef.current.change('creditcardIssuer', undefined)
      }
    }
    dispatch(
      setPaymentInfo({
        idealIssuer: formRef?.current?.getState()?.values?.idealIssuer,
        creditcardIssuer: formRef?.current?.getState()?.values?.creditcardIssuer,
        paymentAmount: formRef?.current?.getState()?.values?.paymentAmount,
        paymentMethod: formRef?.current?.getState()?.values?.paymentMethod,
        ...{ [name]: value },
      })
    )
    return value
  }

  const onSubmit = async ({ paymentMethod, idealIssuer }: any) => {
    const { creditcardIssuer } = formRef.current.getState().values
    let amountWithFee

    if (paymentMethod === 'creditcard') {
      if (creditcardIssuer === 'visa' || creditcardIssuer === 'mastercard') {
        amountWithFee = (amountValue * CREDITCARD_MULTIPLIER).toFixed(2)
      } else if (creditcardIssuer === 'amex') {
        amountWithFee = (amountValue * CREDITCARD_MULTIPLIER_AMEX).toFixed(2)
      } else {
        amountWithFee = amountValue.toFixed(2)
      }
    } else {
      amountWithFee = amountValue.toFixed(2)
    }

    const fee = paymentMethod === 'creditcard' ? (Number(amountWithFee) - Number(amountValue)).toFixed(2) : '0.00'

    const payment = {
      short,
      request: {
        amount: {
          currency: 'EUR',
          value: amountWithFee,
        },
        description: `Betaling ${company}`,
        redirectUrl: `https://${window.location.host}/${itineraryId}/book/success`,
        webhookUrl: `${process.env.REACT_APP_API_URL}/payment/hook/${short}`,
        metadata: {
          itineraryId,
          short,
          isDeposit: false,
          isOption: false,
          fee,
        },
        method: paymentMethod?.toLowerCase().replace(/\s/g, ''),
        issuer: idealIssuer,
      },
      isTest: process.env.NODE_ENV !== 'production',
      travellers: itinerary.contacts,
      opportunityId: itinerary?.opportunityId,
    }
    try {
      const action = await dispatch(createPaymentLink(payment))
      // eslint-disable-next-line no-underscore-dangle
      window.location.href = action.payload.data._links.checkout.href
    } catch (e) {
      dispatch(
        createNotification({
          content:
            'De betaling kon niet verwerkt worden. Neem contact op met onze klantenservice om u verder te helpen.',
          status: 'danger',
        })
      )
    }
  }

  return (
    <PaymentFormContainer>
      <Row style={{ marginBottom: '1rem' }}>
        <CheckoutTabButton active amount={1}>
          Betaling
        </CheckoutTabButton>
      </Row>

      <FinalForm
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onSubmit={onSubmit}
        render={({ form, submitting, handleSubmit }) => {
          formRef.current = form

          const method = formRef.current?.getState()?.values?.paymentMethod
          const disableCreditcard = amountValue > Number(creditcard?.maximumAmount.value) / CREDITCARD_MULTIPLIER

          let multiplier = 1
          if (
            formRef?.current?.getState()?.values?.creditcardIssuer === 'visa' ||
            formRef?.current?.getState()?.values?.creditcardIssuer === 'mastercard'
          ) {
            multiplier = CREDITCARD_MULTIPLIER
          } else if (formRef?.current?.getState()?.values?.creditcardIssuer === 'amex') {
            multiplier = CREDITCARD_MULTIPLIER_AMEX
          }

          if (method !== 'creditcard') {
            multiplier = 1
          }

          return (
            <form>
              <CheckoutCard>
                <Box>
                  {ideal && (
                    <Row style={{ padding: '8px' }}>
                      <PaymentFormCol xs={1}>
                        <Field
                          name="paymentMethod"
                          component={RadioField}
                          type="radio"
                          value={ideal.id}
                          id={ideal.id}
                          defaultChecked
                          defaultValue={ideal.id}
                          parse={onChange}
                        />
                      </PaymentFormCol>
                      <PaymentFormCol xs={11}>
                        <Row>
                          <Col>
                            <label htmlFor="ideal">iDEAL</label>
                          </Col>
                          <Col style={{ justifyContent: 'right', display: 'flex' }}>
                            <img alt="" height="32px" src={idealLogo} />
                          </Col>
                        </Row>
                      </PaymentFormCol>
                      <PaymentFormCol xs={1}></PaymentFormCol>
                      {form.getState().values.paymentMethod === 'ideal' && (
                        <PaymentFormCol xs={12} md={11}>
                          <Field
                            name="idealIssuer"
                            style={{ width: '450px', maxWidth: '100%' }}
                            component={SelectField}
                            isRequired
                            defaultValue={ideal?.issuers ? ideal.issuers[0].id : ''}
                            validate={isRequired}
                            noLabelMargin
                            parse={onChange}
                            label={
                              <div style={{ textTransform: 'none', color: '#606060', fontSize: '12px', margin: 0 }}>
                                Naam van de bank
                              </div>
                            }
                          >
                            {ideal?.issuers?.map(({ name, id }, index) => {
                              return (
                                <option key={id} value={id}>
                                  {name}
                                </option>
                              )
                            })}
                          </Field>
                        </PaymentFormCol>
                      )}
                    </Row>
                  )}

                  {bancontact && (
                    <PaymentMethodRow>
                      <PaymentFormCol xs={1}>
                        <Field
                          name="paymentMethod"
                          component={RadioField}
                          type="radio"
                          value="bancontact"
                          id="bancontact"
                          parse={onChange}
                        />
                      </PaymentFormCol>
                      <PaymentFormCol xs={11}>
                        <Row>
                          <Col>
                            <label htmlFor="bancontact">Bancontact</label>
                          </Col>
                          <Col style={{ justifyContent: 'right', display: 'flex' }}>
                            <img alt="" height="34px" src={bancontactLogo} />
                          </Col>
                        </Row>
                      </PaymentFormCol>
                    </PaymentMethodRow>
                  )}

                  {creditcard && (
                    <PaymentMethodRow>
                      <PaymentFormCol xs={1}>
                        <Field
                          name="paymentMethod"
                          component={RadioField}
                          type="radio"
                          value={creditcard.id}
                          id={creditcard.id}
                          parse={onChange}
                          disabled={disableCreditcard}
                        />
                      </PaymentFormCol>
                      <PaymentFormCol xs={11}>
                        <Row>
                          <Col>
                            <label htmlFor="creditcard">Creditcard</label>
                          </Col>
                          <Col style={{ justifyContent: 'right', display: 'flex' }}>
                            <img alt="" height="24px" src={mastercardLogo} />
                            <img alt="" height="24px" src={americanExpressLogo} />
                            <img alt="" height="24px" src={visaLogo} />
                          </Col>
                        </Row>

                        <PaymentFormCol xs={1}></PaymentFormCol>
                        {form.getState().values.paymentMethod === 'creditcard' && (
                          <>
                            <PaymentFormCol xs={12} md={11}>
                              <Field
                                name="creditcardIssuer"
                                style={{ width: '450px', maxWidth: '100%' }}
                                component={SelectField}
                                isRequired
                                defaultValue={creditcardIssuers ? creditcardIssuers[0].id : ''}
                                validate={isRequired}
                                noLabelMargin
                                parse={onChange}
                                label={
                                  <div style={{ textTransform: 'none', color: '#606060', fontSize: '12px', margin: 0 }}>
                                    Selecteer type
                                  </div>
                                }
                              >
                                {creditcardIssuers?.map(({ name, id }, index) => {
                                  return (
                                    <option key={id} value={id}>
                                      {name}
                                    </option>
                                  )
                                })}
                              </Field>
                            </PaymentFormCol>
                            <Row>
                              <Col>
                                {method === 'creditcard' &&
                                  amountValue * multiplier > Number(creditcard?.maximumAmount.value) && (
                                    <Text size="sm" color="danger">
                                      De limiet voor een betaling met creditcard is bereikt. Neem contact op met uw
                                      reisadviseur indien u toch van deze optie gebruik wilt maken.
                                    </Text>
                                  )}
                                <Text size="sm" color="neutral">
                                  Let op: u betaalt +
                                  {
                                    creditcardIssuers.find(
                                      (issuer) => issuer.id === form.getState().values.creditcardIssuer
                                    )?.additionalServiceCharge
                                  }
                                  % transactiekosten. Deze kosten worden door de creditcardmaatschappij in rekening
                                  gebracht. Wilt u geen toeslag betalen? Selecteer dan een andere betaalwijze.
                                </Text>
                              </Col>
                            </Row>
                          </>
                        )}
                        <>
                          <PaymentFormCol style={{ marginTop: '8px' }}>
                            {method !== 'creditcard' && disableCreditcard && (
                              <Text size="sm" color="danger">
                                De limiet voor een betaling met creditcard is bereikt. Neem contact op met uw
                                reisadviseur indien u toch van deze optie gebruik wilt maken.
                              </Text>
                            )}
                          </PaymentFormCol>
                        </>
                      </PaymentFormCol>
                    </PaymentMethodRow>
                  )}

                  <PaymentMethodRow></PaymentMethodRow>

                  <PaymentFormRow>
                    <PaymentFormCol xs={12} style={{ marginTop: '32px' }}>
                      <H3>Te betalen {currency.format(amountValue * multiplier)}</H3>
                      <Text size="sm" color="neutral">
                        Zodra uw betaling is afgerond, sturen we u een e-mail met uw bevestiging.
                      </Text>
                    </PaymentFormCol>
                  </PaymentFormRow>

                  {/* {creditcard && price < parseInt(creditcard.maximumAmount.value, 10) && (
                    <PaymentMethodRow>
                      <PaymentFormCol xs={1}>
                        <Field
                          name="paymentMethod"
                          component={RadioField}
                          type="radio"
                          value="creditcard"
                          id="creditcard"
                          parse={onChange}
                        />
                      </PaymentFormCol>
                      <PaymentFormCol xs={11}>
                        <Row>
                          <Col>
                            <label htmlFor="creditcard">Creditcard</label>
                          </Col>
                          <Col style={{ justifyContent: 'right', display: 'flex' }}>
                            <img alt="" height="24px" src={americanExpressLogo} />
                            <img alt="" height="24px" src={mastercardLogo} />
                            <img alt="" height="24px" src={visaLogo} />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Text size="sm" color="neutral">
                              +1.8% transactiekosten Mollie <br />
                              Max. {currency.format(parseInt(creditcard.maximumAmount.value, 10))}
                            </Text>
                          </Col>
                        </Row>
                      </PaymentFormCol>
                    </PaymentMethodRow>
                  )} */}
                </Box>
              </CheckoutCard>

              <Alignment justify="space-between">
                <Button
                  afterIcon={<ArrowRightIcon size="1rem" />}
                  onClick={handleSubmit}
                  disabled={
                    submitting ||
                    (method === 'creditcard' && amountValue * multiplier > Number(creditcard?.maximumAmount.value))
                  }
                >
                  Betalen
                </Button>
              </Alignment>
            </form>
          )
        }}
      />
    </PaymentFormContainer>
  )
}

export default OutstandingPayment
