import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'
import moment from 'moment'

import Summary from 'components/Summary'
import DayProgram from 'components/DayProgram'

import { QuoteDataType } from 'types'
import { ApplicationState } from 'reducers'
import { useLocation } from 'react-router-dom'

import { createNotification } from 'actions/Notifications'

const Container = styled.div`
  margin: 0;

  @media (max-width: 991px) {
    margin: 2rem 3rem;
  }

  @media (max-width: 759px) {
    margin: 1.5rem 1.5rem;
  }
`

const Whitespace = styled.div`
  margin: 0;
  height: 10rem;

  @media (max-width: 991px) {
    margin: 2rem 3rem;
  }

  @media (max-width: 759px) {
    margin: 1.5rem 1.5rem;
  }
`

const Itinerary: React.FC = () => {
  const { itinerary, excursions }: QuoteDataType = useSelector((state: ApplicationState) => state.quote.data)
  const dispatch = useDispatch()
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const status = params.get('status')

  useEffect(() => {
    if (status === 'option') {
      dispatch(
        createNotification({
          content: `Uw betaling is geslaagd, de offerte staat tot ${moment(itinerary?.expireDate)
            .add(2, 'days')
            .format(
              'DD MMMM YYYY'
            )} voor u onder optie. Er is een bedrag van €50,00 in mindering gebracht op de aanbetaling van de reis.`,
          status: 'success',
        })
      )
    }
  }, [dispatch])

  if (!itinerary) {
    return null
  }
  const { summary, days } = itinerary

  return (
    <Container>
      {summary && summary.length > 0 ? <Summary summary={summary} /> : <Whitespace></Whitespace>}
      {days && days.length && (
        <DayProgram excursions={excursions} days={days} hideDayProgramOptions={itinerary?.hideDayProgramOptions} />
      )}
    </Container>
  )
}

export default Itinerary
