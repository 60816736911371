import React from 'react'

import Icon, { IconType } from '../Icon'

const RemoveIcon: React.FC<IconType> = (props) => (
  <Icon
    {...props}
    viewBox="0 0 24 24"
    width="24"
    height="24"
    preserveAspectRatio="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M23.8194254,20.3173735 L15.502052,12 L23.8194254,3.68262654 C24.0601915,3.44186047 24.0601915,3.04787962 23.8194254,2.80711354 L21.1928865,0.180574555 C20.9521204,-0.0601915185 20.5581395,-0.0601915185 20.3173735,0.180574555 L12,8.49794802 L3.68262654,0.180574555 C3.44186047,-0.0601915185 3.04787962,-0.0601915185 2.80711354,0.180574555 L0.180574555,2.80711354 C-0.0601915185,3.04787962 -0.0601915185,3.44186047 0.180574555,3.68262654 L8.49794802,12 L0.180574555,20.3173735 C-0.0601915185,20.5581395 -0.0601915185,20.9521204 0.180574555,21.1928865 L2.80711354,23.8194254 C3.04787962,24.0601915 3.44186047,24.0601915 3.68262654,23.8194254 L12,15.502052 L20.3173735,23.8194254 C20.5581395,24.0601915 20.9521204,24.0601915 21.1928865,23.8194254 L23.8194254,21.1928865 C24.0601915,20.9521204 24.0601915,20.5581395 23.8194254,20.3173735 Z" />
  </Icon>
)

export default RemoveIcon
