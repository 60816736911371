import styled from 'styled-components'

const SummaryCard = styled.div`
  padding: 2rem;
  background-color: #f2f2f2;
  border: none;
  margin-bottom: 2rem;
  color: #000;
  @media (max-width: 1200px) {
    margin-bottom: 0;
  }
`

export default SummaryCard
