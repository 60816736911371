import React from 'react'
import { Col } from 'react-grid-system'
import styled from 'styled-components'
import Card from 'styles/Card'

type CheckoutCardProps = {
  children?: React.ReactNode
}

const StyledCard = styled(Card)`
  padding: 0;
  border: 1px solid ${({ theme }) => theme.color.neutral.lighter};
  color: ${({ theme }) => theme.color.neutral.darkest};
  box-shadow: none;
  h3 {
    font-family: 'Oswald', 'Raleway', sans-serif;
    font-size: 18px;
    text-transform: uppercase;
    color: #000;
    font-weight: normal;
  }
`

const CheckoutCard: React.FC<CheckoutCardProps> = ({ children }: CheckoutCardProps) => {
  return (
    <StyledCard>
      <Col>{children}</Col>
    </StyledCard>
  )
}

export { CheckoutCard }
