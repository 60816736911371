import React from 'react'

import Icon, { IconType } from '../Icon'

const CheckIconRounded: React.FC<IconType> = (props) => (
  <Icon
    {...props}
    viewBox="0 0 24 24"
    width="24"
    height="24"
    preserveAspectRatio="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M12,0 C5.3832,0 0,5.3832 0,12 C0,18.6168 5.3832,24 12,24 C18.6168,24 24,18.6168 24,12 C24,5.3832 18.6168,0 12,0 Z M9.6012,17.2956 L5.1456,12.8496 L6.84,11.1504 L9.5988,13.9044 L15.9516,7.5516 L17.6484,9.2484 L9.6012,17.2956 Z" />
  </Icon>
)

export default CheckIconRounded
