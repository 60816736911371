import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Redirect, useParams, useLocation } from 'react-router-dom'
import useCompany from 'hooks/useCompany'

import getMenuItems, { MenuItem } from 'helpers/menuItems'

import { fetchQuote } from 'actions/Quote'

import Loader from 'pages/Loader'
import Error from 'pages/Error'

import Header from 'components/Header'
import Footer from 'components/Footer'
import Navigation from 'components/Navigation'
import ContactSection from 'components/ContactSection'

import { QuoteStateType } from 'types'
import { ApplicationState } from 'reducers'

const PageContainer = ({ children, isLandingPage }: any) => {
  const { pathname } = useLocation()
  const dispatch = useDispatch()
  const { itineraryId }: any = useParams()
  const { company } = useCompany()
  const { data, isFetching, fetchingError }: QuoteStateType = useSelector((state: ApplicationState) => state.quote)

  // Retrieve quote on mount
  useEffect(() => {
    async function handleFetchQuote() {
      await dispatch(fetchQuote(itineraryId, company))
    }

    handleFetchQuote()
  }, [dispatch, itineraryId, company])

  if (isFetching) {
    return <Loader />
  }

  if (fetchingError) {
    return <Error />
  }

  const { itinerary } = data

  if (!itinerary) {
    return null
  }

  const menuItems = getMenuItems(
    itinerary?.status === 'booked',
    itinerary?.isZRA,
    itinerary?.adjustTravellers,
    itinerary?.hidePrices,
    itinerary?.hideDates,
    itinerary?.isExcursion
  )
  const index: number = menuItems.findIndex((item: MenuItem) => pathname.includes(item.link))
  const name = isLandingPage ? itinerary?.name : menuItems[index]?.name
  const isCheckout = false

  if (itinerary.isExcursion && itinerary.status === 'open') {
    return <Redirect to={`/${itineraryId}/checkout/extras`}></Redirect>
  }

  if (itinerary.isExcursion && itinerary.status !== 'open' && !pathname.includes('book')) {
    return <Redirect to={`/${itineraryId}/book`}></Redirect>
  }

  return (
    <>
      <Navigation />
      <Header name={name} small={!isLandingPage} isCheckout={isCheckout} />
      {children}
      <Footer />
      <ContactSection />
    </>
  )
}

export default PageContainer
