import React, { useState } from 'react'
import styled from 'styled-components'
import { Row, Col, Hidden } from 'react-grid-system'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { selectFlight, fetchQuote } from 'actions/Quote'
import { createNotification } from 'actions/Notifications'
import useCompany from 'hooks/useCompany'

import currency from 'helpers/currency'

import Label from 'styles/Label'
import Card from 'styles/Card'
import Text from 'styles/Text'
import Alignment from 'styles/Alignment'
import { TH } from 'styles/Table'
import { H2, H3 } from 'styles/Heading'
import Box from 'styles/Box'
import Button from 'styles/Button'
import Tooltip from 'styles/Tooltip'
import { CheckIconRounded, InfoIcon } from 'styles/Icon'

import { FlightOptionType, QuoteDataType, SingleFlightType } from 'types'

import { ApplicationState } from 'reducers'
import SidebarCard from 'styles/SidebarCard'
import LabelButton from 'styles/LabelButton'
import SelectedButton from 'styles/SelectedButton'
import LuggageDropdown from 'components/LuggageDropdown/LuggageDropdown'
import Separator from 'styles/Separator'
import SingleFlight from './SingleFlight'

const DestinationH3 = styled(H3)`
  margin-left: 0.9rem;
  margin-bottom: 3rem;
  @media (max-width: 990px) {
    margin-left: 0;
    margin-bottom: 1rem;
  }
`

const NoteWrapper = styled.div<any>`
  display: flex;
  align-items: center;
  padding: 1rem 2rem;
  line-height: 1.6rem;
  color: ${({ theme }) => theme.color.neutral.base};
  background-color: ${({ theme }) => theme.color.neutral.lighter};
  font-weight: 600;
  font-size: 0.9rem;
`

const SingleFlightsTable = styled.table`
  border-collapse: collapse;
  width: 100%;
`

interface ExtendedFlightOptionType extends FlightOptionType {
  flightId: string
  orderedByAmount: any
}

const FlightOption: React.FC<ExtendedFlightOptionType> = ({
  orderedByAmount,
  flightId,
  id,
  name,
  travellers,
  selected,
  note,
  singleFlights,
  sellAmount,
  holdLuggage,
  holdLuggageWeight,
  holdLuggageUnits,
  holdLuggageSellAmount,
}: ExtendedFlightOptionType) => {
  const dispatch = useDispatch()
  const { itineraryId }: any = useParams()
  const { itinerary }: QuoteDataType = useSelector((state: ApplicationState) => state.quote.data)
  const { company } = useCompany()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  // const [luggageCount, setLuggageCount] = useState<number>(holdLuggageUnits || 0)
  // const lowestAmount = orderedByAmount[0].sellAmount
  // const additionalAmount = sellAmount - lowestAmount
  const selectedOption = orderedByAmount.filter((flight: any) => flight.selected === true)
  const priceDifference = sellAmount - selectedOption?.[0]?.sellAmount
  const shouldRenderAdditionalPrice = !itinerary?.hidePrices || (!!itinerary?.hidePrices && !!itinerary?.showUpsell)
  const onSelectFlight = async (luggageCount?: number | Event) => {
    if (typeof luggageCount !== 'number') luggageCount = holdLuggageUnits
    setIsSubmitting(true)
    const action = await dispatch(selectFlight(flightId, id, luggageCount, luggageCount !== holdLuggageUnits))
    setIsSubmitting(false)

    if (!action.payload || action.payload.status !== 200) {
      dispatch(
        createNotification({
          content: 'Vlucht optie selecteren mislukt. Neem contact met ons op om uw vluchtvoorkeur door te geven.',
          status: 'danger',
        })
      )
    } else {
      dispatch(fetchQuote(itineraryId, company))
    }
  }

  const onSelectLuggage = async (luggageCount: number) => {
    onSelectFlight(luggageCount)
  }

  return (
    <SidebarCard>
      <Row>
        <Col>
          <Box p="2rem" m={0}>
            <Row>
              {!!name && (
                <Col lg={9} md={12}>
                  <Alignment v="center" justify="flex-start">
                    <Hidden xs sm md>
                      <DestinationH3>{name}</DestinationH3>
                    </Hidden>
                  </Alignment>
                </Col>
              )}
              <Hidden lg xl xxl>
                <Col lg={2}>
                  <Box m={0}>
                    <>
                      <Col lg={12}>
                        <Alignment v="center" justify="flex-start">
                          <DestinationH3>{name}</DestinationH3>
                        </Alignment>
                      </Col>
                    </>
                  </Box>
                </Col>
              </Hidden>
              <Col lg={12}>
                <Box m={0}>
                  <Hidden xs sm md>
                    <SingleFlightsTable>
                      <thead>
                        <tr>
                          <TH>&nbsp;</TH>
                          <TH>Datum</TH>
                          <TH>Van - Naar</TH>

                          <TH>
                            {singleFlights.some((flight: SingleFlightType) => flight.flightNumber) && 'Vluchtnummer'}
                          </TH>
                          {singleFlights.some((flight: SingleFlightType) => flight.flightClass) && <TH>Klasse</TH>}
                        </tr>
                      </thead>
                      <tbody>
                        {singleFlights.map((singleFlight: SingleFlightType) => (
                          <SingleFlight key={singleFlight.id} {...singleFlight} />
                        ))}
                      </tbody>
                    </SingleFlightsTable>
                  </Hidden>
                  <Hidden lg xl xxl>
                    {singleFlights.map((singleFlight: SingleFlightType) => (
                      <SingleFlight key={singleFlight.id} {...singleFlight} />
                    ))}
                  </Hidden>
                </Box>
              </Col>
            </Row>
            <Hidden lg xl xxl>
              <Separator both></Separator>
              <Col lg={2}>
                <Box m={0} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  {selected && (
                    <Box m={0} mt="1rem">
                      <Alignment justify="flex-start">
                        <SelectedButton style={{ width: 200 }} />
                      </Alignment>
                    </Box>
                  )}
                  {!selected && (
                    <Box m={0}>
                      {itinerary?.status === 'open' && (
                        <LabelButton
                          style={{ width: 200 }}
                          type="primary"
                          onClick={onSelectFlight}
                          disabled={isSubmitting}
                          small
                        >
                          Selecteren
                        </LabelButton>
                      )}
                      {itinerary?.status === 'expired' && (
                        <Tooltip content="Deze offerte is verlopen. Neem contact met uw reisadviseur op om deze offerte bij te werken.">
                          <span>
                            <LabelButton style={{ width: 200 }} type="primary" onClick={onSelectFlight} disabled small>
                              Selecteren
                            </LabelButton>
                          </span>
                        </Tooltip>
                      )}
                    </Box>
                  )}
                  {!holdLuggage && (
                    <>
                      <LuggageDropdown
                        disabled={isSubmitting || itinerary?.status !== 'open' || !selected}
                        count={holdLuggageUnits}
                        setCount={onSelectLuggage}
                        holdLuggageWeight={holdLuggageWeight}
                        holdLuggageSellAmount={holdLuggageSellAmount}
                      ></LuggageDropdown>
                    </>
                  )}
                  {holdLuggage && (
                    <LuggageDropdown
                      disabled
                      count={holdLuggageUnits}
                      setCount={onSelectLuggage}
                      holdLuggageWeight={holdLuggageWeight}
                      holdLuggageSellAmount={holdLuggageSellAmount}
                      inclusive
                    ></LuggageDropdown>
                  )}
                  {shouldRenderAdditionalPrice && !!priceDifference && itinerary?.status === 'open' && (
                    <Box m={0} mt="1.25rem">
                      <Text color="neutral">
                        {priceDifference > 0.0 ? (
                          <strong>+ {currency.format(priceDifference / (travellers || 1))} per persoon</strong>
                        ) : (
                          <strong>
                            - {currency.format(Math.abs(priceDifference) / (travellers || 1))} per persoon
                          </strong>
                        )}
                      </Text>
                    </Box>
                  )}
                </Box>
              </Col>
            </Hidden>
          </Box>
        </Col>
        <Hidden xs sm md>
          <Col
            style={{
              borderLeft: '1px solid #ebebeb',
              display: 'flex',
              justifyContent: 'center',
              paddingLeft: 0,
              maxWidth: '25%',
              flex: '0 0 25%',
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: '2rem' }}>
              {selected && <SelectedButton style={{ width: 200 }} />}

              {!selected && (
                <>
                  {itinerary?.status === 'open' && (
                    <LabelButton
                      style={{ width: 200 }}
                      type="primary"
                      onClick={onSelectFlight}
                      disabled={isSubmitting}
                      small
                    >
                      Selecteren
                    </LabelButton>
                  )}
                  {itinerary?.status === 'expired' && (
                    <Tooltip content="Deze offerte is verlopen. Neem contact met uw reisadviseur op om deze offerte bij te werken.">
                      <span>
                        <LabelButton style={{ width: 200 }} type="primary" onClick={onSelectFlight} disabled small>
                          Selecteren
                        </LabelButton>
                      </span>
                    </Tooltip>
                  )}
                </>
              )}
              {!holdLuggage && (
                <>
                  <LuggageDropdown
                    disabled={isSubmitting || itinerary?.status !== 'open' || !selected}
                    count={holdLuggageUnits}
                    setCount={onSelectLuggage}
                    holdLuggageWeight={holdLuggageWeight}
                    holdLuggageSellAmount={holdLuggageSellAmount}
                  ></LuggageDropdown>
                </>
              )}
              {holdLuggage && (
                <LuggageDropdown
                  disabled
                  count={holdLuggageUnits}
                  setCount={onSelectLuggage}
                  holdLuggageWeight={holdLuggageWeight}
                  holdLuggageSellAmount={holdLuggageSellAmount}
                  inclusive
                ></LuggageDropdown>
              )}

              {shouldRenderAdditionalPrice && !!priceDifference && itinerary?.status === 'open' && (
                <Box m={0} mt="1.25rem">
                  <Text style={{ textAlign: 'center' }} color="neutral">
                    {priceDifference > 0.0 ? (
                      <strong>+ {currency.format(priceDifference / (travellers || 1))} per persoon</strong>
                    ) : (
                      <strong>- {currency.format(Math.abs(priceDifference) / (travellers || 1))} per persoon</strong>
                    )}
                  </Text>
                </Box>
              )}
            </div>
          </Col>
        </Hidden>
      </Row>

      {!!note && (
        <NoteWrapper>
          <Hidden xs>
            <Box m={0} ml={0} mr="1rem" inline>
              <InfoIcon color="neutral" variant="base" />
            </Box>
          </Hidden>
          {note}
        </NoteWrapper>
      )}
    </SidebarCard>
  )
}

export default FlightOption
