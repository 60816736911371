import React from 'react'

import Card from 'styles/Card'
import styled from 'styled-components'
import DropdownButton from './DropdownButton'

export type DropdownPanelProps = {
  setParticipantCount: (count: number) => void
  maxParticipantCount: number
  setActive: (active: boolean) => void
  width?: number
}

const IncrementerCard = styled(Card) <any>`
  padding: 0;
  width: ${({ width }: any) => width - 2 || 152}px;
  margin-top: -3px;
  background-color: white;
  border-left: 1px solid ${({ theme }) => theme.color.neutral.base};
  border-right: 1px solid ${({ theme }) => theme.color.neutral.base};
  border-bottom: 1px solid ${({ theme }) => theme.color.neutral.base};
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
`

const Options = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0;
`

const DropdownPanel: React.FC<DropdownPanelProps> = ({
  setParticipantCount,
  maxParticipantCount,
  setActive,
  width = 160,
}: DropdownPanelProps) => {
  const select = (amount: number) => {
    setParticipantCount(amount)
    setActive(false)
  }

  const cancel = () => {
    setActive(false)
  }
  return (
    <IncrementerCard width={width}>
      <Options>
        {Array.from(Array(maxParticipantCount + 1).keys()).map((i) => (
          <DropdownButton
            key={i}
            amount={i}
            onClick={() => {
              select(i)
            }}
          ></DropdownButton>
        ))}
      </Options>
    </IncrementerCard>
  )
}

export default DropdownPanel
