import TravellerForm from 'components/TravellerForm'
import Itinerary from 'pages/Itinerary'
import React from 'react'
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion'
import { Col } from 'react-grid-system'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import CircleIcon from 'styles/Icon/icons/CircleIcon'
import InvertedCheckIconRounded from 'styles/Icon/icons/InvertedCheckIconRounded'
import { ItineraryContactType } from 'types'

export const TravellerAccordion = styled(Accordion)`
  background-color: white;
`

export const TravellerAccordionItem = styled(AccordionItem)`
  margin-bottom: 1rem;
`

export const TravellerAccordionItemButton = styled(AccordionItemButton)`
  background-color: white;
  min-height: 64px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 1rem;
  border: 1px solid ${({ theme }) => theme.color.neutral.lighter};
  cursor: pointer;
  color: ${({ theme }) => theme.color.primary.base};
  font-family: 'Oswald', 'Raleway', sans-serif;
  font-size: 18px;

  .accordion-label {
    margin: 0.5rem 0;
  }
  .accordion-name {
    margin: 0.5rem 0;
    color: #000;
    text-transform: uppercase;
    font-size: 18px;
  }
`

export const TravellerAccordionItemPanel = styled(AccordionItemPanel)`
  border-right: 1px solid ${({ theme }) => theme.color.neutral.lighter};
  border-left: 1px solid ${({ theme }) => theme.color.neutral.lighter};
  border-bottom: 1px solid ${({ theme }) => theme.color.neutral.lighter};
  margin-top: -1px;
`

export type TravellerAccordionComponentProps = {
  index: number
  traveller?: ItineraryContactType
  opportunityId: string
  contacts: ItineraryContactType[]
  disabled?: boolean
  travellerCount: number
}

export const TravellerAccordionHeader: React.FC<TravellerAccordionComponentProps> = ({
  traveller,
  index,
  opportunityId,
  contacts,
  travellerCount,
}: TravellerAccordionComponentProps) => {
  const globalState = useSelector((state: any) => {
    return { ...state.checkout, ...state.quote.data }
  })
  const isDisabled = (travellerIndex: number) => {
    return !globalState.travellersValid[travellerIndex] || globalState.travellersEdited[travellerIndex]
  }

  return (
    <AccordionItemHeading>
      <TravellerAccordionItemButton>
        <Col order={{ xs: 0, md: 0 }} xs={10} md={4}>
          <div className="accordion-label">
            {index === 0 ? 'Gegevens hoofdboeker:' : `Gegevens medereiziger ${index}:`}
          </div>
        </Col>
        <Col order={{ xs: 2, md: 1 }} xs={12} md={7}>
          <div className="accordion-name" translate="no">
            {traveller
              ? `
            ${traveller.gender?.toLowerCase() === 'man' ? 'DHR' : ''}
            ${traveller.gender?.toLowerCase() === 'vrouw' ? 'MEVR' : ''}
            ${traveller.firstname ?? ''}
            ${traveller.middlename ?? ''}
            ${traveller.lastname}
            `
              : 'Nieuwe medereiziger'}
          </div>
        </Col>
        <Col order={{ xs: 1, md: 2 }} xs={2} md={1} style={{ display: 'flex', justifyContent: 'center' }}>
          {isDisabled(index) ? (
            <CircleIcon style={{ filter: 'brightness(0%)' }} />
          ) : (
            <InvertedCheckIconRounded style={{ filter: 'brightness(0%)' }} />
          )}
        </Col>
      </TravellerAccordionItemButton>
    </AccordionItemHeading>
  )
}

export const TravellerAccordionComponent: React.FC<TravellerAccordionComponentProps> = ({
  index,
  traveller,
  opportunityId,
  contacts,
  disabled,
  travellerCount,
}: TravellerAccordionComponentProps) => {
  return (
    <TravellerAccordionItem uuid={index}>
      <TravellerAccordionHeader
        opportunityId={opportunityId}
        index={index}
        traveller={traveller}
        contacts={contacts}
        travellerCount={travellerCount}
      ></TravellerAccordionHeader>
      <TravellerAccordionItemPanel>
        <TravellerForm
          contacts={contacts}
          opportunityId={opportunityId}
          traveller={traveller}
          index={index}
          disabled={disabled}
          travellerCount={travellerCount}
        ></TravellerForm>
      </TravellerAccordionItemPanel>
    </TravellerAccordionItem>
  )
}
