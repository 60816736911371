import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import Moment from 'react-moment'
import { Container } from 'react-grid-system'
import moment from 'moment'

import currency from 'helpers/currency'

import { QuoteDataType } from 'types'

import { ApplicationState } from 'reducers'

import Box from 'styles/Box'
import { H1, H2 } from 'styles/Heading'
import Tooltip from 'styles/Tooltip'
import { CalenderIcon, ClockIcon, MoneyIcon } from 'styles/Icon'

type HeaderStyleType = {
  small: boolean
  source: string
}

const HeaderStyle = styled.div<HeaderStyleType>`
  height: ${({ small }) => (small ? '150px' : '600px')};
  width: auto;
  min-width: 100%;
  min-height: 100%;
  background-image: ${({ source }) => `url('${source}')`};
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 300px;
  overflow: hidden;
`

const BannerContent = styled.div<any>`
  color: #fff;
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 1);
  position: relative;

  width: ${(props: any) => (props.small ? '90%' : '600px')};
  max-width: 90%;

  & p {
    font-weight: 500;
  }
  z-index: 5000;
`

const BannerBackground = styled.div`
  position: absolute;
  width: 600px;
  height: 400px;
  margin-top: -300px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.16);
  -webkit-box-shadow: 0px 0px 120px 0px rgba(0, 0, 0, 0.36);
  -moz-box-shadow: 0px 0px 120px 0px rgba(0, 0, 0, 0.36);
  box-shadow: 0px 0px 120px 0px rgba(0, 0, 0, 0.36);
  z-index: 4000;
`

const SummaryItem = styled.span`
  display: inline-flex;
  align-items: center;
  padding-right: 2rem;

  @media (max-width: 768px) {
    display: flex;
    width: fit-content;
  }
`

const Title = styled(H1)`
  color: #fff;
`

const Subtitle = styled(H2)`
  color: #fff;
  text-transform: none;
  font-size: 2rem;
  font-weight: 400;
`

type HeaderProps = {
  name: string
  small: boolean
  isCheckout: boolean
}

const Header: React.FC<HeaderProps> = ({ name, small, isCheckout }: HeaderProps) => {
  const { itinerary }: QuoteDataType = useSelector((state: ApplicationState) => state.quote.data)

  if (!itinerary) {
    return null
  }

  const { title, headerImage, description, startDate, endDate, totalCosts, travellers } = itinerary

  return (
    <HeaderStyle small={small} source={headerImage}>
      <Container>
        <BannerContent small={small}>
          <Subtitle>{title}</Subtitle>
          {isCheckout ? <Title>Boeken</Title> : <Title>{name}</Title>}

          {!small && (
            <>
              {description && <p>{description}</p>}
              <div>
                {!itinerary.hideDates && startDate && (
                  <Tooltip
                    content={
                      <span>
                        <strong>Uw reis start op:</strong>
                        <div>{moment(startDate).format('D MMMM YYYY')}</div>
                      </span>
                    }
                  >
                    <SummaryItem>
                      <Box m="1rem">
                        <CalenderIcon />
                      </Box>
                      <strong>
                        <Moment format="MMMM YYYY">{startDate}</Moment>
                      </strong>
                    </SummaryItem>
                  </Tooltip>
                )}

                {!itinerary.hideDates && startDate && endDate && (
                  <Tooltip
                    content={
                      <span>
                        <strong>U komt terug op:</strong>
                        <div>{moment(endDate).format('DD MMMM YYYY')}</div>
                      </span>
                    }
                  >
                    <SummaryItem>
                      <Box m="1rem">
                        <ClockIcon />
                      </Box>
                      <strong>
                        <span>{moment(endDate).diff(startDate, 'days') + 1}</span>
                        <span> dagen</span>
                      </strong>
                    </SummaryItem>
                  </Tooltip>
                )}

                {itinerary.hideDates && startDate && endDate && (
                  <SummaryItem>
                    <Box m="1rem">
                      <ClockIcon />
                    </Box>
                    <strong>
                      <span>{moment(endDate).diff(startDate, 'days') + 1}</span>
                      <span> dagen</span>
                    </strong>
                  </SummaryItem>
                )}

                {!itinerary.hidePrices && totalCosts && (
                  <Tooltip
                    content={
                      <span>
                        <strong>Kosten per persoon:</strong>
                        <div>{currency.format(totalCosts / (travellers || 1))}</div>
                      </span>
                    }
                  >
                    <SummaryItem>
                      <Box m="1rem">
                        <MoneyIcon />
                      </Box>
                      <strong>{currency.format(totalCosts)}</strong>
                    </SummaryItem>
                  </Tooltip>
                )}
              </div>
            </>
          )}
        </BannerContent>
        <BannerBackground />
      </Container>
    </HeaderStyle>
  )
}

export default Header
