import styled from 'styled-components/macro'

/**
 * A generic Box component to arrange spacing.
 */

interface BoxProps {
  pt?: string | number
  pr?: string | number
  pb?: string | number
  pl?: string | number
  p?: string | number
  mt?: string | number
  mr?: string | number
  mb?: string | number
  ml?: string | number
  m?: string | number
  align?: 'left' | 'right' | 'center'
  vAlign?: 'flex-start' | 'center' | 'flex-end'
  vAlignSelf?: 'flex-start' | 'center' | 'flex-end'
  inline?: boolean
  style?: any
}

const Box: React.FC<BoxProps> = styled.span<BoxProps>`
  padding: ${({ p }) => p};
  padding-top: ${({ pt }) => pt};
  padding-right: ${({ pr }) => pr};
  padding-bottom: ${({ pb }) => pb};
  padding-left: ${({ pl }) => pl};
  margin: ${({ m }) => m};
  margin-top: ${({ mt }) => mt};
  margin-right: ${({ mr }) => mr};
  margin-bottom: ${({ mb }) => mb};
  margin-left: ${({ ml }) => ml};
  display: ${({ inline }) => (inline ? 'inline-flex' : 'block')};
  text-align: ${({ align }) => align};
  align-items: ${({ vAlign }) => vAlign};
  align-self: ${({ vAlignSelf }) => vAlignSelf};
`

Box.defaultProps = {
  m: '1rem',
  align: 'left',
  vAlign: 'flex-start',
}

export default Box
