import React from 'react'
import { Col } from 'react-grid-system'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import Button from 'styles/Button'

const ButtonContainer = styled(Col)`
  margin: 8px 0px;
  button {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 0.4rem 2rem;
  }

  button[type='neutral'] {
    color: black;
  }
`

type CheckoutTabButtonProps = {
  children?: React.ReactNode
  to?: string
  disabled?: boolean
  active?: boolean
  amount?: number
}
const CheckoutTabButton: React.FC<CheckoutTabButtonProps> = ({
  children,
  disabled,
  to,
  active,
  amount = 3,
}: CheckoutTabButtonProps) => {
  return (
    <ButtonContainer xs={12} md={12 / amount}>
      {to && !disabled && (
        <Link to={to}>
          <Button type={active ? 'primary' : 'neutral'} variant={active ? 'base' : 'lighter'}>
            {children}
          </Button>
        </Link>
      )}
      {!to && !disabled && (
        <Button type={active ? 'primary' : 'neutral'} variant={active ? 'base' : 'lighter'}>
          {children}
        </Button>
      )}
      {disabled && (
        <Button disabled type={active ? 'primary' : 'neutral'} variant={active ? 'base' : 'lighter'}>
          {children}
        </Button>
      )}
    </ButtonContainer>
  )
}

export { CheckoutTabButton }
