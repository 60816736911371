import React from 'react'

import Icon, { IconType } from '../Icon'

const ListIcon: React.FC<IconType> = (props) => (
  <Icon
    {...props}
    viewBox="0 0 24 24"
    width="24"
    height="24"
    preserveAspectRatio="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M23.4227858,-3.90798505e-14 L0.439898226,-3.90798505e-14 C0.121111618,-3.90798505e-14 -0.137315988,0.258427606 -0.137315988,0.577214214 L-0.137315988,2.66656763 C-0.137315988,2.98535424 0.121111618,3.24378184 0.439898226,3.24378184 L23.4227858,3.24378184 C23.7415724,3.24378184 24,2.98535424 24,2.66656763 L24,0.577214214 C24,0.258427606 23.7415724,-3.90798505e-14 23.4227858,-3.90798505e-14 Z" />
    <path d="M23.4227858,8.4802178 L0.439898226,8.4802178 C0.121111618,8.4802178 -0.137315988,8.73864541 -0.137315988,9.05743202 L-0.137315988,11.1467854 C-0.137315988,11.465572 0.121111618,11.7239996 0.439898226,11.7239996 L23.4227858,11.7239996 C23.7415724,11.7239996 24,11.465572 24,11.1467854 L24,9.05743202 C24,8.73864541 23.7415724,8.4802178 23.4227858,8.4802178 Z" />
    <path d="M23.4227858,16.5660454 L0.439898226,16.5660454 C0.121111618,16.5660454 -0.137315988,16.824473 -0.137315988,17.1432596 L-0.137315988,19.232613 C-0.137315988,19.5513996 0.121111618,19.8098272 0.439898226,19.8098272 L23.4227858,19.8098272 C23.7415724,19.8098272 24,19.5513996 24,19.232613 L24,17.1432596 C24,16.824473 23.7415724,16.5660454 23.4227858,16.5660454 Z" />
  </Icon>
)

export default ListIcon
