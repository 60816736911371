import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useScreenClass } from 'react-grid-system'
import { useParams } from 'react-router-dom'

import { QuoteDataType } from 'types'
import { ApplicationState } from 'reducers'
import { ArrowRightIcon, TravellersIcon } from 'styles/Icon'
import styled from 'styled-components'
import LabelButton from 'styles/LabelButton/LabelButton'
import DropdownPanel from './DropdownPanel'

const DropdownHoverContainer = styled.div`
  position: absolute;
  z-index: 9999999;
`

type DropdownProps = {
  participantCount: number
  setParticipantCount: (count: number) => void
  disabled?: boolean
  width?: number
}

const Dropdown: React.FC<DropdownProps> = ({
  setParticipantCount,
  participantCount,
  disabled,
  width = 160,
}: DropdownProps) => {
  const { itinerary }: QuoteDataType = useSelector((state: ApplicationState) => state.quote.data)
  const [active, setActive] = useState(false)
  const ref = useRef(null)

  useEffect(() => {
    const handleClickOutside = (event: { target: any }) => {
      const current = ref.current as any
      if (current && current.contains(event.target)) return
      setActive(false)
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref])

  if (!itinerary) return null

  const open = () => {
    setActive(true)
  }

  const label = participantCount === 1 ? 'persoon' : 'personen'
  return (
    <div ref={ref} style={{ display: 'flex', flexDirection: 'column' }}>
      <LabelButton
        small
        style={{ width: width || 160 }}
        disabled={disabled}
        iconMargin="0.5rem"
        iconMarginInside
        beforeIcon={<TravellersIcon color="neutral" size="1rem" />}
        afterIcon={
          <ArrowRightIcon
            style={{
              transition: 'fill 0.25s ease-out 0s',
              transform: active ? 'rotate(-90deg)' : 'rotate(90deg)',
              ...(!active && { marginBottom: '-8px' }),
              ...(active && { marginTop: '-4px' }),
            }}
            color="neutral"
            size="1rem"
          />
        }
        onClick={open}
        outline={!!participantCount}
        selected={!!participantCount}
      >
        {participantCount ? `${participantCount} ${label}` : 'Selecteren'}
      </LabelButton>
      {active && (
        <div style={{ position: 'relative' }}>
          <DropdownHoverContainer>
            <DropdownPanel
              maxParticipantCount={itinerary.travellers}
              setParticipantCount={setParticipantCount}
              setActive={setActive}
              width={width || 160}
            ></DropdownPanel>
          </DropdownHoverContainer>
        </div>
      )}
    </div>
  )
}

export default Dropdown
