import React from 'react'
import useCompany from 'hooks/useCompany'

import useItinerary from 'hooks/useItinerary'
import { LogoType } from './LogoWrapper'

import MOR from './logos/MOR'
import OTC from './logos/OTC'
import SLR from './logos/SLR'
import TRL from './logos/TRL'
import BKR from './logos/BKR'

const Logo: React.FC<LogoType> = (props: LogoType) => {
  const { env } = useCompany()
  const itinerary = useItinerary()

  if (!itinerary) {
    return <MOR {...props} />
  }

  if (itinerary.isZRA && itinerary.zra.logoUrl) {
    return <img src={itinerary.zra.logoUrl} alt={itinerary.zra.name} width="300px" height="30px" />
  }

  if (env.includes('mor')) {
    return <MOR {...props} />
  }

  if (env.includes('otc')) {
    return <OTC {...props} />
  }

  if (env.includes('slr')) {
    return <SLR {...props} />
  }

  if (env.includes('trl')) {
    return <TRL {...props} />
  }

  if (env.includes('bkr')) {
    return <BKR {...props} />
  }

  return <MOR {...props} />
}

export default Logo
