import React from 'react'

import Icon, { IconType } from '../Icon'

const CarIcon: React.FC<IconType> = (props) => (
  <Icon
    {...props}
    viewBox="0 0 24 24"
    width="24"
    height="24"
    preserveAspectRatio="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.4,6 L19.8,6 L15.72075,0.90075 C15.2651769,0.331491348 14.5756106,0 13.8465,0 L5.824875,0 C4.8435,0 3.961125,0.597375 3.596625,1.508625 L1.8,6.08475 C0.7665,6.3525 0,7.282875 0,8.4 L0,12.6 C0,12.9315 0.2685,13.2 0.6,13.2 L2.4,13.2 C2.4,15.18825 4.01175,16.8 6,16.8 C7.98825,16.8 9.6,15.18825 9.6,13.2 L14.4,13.2 C14.4,15.18825 16.01175,16.8 18,16.8 C19.98825,16.8 21.6,15.18825 21.6,13.2 L23.4,13.2 C23.7315,13.2 24,12.9315 24,12.6 L24,9.6 C24,7.61175 22.38825,6 20.4,6 Z M6,15 C5.007375,15 4.2,14.192625 4.2,13.2 C4.2,12.207375 5.007375,11.4 6,11.4 C6.992625,11.4 7.8,12.207375 7.8,13.2 C7.8,14.192625 6.992625,15 6,15 Z M8.7,6 L4.384875,6 L5.824875,2.4 L8.7,2.4 L8.7,6 Z M10.5,6 L10.5,2.4 L13.8465,2.4 L16.7265,6 L10.5,6 Z M18,15 C17.007375,15 16.2,14.192625 16.2,13.2 C16.2,12.207375 17.007375,11.4 18,11.4 C18.992625,11.4 19.8,12.207375 19.8,13.2 C19.8,14.192625 18.992625,15 18,15 Z"
      transform="translate(0, 3)"
    />
  </Icon>
)

export default CarIcon
