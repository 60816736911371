import React from 'react'

import Icon, { IconType } from '../Icon'

const ClockIcon: React.FC<IconType> = (props) => (
  <Icon
    {...props}
    viewBox="0 0 24 24"
    width="24"
    height="24"
    preserveAspectRatio="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12,0.625 C5.71835938,0.625 0.625,5.71835937 0.625,12 C0.625,18.2816406 5.71835938,23.375 12,23.375 C18.2816406,23.375 23.375,18.2816406 23.375,12 C23.375,5.71835937 18.2816406,0.625 12,0.625 Z M12,21.4453125 C6.78476563,21.4453125 2.5546875,17.2152344 2.5546875,12 C2.5546875,6.78476562 6.78476563,2.5546875 12,2.5546875 C17.2152344,2.5546875 21.4453125,6.78476562 21.4453125,12 C21.4453125,17.2152344 17.2152344,21.4453125 12,21.4453125 Z"
      transform="translate(0, 0.5)"
    />
    <path
      d="M16.4357422,15.2144531 L12.8150391,12.5966797 L12.8150391,6.3125 C12.8150391,6.20078125 12.7236328,6.109375 12.6119141,6.109375 L11.390625,6.109375 C11.2789062,6.109375 11.1875,6.20078125 11.1875,6.3125 L11.1875,13.3050781 C11.1875,13.3710937 11.2179687,13.4320312 11.2712891,13.4701172 L15.4708984,16.5322266 C15.5623047,16.5982422 15.6892578,16.5779297 15.7552734,16.4890625 L16.4814453,15.4988281 C16.5474609,15.4048828 16.5271484,15.2779297 16.4357422,15.2144531 L16.4357422,15.2144531 Z"
      transform="translate(0, 0.5)"
    />
  </Icon>
)

export default ClockIcon
