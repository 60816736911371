import React from 'react'

import Icon, { IconType } from '../Icon'

const ArrowLeftIcon: React.FC<IconType> = (props) => (
  <Icon
    {...props}
    viewBox="0 0 24 24"
    width="24"
    height="24"
    preserveAspectRatio="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M16.9285714,11.1428571 L12.8571429,11.1428571 L12.8571429,7.07142857 C12.8571429,6.95357143 12.7607143,6.85714286 12.6428571,6.85714286 L11.3571429,6.85714286 C11.2392857,6.85714286 11.1428571,6.95357143 11.1428571,7.07142857 L11.1428571,11.1428571 L7.07142857,11.1428571 C6.95357143,11.1428571 6.85714286,11.2392857 6.85714286,11.3571429 L6.85714286,12.6428571 C6.85714286,12.7607143 6.95357143,12.8571429 7.07142857,12.8571429 L11.1428571,12.8571429 L11.1428571,16.9285714 C11.1428571,17.0464286 11.2392857,17.1428571 11.3571429,17.1428571 L12.6428571,17.1428571 C12.7607143,17.1428571 12.8571429,17.0464286 12.8571429,16.9285714 L12.8571429,12.8571429 L16.9285714,12.8571429 C17.0464286,12.8571429 17.1428571,12.7607143 17.1428571,12.6428571 L17.1428571,11.3571429 C17.1428571,11.2392857 17.0464286,11.1428571 16.9285714,11.1428571 Z" />
    <path d="M12,0 C5.37321429,0 0,5.37321429 0,12 C0,18.6267857 5.37321429,24 12,24 C18.6267857,24 24,18.6267857 24,12 C24,5.37321429 18.6267857,0 12,0 Z M12,21.9642857 C6.49821429,21.9642857 2.03571429,17.5017857 2.03571429,12 C2.03571429,6.49821429 6.49821429,2.03571429 12,2.03571429 C17.5017857,2.03571429 21.9642857,6.49821429 21.9642857,12 C21.9642857,17.5017857 17.5017857,21.9642857 12,21.9642857 Z" />
  </Icon>
)

export default ArrowLeftIcon
