import React from 'react'

import { Logo } from 'styles/Logo'
import { LayoutCenter } from 'styles/Layout'

const LogoPage: React.FC = () => (
  <LayoutCenter>
    <Logo variant="dark" />
  </LayoutCenter>
)

export default LogoPage
