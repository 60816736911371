import React from 'react'
import { useSelector } from 'react-redux'
import { Container, useScreenClass } from 'react-grid-system'
import { Link, useParams } from 'react-router-dom'

import Box from 'styles/Box'
import Button from 'styles/Button'
import { H3 } from 'styles/Heading'
import Alert from 'styles/Alert'
import Anchor from 'styles/Anchor'
import { ArrowRightIcon, ArrowLeftIcon } from 'styles/Icon'

import { FlightType, FlightOptionType, QuoteDataType } from 'types'
import { ApplicationState } from 'reducers'

import logoTraveLife from 'assets/images/logo-travelife.png'

import useCompany from 'hooks/useCompany'

import FlightOption from './FlightOption'
import TravelifeIcon from 'styles/Icon/icons/Travelife'

const Flights: React.FC = () => {
  const { itineraryId }: any = useParams()
  const { flights, itinerary }: QuoteDataType = useSelector((state: ApplicationState) => state.quote.data)
  const { co2Link } = useCompany()

  const screenClass = useScreenClass()
  const inlineButtons = ['sm', 'md', 'lg', 'xl', 'xxl'].includes(screenClass)
  if (!itinerary) {
    return null
  }

  return (
    <Container>
      {!flights?.length && (
        <Box m={0} mt="2rem" mb="2rem">
          <Alert>
            Aan deze reis zijn geen vluchten toegevoegd. Wilt u een vlucht boeken, laat het ons dan vooral weten!
          </Alert>
        </Box>
      )}

      {flights.length > 0 && (
        <>
          {itinerary?.status === 'open' && (
            <Box m={0} mt="2rem">
              <p>
                Hebt u voorkeur voor een andere vlucht? Laat het ons weten dan passen we dit graag aan in de offerte.
              </p>
            </Box>
          )}

          <Box m={0} mt="2rem">
            <H3>Geef uw vluchtvoorkeur aan</H3>
          </Box>

          {flights.map((flight: FlightType) => {
            const orderedByAmount = [...flight.options].sort((a, b) => a.sellAmount - b.sellAmount)

            return (
              <React.Fragment key={flight.id}>
                {flight.options.map((option: FlightOptionType) => (
                  <FlightOption key={option.id} {...option} flightId={flight.id} orderedByAmount={orderedByAmount} />
                ))}
              </React.Fragment>
            )
          })}
        </>
      )}

      <Box m={0} mt="1rem" mb="2rem" inline={inlineButtons}>
        <Box m={0} mb="1rem" mr="1rem">
          <Link to={`/${itineraryId}/itinerary`}>
            <Button type="neutral" variant="dark" small beforeIcon={<ArrowLeftIcon size="1rem" />}>
              Programma
            </Button>
          </Link>
        </Box>

        <Link to={`/${itineraryId}/accommodations`}>
          <Button type="primary" small afterIcon={<ArrowRightIcon size="1rem" />}>
            Accommodaties
          </Button>
        </Link>
      </Box>

      <div>
        <Box inline={inlineButtons} vAlign="center" ml={0} mb="6rem">
          <Box m={0} mr="1rem">
            <TravelifeIcon color="neutral"></TravelifeIcon>
          </Box>
          <span>
            Wij compenseren standaard de uitgestoten CO2 van uw vlucht. Meer weten? Lees{' '}
            <Anchor href={co2Link} target="_blank">
              hier
            </Anchor>{' '}
            meer.
          </span>
        </Box>
      </div>
    </Container>
  )
}

export default Flights
