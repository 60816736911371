import MorLogo from 'assets/images/mor-logo.png'
import OtcLogo from 'assets/images/otc-logo.png'
import SlrLogo from 'assets/images/slr-logo.png'
import TrlLogo from 'assets/images/trl-logo.png'
import BkrLogo from 'assets/images/bkr-logo.svg'

export type Company = {
  env: Environment
  company: CompanyName
  short: Short
  logo: typeof MorLogo | typeof OtcLogo | typeof SlrLogo | typeof BkrLogo
  trustPilotLink: TrustPilotLink
  co2Link: string
  IBAN: string
  BIC: string
  BTW: string
  KVK: string
  TACLink: string
  privacyLink: string
  disclaimerLink: string
}

export type Short = 'mor' | 'otc' | 'slr' | 'trl' | 'trw' | 'bkr'

export type Environment =
  | 'mor-dev'
  | 'otc-dev'
  | 'slr-dev'
  | 'trl-dev'
  | 'trw-dev'
  | 'bkr-dev'
  | 'mor-test'
  | 'otc-test'
  | 'slr-test'
  | 'trl-test'
  | 'trw-test'
  | 'bkr-test'
  | 'mor-prod'
  | 'otc-prod'
  | 'slr-prod'
  | 'trl-prod'
  | 'trw-prod'
  | 'bkr-prod'

export type CompanyName =
  | 'Middenoostenreizen.com'
  | 'Official Travel Company'
  | 'Sri Lanka Reizen'
  | 'Srilankarondreis.com'
  | 'Travel Legends'
  | 'Travelwizzard.nl'
  | 'Balkan Reizen'

export type TrustPilotLink =
  | 'www.middenoostenreizen.com'
  | 'officialtravelcompany.com'
  | 'srilankareizen.com'
  | 'srilankarondreis.com'
  | 'travellegends.nl'
  | 'travelwizzard.nl'
  | 'balkanreizen.com'

const useCompany = (): Company => {
  switch (window.location.host) {
    case 'localhost:3000':
      return {
        env: 'mor-dev',
        company: 'Middenoostenreizen.com',
        short: 'mor',
        logo: MorLogo,
        trustPilotLink: 'www.middenoostenreizen.com',
        co2Link: 'https://middenoostenreizen.com/verantwoord-op-reis/',
        IBAN: 'NL94 ABNA 0540 9791 20',
        BIC: 'ABNANL2A',
        BTW: 'NL858209287B01',
        KVK: '70241481',
        TACLink: 'https://www.middenoostenreizen.com/algemene-voorwaarden/',
        privacyLink: 'https://middenoostenreizen.com/privacy-policy/',
        disclaimerLink: 'https://www.middenoostenreizen.com/disclaimer/',
      }

    case 'localhost:4000':
      return {
        env: 'otc-dev',
        company: 'Official Travel Company',
        short: 'otc',
        logo: OtcLogo,
        trustPilotLink: 'officialtravelcompany.com',
        co2Link: 'https://www.officialtravelcompany.com/over-ons/verantwoord-op-reis',
        IBAN: 'NL80 ABNA 0427 3044 90',
        BIC: 'ABNANL2A',
        BTW: 'NL858208921B01',
        KVK: '70240663',
        TACLink: 'https://www.officialtravelcompany.com/algemene-voorwaarden',
        privacyLink: 'https://www.officialtravelcompany.com/PRIVACYSTATEMENT-OTC-BV.pdf',
        disclaimerLink: 'https://www.officialtravelcompany.com/disclaimer',
      }

    case 'localhost:5056':
      return {
        env: 'slr-dev',
        company: 'Sri Lanka Reizen',
        short: 'slr',
        logo: SlrLogo,
        trustPilotLink: 'srilankareizen.com',
        co2Link: 'https://srilankareizen.com/verantwoord-op-reis/',
        IBAN: 'NL94 ABNA 0540 9791 20',
        BIC: 'ABNANL2A',
        BTW: 'NL858209287B01',
        KVK: '70241481',
        TACLink: 'https://www.srilankareizen.com/algemene-voorwaarden',
        privacyLink: 'https://srilankareizen.com/privacy-policy/',
        disclaimerLink: 'https://www.srilankareizen.com/disclaimer',
      }
    case 'localhost:5057':
      return {
        env: 'slr-dev',
        company: 'Srilankarondreis.com',
        short: 'slr',
        logo: SlrLogo,
        trustPilotLink: 'srilankarondreis.com',
        co2Link: 'https://srilankareizen.com/verantwoord-op-reis/',
        IBAN: 'NL94 ABNA 0540 9791 20',
        BIC: 'ABNANL2A',
        BTW: 'NL858209287B01',
        KVK: '70241481',
        TACLink: 'https://www.srilankareizen.com/algemene-voorwaarden',
        privacyLink: 'https://srilankareizen.com/privacy-policy/',
        disclaimerLink: 'https://www.srilankareizen.com/disclaimer',
      }
    case 'localhost:3002':
      return {
        env: 'trl-dev',
        company: 'Travel Legends',
        short: 'trl',
        logo: TrlLogo,
        trustPilotLink: 'travellegends.nl',
        co2Link: 'https://travellegends.nl/verantwoord-op-reis/',
        IBAN: 'NL94 ABNA 0540 9791 20',
        BIC: 'ABNANL2A',
        BTW: 'NL858209287B01',
        KVK: '70241481',
        TACLink: 'https://travellegends.nl/algemene-voorwaarden/',
        privacyLink: 'https://travellegends.nl/privacy-policy/',
        disclaimerLink: 'https://travellegends.nl/disclaimer/',
      }
    case 'localhost:3003':
      return {
        env: 'trw-dev',
        company: 'Travelwizzard.nl',
        short: 'trw',
        logo: MorLogo,
        trustPilotLink: 'www.middenoostenreizen.com',
        co2Link: 'https://middenoostenreizen.com/verantwoord-op-reis/',
        IBAN: 'NL94 ABNA 0540 9791 20',
        BIC: 'ABNANL2A',
        BTW: 'NL858209287B01',
        KVK: '70241481',
        TACLink: 'https://www.middenoostenreizen.com/algemene-voorwaarden/',
        privacyLink: 'https://middenoostenreizen.com/privacy-policy/',
        disclaimerLink: 'https://www.middenoostenreizen.com/disclaimer/',
      }
    case 'localhost:3004':
      return {
        env: 'bkr-dev',
        company: 'Balkan Reizen',
        short: 'bkr',
        logo: BkrLogo,
        trustPilotLink: 'balkanreizen.com',
        co2Link: 'https://www.balkanreizen.com/verantwoord-op-reis',
        IBAN: 'NL94 ABNA 0540 9791 20',
        BIC: 'ABNANL2A',
        BTW: 'NL858209287B01',
        KVK: '70241481',
        TACLink: 'https://www.balkanreizen.com/algemene-voorwaarden',
        privacyLink: 'https://balkanreizen.com/privacy-policy/',
        disclaimerLink: 'https://www.balkanreizen.com/disclaimer',
      }
    case 'test.middenoostenreizen.com':
      return {
        env: 'mor-test',
        company: 'Middenoostenreizen.com',
        short: 'mor',
        logo: MorLogo,
        trustPilotLink: 'www.middenoostenreizen.com',
        co2Link: 'https://middenoostenreizen.com/verantwoord-op-reis/',
        IBAN: 'NL94 ABNA 0540 9791 20',
        BIC: 'ABNANL2A',
        BTW: 'NL858209287B01',
        KVK: '70241481',
        TACLink: 'https://www.middenoostenreizen.com/algemene-voorwaarden/',
        privacyLink: 'https://middenoostenreizen.com/privacy-policy/',
        disclaimerLink: 'https://www.middenoostenreizen.com/disclaimer/',
      }

    case 'test.officialtravelcompany.com':
      return {
        env: 'otc-test',
        company: 'Official Travel Company',
        short: 'otc',
        logo: OtcLogo,
        trustPilotLink: 'officialtravelcompany.com',
        co2Link: 'https://www.officialtravelcompany.com/over-ons/verantwoord-op-reis',
        IBAN: 'NL80 ABNA 0427 3044 90',
        BIC: 'ABNANL2A',
        BTW: 'NL858208921B01',
        KVK: '70240663',
        TACLink: 'https://www.officialtravelcompany.com/algemene-voorwaarden',
        privacyLink: 'https://www.officialtravelcompany.com/PRIVACYSTATEMENT-OTC-BV.pdf',
        disclaimerLink: 'https://www.officialtravelcompany.com/disclaimer',
      }

    case 'test.srilankareizen.com':
      return {
        env: 'slr-test',
        company: 'Sri Lanka Reizen',
        short: 'slr',
        logo: SlrLogo,
        trustPilotLink: 'srilankareizen.com',
        co2Link: 'https://www.srilankareizen.com/verantwoord-op-reis',
        IBAN: 'NL94 ABNA 0540 9791 20',
        BIC: 'ABNANL2A',
        BTW: 'NL858209287B01',
        KVK: '70241481',
        TACLink: 'https://www.srilankareizen.com/algemene-voorwaarden',
        privacyLink: 'https://srilankareizen.com/privacy-policy/',
        disclaimerLink: 'https://www.srilankareizen.com/disclaimer',
      }
    case 'test.srilankarondreis.com':
      return {
        env: 'slr-test',
        company: 'Srilankarondreis.com',
        short: 'slr',
        logo: SlrLogo,
        trustPilotLink: 'srilankarondreis.com',
        co2Link: 'https://srilankareizen.com/verantwoord-op-reis/',
        IBAN: 'NL94 ABNA 0540 9791 20',
        BIC: 'ABNANL2A',
        BTW: 'NL858209287B01',
        KVK: '70241481',
        TACLink: 'https://www.srilankareizen.com/algemene-voorwaarden',
        privacyLink: 'https://srilankareizen.com/privacy-policy/',
        disclaimerLink: 'https://www.srilankareizen.com/disclaimer',
      }
    case 'test.travellegends.nl':
      return {
        env: 'trl-test',
        company: 'Travel Legends',
        short: 'trl',
        logo: TrlLogo,
        trustPilotLink: 'travellegends.nl',
        co2Link: 'https://travellegends.nl/verantwoord-op-reis/',
        IBAN: 'NL94 ABNA 0540 9791 20',
        BIC: 'ABNANL2A',
        BTW: 'NL858209287B01',
        KVK: '70241481',
        TACLink: 'https://travellegends.nl/algemene-voorwaarden/',
        privacyLink: 'https://travellegends.nl/privacy-policy/',
        disclaimerLink: 'https://travellegends.nl/disclaimer/',
      }
    case 'test.travelwizzard.nl':
      return {
        env: 'trw-test',
        company: 'Travelwizzard.nl',
        short: 'trw',
        logo: MorLogo,
        trustPilotLink: 'www.middenoostenreizen.com',
        co2Link: 'https://middenoostenreizen.com/verantwoord-op-reis/',
        IBAN: 'NL94 ABNA 0540 9791 20',
        BIC: 'ABNANL2A',
        BTW: 'NL858209287B01',
        KVK: '70241481',
        TACLink: 'https://www.middenoostenreizen.com/algemene-voorwaarden/',
        privacyLink: 'https://middenoostenreizen.com/privacy-policy/',
        disclaimerLink: 'https://www.middenoostenreizen.com/disclaimer/',
      }
    case 'test.balkanreizen.com':
      return {
        env: 'bkr-test',
        company: 'Balkan Reizen',
        short: 'bkr',
        logo: BkrLogo,
        trustPilotLink: 'balkanreizen.com',
        co2Link: 'https://www.balkanreizen.com/verantwoord-op-reis',
        IBAN: 'NL94 ABNA 0540 9791 20',
        BIC: 'ABNANL2A',
        BTW: 'NL858209287B01',
        KVK: '70241481',
        TACLink: 'https://www.balkanreizen.com/algemene-voorwaarden',
        privacyLink: 'https://balkanreizen.com/privacy-policy/',
        disclaimerLink: 'https://www.balkanreizen.com/disclaimer/',
      }
    case 'offerte.middenoostenreizen.com':
      return {
        env: 'mor-prod',
        company: 'Middenoostenreizen.com',
        short: 'mor',
        logo: MorLogo,
        trustPilotLink: 'www.middenoostenreizen.com',
        co2Link: 'https://middenoostenreizen.com/verantwoord-op-reis/',
        IBAN: 'NL94 ABNA 0540 9791 20',
        BIC: 'ABNANL2A',
        BTW: 'NL858209287B01',
        KVK: '70241481',
        TACLink: 'https://www.middenoostenreizen.com/algemene-voorwaarden/',
        privacyLink: 'https://middenoostenreizen.com/privacy-policy/',
        disclaimerLink: 'https://www.middenoostenreizen.com/disclaimer/',
      }

    case 'offerte.officialtravelcompany.com':
      return {
        env: 'otc-prod',
        company: 'Official Travel Company',
        short: 'otc',
        logo: OtcLogo,
        trustPilotLink: 'officialtravelcompany.com',
        co2Link: 'https://www.officialtravelcompany.com/over-ons/verantwoord-op-reis',
        IBAN: 'NL80 ABNA 0427 3044 90',
        BIC: 'ABNANL2A',
        BTW: 'NL858208921B01',
        KVK: '70240663',
        TACLink: 'https://www.officialtravelcompany.com/algemene-voorwaarden',
        privacyLink: 'https://www.officialtravelcompany.com/PRIVACYSTATEMENT-OTC-BV.pdf',
        disclaimerLink: 'https://www.officialtravelcompany.com/disclaimer',
      }

    case 'offerte.srilankareizen.com':
      return {
        env: 'slr-prod',
        company: 'Sri Lanka Reizen',
        short: 'slr',
        logo: SlrLogo,
        trustPilotLink: 'srilankareizen.com',
        co2Link: 'https://www.srilankareizen.com/verantwoord-op-reis',
        IBAN: 'NL94 ABNA 0540 9791 20',
        BIC: 'ABNANL2A',
        BTW: 'NL858209287B01',
        KVK: '70241481',
        TACLink: 'https://www.srilankareizen.com/algemene-voorwaarden',
        privacyLink: 'https://srilankareizen.com/privacy-policy/',
        disclaimerLink: 'https://www.srilankareizen.com/disclaimer',
      }

    case 'offerte.srilankarondreis.com':
      return {
        env: 'slr-prod',
        company: 'Srilankarondreis.com',
        short: 'slr',
        logo: SlrLogo,
        trustPilotLink: 'srilankarondreis.com',
        co2Link: 'https://srilankareizen.com/verantwoord-op-reis/',
        IBAN: 'NL94 ABNA 0540 9791 20',
        BIC: 'ABNANL2A',
        BTW: 'NL858209287B01',
        KVK: '70241481',
        TACLink: 'https://www.srilankareizen.com/algemene-voorwaarden',
        privacyLink: 'https://srilankareizen.com/privacy-policy/',
        disclaimerLink: 'https://www.srilankareizen.com/disclaimer',
      }

    case 'offerte.travellegends.nl':
      return {
        env: 'trl-prod',
        company: 'Travel Legends',
        short: 'trl',
        logo: TrlLogo,
        trustPilotLink: 'travellegends.nl',
        co2Link: 'https://travellegends.nl/verantwoord-op-reis/',
        IBAN: 'NL94 ABNA 0540 9791 20',
        BIC: 'ABNANL2A',
        BTW: 'NL858209287B01',
        KVK: '70241481',
        TACLink: 'https://travellegends.nl/algemene-voorwaarden/',
        privacyLink: 'https://travellegends.nl/privacy-policy/',
        disclaimerLink: 'https://travellegends.nl/disclaimer/',
      }

    case 'offerte.travelwizzard.nl':
      return {
        env: 'trw-prod',
        company: 'Travelwizzard.nl',
        short: 'trw',
        logo: MorLogo,
        trustPilotLink: 'www.middenoostenreizen.com',
        co2Link: 'https://www.middenoostenreizen.com/verantwoord-reizen/',
        IBAN: 'NL94 ABNA 0540 9791 20',
        BIC: 'ABNANL2A',
        BTW: 'NL858209287B01',
        KVK: '70241481',
        TACLink: 'https://www.middenoostenreizen.com/algemene-voorwaarden/',
        privacyLink: 'https://middenoostenreizen.com/privacy-policy/',
        disclaimerLink: 'https://www.middenoostenreizen.com/disclaimer/',
      }

    case 'offerte.balkanreizen.com':
      return {
        env: 'bkr-prod',
        company: 'Balkan Reizen',
        short: 'bkr',
        logo: BkrLogo,
        trustPilotLink: 'balkanreizen.com',
        co2Link: 'https://www.balkanreizen.com/verantwoord-op-reis',
        IBAN: 'NL94 ABNA 0540 9791 20',
        BIC: 'ABNANL2A',
        BTW: 'NL858209287B01',
        KVK: '70241481',
        TACLink: 'https://www.balkanreizen.com/algemene-voorwaarden',
        privacyLink: 'https://balkanreizen.com/privacy-policy/',
        disclaimerLink: 'https://www.balkanreizen.com/disclaimer',
      }

    default:
      return {
        env: 'mor-prod',
        company: 'Middenoostenreizen.com',
        short: 'mor',
        logo: MorLogo,
        trustPilotLink: 'www.middenoostenreizen.com',
        co2Link: 'https://middenoostenreizen.com/verantwoord-op-reis/',
        IBAN: 'NL94 ABNA 0540 9791 20',
        BIC: 'ABNANL2A',
        BTW: 'NL858209287B01',
        KVK: '70241481',
        TACLink: 'https://www.middenoostenreizen.com/algemene-voorwaarden/',
        privacyLink: 'https://middenoostenreizen.com/privacy-policy/',
        disclaimerLink: 'https://www.middenoostenreizen.com/disclaimer/',
      }
  }
}

export default useCompany
