import React from 'react'

import Icon, { IconType } from '../Icon'

const FlightIcon: React.FC<IconType> = (props) => (
  <Icon
    {...props}
    viewBox="0 0 24 24"
    width="24"
    height="24"
    preserveAspectRatio="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24,17.052 L24,14.778 L14.4,7.2 L14.4,2.4 C14.4,1.0745166 13.3254834,-1.77635684e-15 12,-1.77635684e-15 C10.6745166,-1.77635684e-15 9.6,1.0745166 9.6,2.4 L9.6,7.326 L-1.77635684e-15,14.904 L-1.77635684e-15,17.0508 L9.6,13.6788 L9.6,19.2 L6,21.6 L6,24 L12,21.6 L18,24 L18,21.6 L14.4,19.2 L14.4,13.578 L24,17.052 Z"
      transform="translate(12.000000, 12.000000) rotate(-270.000000) translate(-12.000000, -12.000000)"
    />
  </Icon>
)

export default FlightIcon
