import React, { ReactNode } from 'react'
import styled from 'styled-components'

import Box from 'styles/Box'
import Text from 'styles/Text'
import Alignment from 'styles/Alignment'
import FormLabel from './FormLabel'

type SelectProps = {
  input: any
  label?: ReactNode
  meta: any
  isRequired: boolean
  children: Element | Element[]
  noLabelMargin?: boolean
}

type SelectType = {
  isInvalid: boolean
}

const Select = styled.select<SelectType>`
  background-color: ${({ theme, isInvalid }) =>
    isInvalid ? theme.color.danger.lightest : theme.color.neutral.lightest};

  font-family: 'Raleway', sans-serif;
  border: none;
  letter-spacing: 1.25px;
  padding: 0.75rem 1.25rem;
  border-radius: 0;
  outline: none;
  width: 100%;
  font-size: 1rem;
  margin-bottom: ${({ isInvalid }) => (isInvalid ? '0.75rem' : '1.5rem')};
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
`

const Caret = styled.span`
  content: ' ';
  outline: none;
  position: absolute;
  border-top: 5px solid #000;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  margin-left: -1.5rem;
  margin-top: 1rem;
  z-index: 999;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`

const FieldContainer = styled.div`
  width: 100%;
`

const Field: React.FC<any> = ({ input, label, meta, isRequired, children, noLabelMargin, ...props }: SelectProps) => {
  const isInvalid = (meta.error || (meta.submitError && !meta.dirtySinceLastSubmit)) && meta.touched

  return (
    <FieldContainer>
      {label && (
        <Alignment>
          <FormLabel noLabelMargin={noLabelMargin}>{label}</FormLabel>

          {isRequired && (
            <Box m={0} ml="0.25em" inline>
              <Text color="primary" size="lg">
                *
              </Text>
            </Box>
          )}
        </Alignment>
      )}

      <Select isInvalid={isInvalid} {...input} {...props}>
        {children}
      </Select>
      <Caret />

      {isInvalid && (
        <Box m={0} mb="1rem">
          <Text color="danger" size="sm">
            {meta.error || meta.submitError}
          </Text>
        </Box>
      )}
    </FieldContainer>
  )
}

export default Field
