import React, { ReactNode } from 'react'

import styled from 'styled-components'

import Box from 'styles/Box'
import Text from 'styles/Text'
import Alignment from 'styles/Alignment'
import FormLabel from './FormLabel'

interface FieldProps {
  input: any
  label?: ReactNode
  meta: any
  onChange: any
  isRequired?: boolean
}

type InputType = {
  isInvalid: boolean
}

const Input = styled.input<InputType>`
  background-color: ${({ theme, isInvalid }) =>
    isInvalid ? theme.color.danger.lightest : theme.color.neutral.lightest};

  font-family: 'Raleway', sans-serif;
  border: none;
  letter-spacing: 1.25px;
  padding: 0.75rem 1.25rem;
  border-radius: 0;
  outline: none;
  width: calc(100% - 2rem);
  font-size: 0.9rem;
  margin-bottom: ${({ isInvalid }) => (isInvalid ? '0.75rem' : '1.5rem')};
`

const FieldContainer = styled.div`
  width: 100%;
`

const InputContainer = styled.div`
  display: contents;
`

const Field: React.FC<any> = ({ input, label, isRequired, meta, ...props }: FieldProps) => {
  const isInvalid = (meta.error || (meta.submitError && !meta.dirtySinceLastSubmit)) && meta.touched

  return (
    <FieldContainer>
      {label && (
        <Alignment>
          <FormLabel>{label}</FormLabel>
          {isRequired && (
            <Box m={0} ml="0.25em" inline>
              <Text color="primary" size="lg">
                *
              </Text>
            </Box>
          )}
        </Alignment>
      )}
      <InputContainer>
        <Input {...input} {...props} isInvalid={isInvalid} />
      </InputContainer>

      {isInvalid && (
        <Box m={0} mb="1rem">
          <Text color="danger" size="sm">
            {meta.error || meta.submitError}
          </Text>
        </Box>
      )}
    </FieldContainer>
  )
}

export default Field
