import { useSelector } from 'react-redux'

import { QuoteDataType, ItineraryType } from 'types'
import { ApplicationState } from 'reducers'

const useItinerary = (): ItineraryType | null => {
  const { itinerary }: QuoteDataType = useSelector((state: ApplicationState) => state.quote.data)

  if (!itinerary) {
    return null
  }

  return itinerary
}

export default useItinerary
