import React, { ReactElement } from 'react'
import styled, { css } from 'styled-components'

type ButtonType = {
  children: any
  type?: 'primary' | 'neutral' | 'danger'
  variant?: 'base' | 'dark' | 'light' | 'lighter' | 'lightest'
  outline?: boolean
  small?: boolean
  onClick?: (a?: any) => any
  disabled?: boolean
  beforeIcon?: ReactElement | Element
  afterIcon?: ReactElement | Element
  style?: React.CSSProperties
  selected?: boolean
  iconMargin?: string
  iconMarginInside?: boolean
  textStyle?: React.CSSProperties
}

const ButtonStyle = styled.button<any>`
  height: fit-content;
  cursor: pointer;
  border-radius: none;
  outline: none;
  transition: 0.25s ease-out;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem ${({ hasIcons }) => (hasIcons ? '0.5rem' : '1.5rem')};
  background-color: ${({ theme, type, variant }) => theme.color[type!][variant!]};
  border: 2px solid ${({ theme, type, variant }) => theme.color[type!][variant!]};
  color: white;
  min-width: 160px;
  & svg {
    fill: white;
  }
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.color.primary.base};


  :disabled {
    cursor: not-allowed;
    background-color: ${({ theme, type }) => theme.color[type!].lightest};
    border: 1px solid ${({ theme, type }) => theme.color[type!].lightest};
    color: ${({ theme, type }) => theme.color[type!].light};

    & svg {
      fill: ${({ theme, type }) => theme.color[type!].light};
    }
  }

  ${({ theme, type }) =>
    type === 'primary' &&
    css`
      :hover {
        background-color: ${theme.color[type!].light};
        border: 1px solid ${theme.color[type!].light};
        color: white;
      }
      & svg {
        transition: 0.25s ease-out;
      }
      :hover svg {
        fill: white;
      }
    `}

  ${({ theme, type }) =>
    type === 'neutral' &&
    css`
      :hover {
        background-color: ${theme.color[type!].base};
        border: 1px solid ${theme.color[type!].base};
        color: white;
      }
    `}

  ${({ outline, theme, type, variant, selected }) =>
    outline &&
    !selected &&
    css`
      background-color: transparent;
      border: 1px solid ${theme.color[type!][variant!]};
      color: ${theme.color[type!][variant!]};
      & svg {
        fill: ${theme.color[type!][variant!]};
      }
      :hover:enabled {
        background-color: ${theme.color[type!][variant!]};
        border: 1px solid ${theme.color[type!][variant!]};
        color: ${variant === 'lightest' ? 'black' : 'white'};

        & svg {
          transition: 0.25s ease-out;
          fill: ${variant === 'lightest' ? 'black' : 'white'};
        }
      }
    `}

    ${({ outline, theme, type, variant, selected }) =>
    outline &&
    selected &&
    css`
        background-color: transparent;
        border: 1px solid ${theme.color.neutral.base};
        color: ${theme.color.success.base};
        & svg {
          transition: 0.25s ease-out;
          fill: ${theme.color.success.base};
        }
        :hover:enabled {
          & svg {
            fill: ${variant === 'lightest' ? 'black' : 'white'};
          }
      `}


`

const ButtonText = styled.span`
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  font-size: 0.8rem;
  text-wrap: nowrap;
  width: 100%;
`

const BeforeIcon = styled.span<any>`
  margin-right: ${({ iconMargin }: any) => iconMargin || '1rem'};
  margin-left: ${({ iconMargin, iconMarginInside }: any) => (!iconMarginInside ? iconMargin || '1rem' : '0')};
  display: flex;
  align-items: center;
`

const AfterIcon = styled.span<any>`
  margin-right: ${({ iconMargin, iconMarginInside }: any) => (!iconMarginInside ? iconMargin || '1rem' : '0')};
  margin-left: ${({ iconMargin }: any) => iconMargin || '1rem'};
`

const LabelButton: React.FC<ButtonType> = ({
  children,
  type,
  variant,
  outline,
  small,
  onClick,
  disabled,
  beforeIcon,
  afterIcon,
  style,
  selected,
  iconMargin,
  iconMarginInside,
  textStyle,
}: ButtonType) => (
  <ButtonStyle
    style={style}
    type={type}
    variant={variant!}
    outline={outline}
    small={small}
    onClick={onClick}
    disabled={disabled}
    selected={selected}
    hasIcons={!!beforeIcon || !!afterIcon}
  >
    {beforeIcon && (
      <BeforeIcon iconMarginInside={iconMarginInside} iconMargin={iconMargin}>
        {beforeIcon}
      </BeforeIcon>
    )}
    <ButtonText style={textStyle}>{children}</ButtonText>
    {afterIcon && (
      <AfterIcon iconMarginInside={iconMarginInside} iconMargin={iconMargin}>
        {afterIcon}
      </AfterIcon>
    )}
  </ButtonStyle>
)

LabelButton.defaultProps = {
  type: 'primary',
  variant: 'base',
  small: false,
  disabled: false,
  outline: false,
}

export default LabelButton
