import React from 'react'
import { Document, Font, Page, View, Text as DefaultText, Image } from '@react-pdf/renderer'
import styled from '@react-pdf/styled-components'
import { css } from 'styled-components'
import moment from 'moment'

import useCompany from 'hooks/useCompany'

import currency from 'helpers/currency'

import RalewayRegular from 'assets/fonts/Raleway-Regular.ttf'
import RalewayBold from 'assets/fonts/Raleway-Bold.ttf'
import OswaldLight from 'assets/fonts/Oswald-Light.ttf'
import OswaldRegular from 'assets/fonts/Oswald-Regular.ttf'
import { InvoiceType, InvoiceRuleType } from 'types'

Font.register({
  family: 'OswaldRegular',
  format: 'truetype',
  src: OswaldRegular,
})

Font.register({
  family: 'OswaldLight',
  format: 'truetype',
  src: OswaldLight,
})

Font.register({
  family: 'RalewayRegular',
  format: 'truetype',
  src: RalewayRegular,
})

Font.register({
  family: 'RalewayBold',
  format: 'truetype',
  src: RalewayBold,
})

const PageWrapper = styled(Page)`
  flex-direction: row;
  background-color: #fff;
`

const Section = styled(View)`
  color: ${({ theme }: any) => theme.color.neutral.dark};
  margin: 50px;
  flex-grow: 1;
`

const Logo = styled(Image)`
  width: 200px;
  height: 20px;
  position: absolute;
`

const TRLLogo = styled(Image)`
  width: 132px;
  height: 22px;
  position: absolute;
`

const Footer = styled(View)`
  position: absolute;
  bottom: 0;
`

const Row = styled(View)`
  flex-direction: ${({ direction }: any) => (!direction ? 'row' : direction)};
  justify-content: ${({ justifyContent }: any) => (!justifyContent ? 'flex-start' : justifyContent)};
`

type ColType = {
  width: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
}

const Col = styled(View) <ColType>`
  flex-direction: column;

  ${({ width }: any) =>
    width &&
    css`
      width: ${(width / 12) * 100}%;
    `};
`

const Text = styled(DefaultText)`
  font-family: 'RalewayRegular';
  color: ${({ theme }: any) => theme.color.neutral.darker};
  font-size: 8;
  letter-spacing: 1.1;
  line-height: 2;

  ${({ strong }: any) =>
    strong &&
    css`
      font-family: 'RalewayBold';
    `}

  ${({ align }: any) =>
    align &&
    css`
      text-align: ${align};
    `}

  ${({ small }: any) =>
    small &&
    css`
      color: ${({ theme }: any) => theme.color.neutral.base};
      font-size: 7;
    `}
`

type BoxType = {
  mb: string
  mt: string
  ml: string
  mr: string
}

const Box = styled(View) <BoxType>`
  margin-bottom: ${({ mb }: BoxType) => mb || 0};
  margin-top: ${({ mt }: BoxType) => mt || 0};
  margin-left: ${({ ml }: BoxType) => ml || 0};
  margin-right: ${({ mr }: BoxType) => mr || 0};
`

const H1 = styled(Text)`
  font-family: 'OswaldLight';
  color: ${({ theme }: any) => theme.color.primary.base};
  font-size: 25;
  text-transform: uppercase;
`

const TR = styled(View)`
  border-bottom: 1;
  border-bottom-color: ${({ theme }: any) => theme.color.neutral.lighter};
`

const PaymentText = styled(Text)`
  color: ${({ theme }: any) => theme.color.primary.base};
  font-size: 10px;
  text-transform: uppercase;
`

const PaymentRow = styled(Row)`
  border-bottom: 1;
  border-bottom-color: ${({ theme }: any) => theme.color.neutral.lighter};
  border-top: 1;
  border-top-color: ${({ theme }: any) => theme.color.neutral.lighter};
  padding-top: 7px;
`

type PaymentReceiptProps = {
  invoice: InvoiceType
  theme: any
}

const PaymentReceipt: React.FC<any> = ({ theme, invoice }: PaymentReceiptProps) => {
  const { logo, company, BIC, IBAN, KVK, BTW, short } = useCompany()
  if (!invoice) {
    return null
  }

  return (
    <Document>
      <PageWrapper size="A4">
        <Section theme={theme}>
          {short === 'trl' ? <TRLLogo src={logo} /> : <Logo src={logo} />}

          <Text theme={theme} align="right" small>
            {company}
          </Text>
          <Text theme={theme} align="right" small>
            Onderwijsboulevard 52
          </Text>
          <Text theme={theme} align="right" small>
            5223 DH 's-Hertogenbosch
          </Text>
          <Text theme={theme} align="right" small>
            Nederland
          </Text>

          <Box mt="20px" mb="20px">
            <Row>
              <Col width={3}>
                <Text theme={theme} strong>
                  Ter attentie van:
                </Text>
              </Col>

              <Col>
                {invoice.name && <Text theme={theme}>{invoice.name}</Text>}
                {invoice.street && <Text theme={theme}>{invoice.street}</Text>}
                {invoice.postalCode && <Text theme={theme}>{invoice.postalCode}</Text>}
                {invoice.country && <Text theme={theme}>{invoice.country}</Text>}
              </Col>
            </Row>
          </Box>

          <Row>
            <Col width={3}>
              <Text theme={theme} strong>
                Factuurnummer:
              </Text>
            </Col>
            <Text theme={theme}>{invoice.invoiceNumber}</Text>
          </Row>
          <Row>
            <Col width={3}>
              <Text theme={theme} strong>
                Factuurdatum:
              </Text>
            </Col>
            <Text theme={theme}>{moment(invoice.invoiceDate).format('DD-MM-YYYY')}</Text>
          </Row>
          <Row>
            <Col width={3}>
              <Text theme={theme} strong>
                Relatienummer:
              </Text>
            </Col>
            <Text theme={theme}>{invoice.debtorNumber}</Text>
          </Row>

          <Box mt="30px" mb="30px">
            <H1 theme={theme}>Factuur</H1>
          </Box>

          <TR theme={theme}>
            <Row>
              <Col width={5}>
                <Text theme={theme} strong>
                  Omschrijving
                </Text>
              </Col>

              <Col width={1}>
                <Text theme={theme} align="right" strong>
                  Aantal
                </Text>
              </Col>

              <Col width={2}>
                <Text theme={theme} align="right" strong>
                  Prijs (p.p.)
                </Text>
              </Col>

              <Col width={2}>
                {(!!invoice.taxAmountHigh || !!invoice.taxAmountLow) && (
                  <Text theme={theme} align="right" strong>
                    BTW
                  </Text>
                )}
              </Col>

              <Col width={2}>
                <Text theme={theme} align="right" strong>
                  Totaalprijs
                </Text>
              </Col>
            </Row>
          </TR>

          <TR theme={theme}>
            <Box mt="10px" mb="5px">
              {invoice.invoiceRules.map((invoiceRule: InvoiceRuleType, index: number) => (
                <Row key={index}>
                  <Col width={5}>
                    <Box mr="10px">
                      <Text theme={theme}>{invoiceRule.description}</Text>
                    </Box>
                  </Col>

                  <Col width={1}>
                    <Text theme={theme} align="right">
                      {invoiceRule.units}
                    </Text>
                  </Col>

                  <Col width={2}>
                    {invoiceRule.unitAmount && (
                      <Text theme={theme} align="right">
                        {currency.format(invoiceRule.unitAmount)}
                      </Text>
                    )}
                  </Col>

                  <Col width={2}>
                    {invoiceRule.taxAmount ? (
                      <Text theme={theme} align="right">
                        {currency.format(invoiceRule.taxAmount)}
                      </Text>
                    ) : (
                      <span></span>
                    )}
                  </Col>

                  <Col width={2}>
                    {invoiceRule.unitAmount && (
                      <Text theme={theme} align="right">
                        {currency.format(invoiceRule.totalAmount)}
                      </Text>
                    )}
                  </Col>
                </Row>
              ))}
            </Box>
          </TR>

          <Box mt="10px" mb="5px">
            {(!!invoice.taxAmountLow || !!invoice.taxAmountLow) && (
              <Row justifyContent="flex-end">
                <Col width={2}>
                  <Text theme={theme} align="right" strong>
                    Subtotaal
                  </Text>
                </Col>
                <Col width={2}>
                  <Text theme={theme} align="right">
                    {currency.format(invoice.subTotalAmount)}
                  </Text>
                </Col>
              </Row>
            )}

            {!!invoice.taxAmountLow && (
              <Row justifyContent="flex-end">
                <Col width={2}>
                  <Text theme={theme} align="right" strong>
                    BTW (9%)
                  </Text>
                </Col>
                <Col width={2}>
                  <Text theme={theme} align="right">
                    {currency.format(invoice.taxAmountLow)}
                  </Text>
                </Col>
              </Row>
            )}

            {!!invoice.taxAmountHigh && (
              <Row justifyContent="flex-end">
                <Col width={2}>
                  <Text theme={theme} align="right" strong>
                    BTW (21%)
                  </Text>
                </Col>
                <Col width={2}>
                  <Text theme={theme} align="right">
                    {currency.format(invoice.taxAmountHigh)}
                  </Text>
                </Col>
              </Row>
            )}

            <Row justifyContent="flex-end">
              <Col width={2}>
                <Text theme={theme} align="right" strong>
                  Totaal
                </Text>
              </Col>
              <Col width={2}>
                <Text theme={theme} align="right">
                  {currency.format(invoice.totalAmount)}
                </Text>
              </Col>
            </Row>

            {invoice.remainingAmount > 0 && (
              <Row justifyContent="flex-end">
                <Col width={2}>
                  <Text theme={theme} align="right" strong>
                    Reeds betaald
                  </Text>
                </Col>
                <Col width={2}>
                  <Text theme={theme} align="right">
                    {currency.format(invoice.depositAmount)}
                  </Text>
                </Col>
              </Row>
            )}

            {invoice.remainingAmount > 0 && (
              <Row justifyContent="flex-end">
                <Col width={2}>
                  <Text theme={theme} align="right" strong>
                    Totaal resterend
                  </Text>
                </Col>
                <Col width={2}>
                  <Text theme={theme} align="right">
                    {currency.format(invoice.remainingAmount)}
                  </Text>
                </Col>
              </Row>
            )}
          </Box>

          {invoice.remainingAmount > 0 ? (
            <Row justifyContent="flex-end">
              <Col width={4}>
                <PaymentRow theme={theme} justifyContent="flex-end">
                  <Col width={6}>
                    <PaymentText theme={theme} align="right" strong>
                      Te betalen
                    </PaymentText>
                  </Col>
                  <Col width={6}>
                    <PaymentText theme={theme} align="right" strong>
                      {currency.format(invoice.remainingAmount)}
                    </PaymentText>
                  </Col>
                </PaymentRow>
              </Col>
            </Row>
          ) : (
            <Row justifyContent="flex-end">
              <Col width={5}>
                <PaymentRow theme={theme} justifyContent="flex-end">
                  <Col width={6}>
                    <PaymentText theme={theme} align="right" strong>
                      Totaal voldaan
                    </PaymentText>
                  </Col>
                  <Col width={6}>
                    <PaymentText theme={theme} align="right" strong>
                      {currency.format(invoice.totalAmount)}
                    </PaymentText>
                  </Col>
                </PaymentRow>
              </Col>
            </Row>
          )}

          {invoice.remainingAmount > 0 && (
            <Box mt="20px">
              <Row justifyContent="flex-end">
                <Col width={9}>
                  <Text theme={theme} small align="right">
                    Hartelijk dank voor de betaling van
                    <Text theme={theme} small strong>
                      &nbsp;&nbsp;{currency.format(invoice.depositAmount)}&nbsp;&nbsp;
                    </Text>
                    Uw geboekte services kunt u via de link van de offerte inzien. U ontvangt automatisch bericht voor
                    de restantbetaling van
                    <Text theme={theme} small strong>
                      &nbsp;&nbsp;{currency.format(invoice.remainingAmount)}&nbsp;
                    </Text>
                    . Zorg ervoor dat deze uiterlijk vijf weken voor vertrek is voldaan.
                  </Text>
                </Col>
              </Row>
            </Box>
          )}

          <Footer>
            <Row>
              <Text theme={theme} small strong>
                IBAN: {IBAN}
              </Text>
              <Box mr="10px" ml="10px">
                <Text theme={theme} small>
                  |
                </Text>
              </Box>
              <Text theme={theme} small strong>
                BIC: {BIC}
              </Text>
              <Box mr="10px" ml="10px">
                <Text theme={theme} small>
                  |
                </Text>
              </Box>
              <Text theme={theme} small strong>
                BTW: {BTW}
              </Text>
              <Box mr="10px" ml="10px">
                <Text theme={theme} small>
                  |
                </Text>
              </Box>
              <Text theme={theme} small strong>
                KvK: {KVK}
              </Text>
            </Row>
          </Footer>
        </Section>
      </PageWrapper>
    </Document>
  )
}

export default PaymentReceipt
