import React from 'react'
import { CheckoutTabButton } from './CheckoutTabButton'

export type CheckoutTabType = {
  name: string
  path?: string
  disabledNextTab?: () => boolean | undefined
  hidden?: boolean
  onClick?: () => void
}

type CheckoutTabsProps = {
  tabs: CheckoutTabType[]
}
const CheckoutTabs: React.FC<CheckoutTabsProps> = ({ tabs }: CheckoutTabsProps) => {
  let tabIndex = tabs
    .filter(({ hidden }) => !hidden)
    .findIndex((tab) => tab.path && window.location.pathname.endsWith(tab.path.split('/').slice(-2).join('/')))
  tabIndex = tabIndex === -1 ? 0 : tabIndex
  const currentTab: CheckoutTabType = tabs.filter(({ hidden }) => !hidden)[tabIndex]
  const disabled = currentTab.disabledNextTab ? currentTab.disabledNextTab() : false
  return (
    <>
      {tabs
        .filter(({ hidden }) => !hidden)
        .map((tab: CheckoutTabType, index: number) => {
          return (
            <CheckoutTabButton
              key={index}
              disabled={disabled && index > tabIndex}
              to={tab.path}
              active={index === tabIndex}
            >
              {tab.name}
            </CheckoutTabButton>
          )
        })}
    </>
  )
}

export { CheckoutTabs }
