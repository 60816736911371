import React from 'react'
import styled from 'styled-components/macro'

import { InfoIcon } from 'styles/Icon'
import Box from 'styles/Box'

type AlertProps = {
  children: any
}

const AlertWrapper = styled.div`
  background-color: ${({ theme }) => theme.color.neutral.lighter};
  color: ${({ theme }) => theme.color.neutral.dark};
  width: 100%;
  word-break: break-word;
  display: flex;
  align-items: center;
  font-weight: 500;
`

const AlertInner = styled.div`
  width: 100%;
  padding: 1rem 1.5rem;
  word-break: break-word;
  display: flex;
  align-items: center;
`

const Alert: React.FC<any> = ({ children }: AlertProps) => (
  <AlertWrapper>
    <AlertInner>
      <Box m={0} mr="1rem" inline>
        <InfoIcon color="neutral" variant="base" />
      </Box>
      {children}
    </AlertInner>
  </AlertWrapper>
)

export default Alert
