import React, { SVGProps } from 'react'
import styled from 'styled-components/macro'

export type IconType = {
  color?: 'primary' | 'neutral' | 'success' | 'danger'
  variant?: 'base' | 'dark' | 'light' | 'lighter'
  size?: string
  style?: React.CSSProperties
  onClick?: () => void
  children?: React.ReactNode
  transparant?: boolean
}

const SVG = styled.svg<IconType>`
  fill: ${({ theme, color, variant, transparant }) => (color && !transparant ? theme.color[color!][variant!] : 'white')};
  color: ${({ theme, color, variant }) => (color ? theme.color[color!][variant!] : 'white')};
  width: ${({ size }) => size};
  height: ${({ size }) => size};
`

const Icon = ({ children, onClick, ...props }: any): any => (
  <SVG onClick={onClick} {...props}>
    {children}
  </SVG>
)

Icon.defaultProps = {
  size: '1.4rem',
  variant: 'base',
}

export default Icon
