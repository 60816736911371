import React from 'react'

import Icon, { IconType } from '../Icon'

const InvoiceIcon: React.FC<IconType> = (props) => (
  <Icon {...props} viewBox="0 0 24 24" width="24" height="24" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.3624147,0.369133668 C14.8752506,0.369133668 15.2979219,0.755173858 15.355687,1.25251254 L15.3624147,1.36913367 L15.3624147,6.09396315 C15.3624147,6.1740256 15.4143375,6.24196085 15.4863466,6.26593245 L15.5436221,6.27517052 L20.2684516,6.27517052 C20.8207363,6.27517052 21.2684516,6.72288577 21.2684516,7.27517052 C21.2684516,7.78800636 20.8824114,8.21067768 20.3850727,8.26844279 L20.2684516,8.27517052 L15.5436221,8.27517052 C14.3913505,8.27517052 13.4477642,7.38168291 13.3678914,6.24973607 L13.3624147,6.09396315 L13.3624147,1.36913367 C13.3624147,0.816848918 13.81013,0.369133668 14.3624147,0.369133668 Z" />
    <path d="M14.3624147,0.369133668 L6.09396315,0.369133668 C4.23695277,0.369133668 2.73154841,1.87453802 2.73154841,3.73154841 L2.73154841,20.2684516 C2.73154841,22.125462 4.23695277,23.6308663 6.09396315,23.6308663 L17.9060369,23.6308663 C19.7630472,23.6308663 21.2684516,22.125462 21.2684516,20.2684516 L21.2684516,7.27517052 C21.2684516,7.00995403 21.1630948,6.75560012 20.9755584,6.56806374 L15.0695215,0.662026887 C14.8819851,0.474490509 14.6276312,0.369133668 14.3624147,0.369133668 Z M13.948,2.369 L19.268,7.689 L19.2684516,20.2684516 C19.2684516,21.0208925 18.6584777,21.6308663 17.9060369,21.6308663 L6.09396315,21.6308663 C5.34152226,21.6308663 4.73154841,21.0208925 4.73154841,20.2684516 L4.73154841,3.73154841 C4.73154841,2.97910752 5.34152226,2.369 6.09396315,2.369 L13.948,2.369 Z" />
    <path d="M9.63758526,5.09396315 C10.18987,5.09396315 10.6375853,5.5416784 10.6375853,6.09396315 C10.6375853,6.60679899 10.2515451,7.02947031 9.75420639,7.08723542 L9.63758526,7.09396315 L8.45637789,7.09396315 C7.90409314,7.09396315 7.45637789,6.6462479 7.45637789,6.09396315 C7.45637789,5.58112731 7.84241808,5.15845599 8.33975676,5.10069088 L8.45637789,5.09396315 L9.63758526,5.09396315 Z" />
    <path d="M15.5436221,11 C16.0959069,11 16.5436221,11.4477153 16.5436221,12 C16.5436221,12.5128358 16.1575819,12.9355072 15.6602432,12.9932723 L15.5436221,13 L8.45637789,13 C7.90409314,13 7.45637789,12.5522847 7.45637789,12 C7.45637789,11.4871642 7.84241808,11.0644928 8.33975676,11.0067277 L8.45637789,11 L15.5436221,11 Z" />
    <path d="M15.5436221,16.9060369 C16.0959069,16.9060369 16.5436221,17.3537521 16.5436221,17.9060369 C16.5436221,18.4188727 16.1575819,18.841544 15.6602432,18.8993091 L15.5436221,18.9060369 L13.1812074,18.9060369 C12.6289226,18.9060369 12.1812074,18.4583216 12.1812074,17.9060369 C12.1812074,17.393201 12.5672476,16.9705297 13.0645862,16.9127646 L13.1812074,16.9060369 L15.5436221,16.9060369 Z" />
  </Icon>
)

export default InvoiceIcon
