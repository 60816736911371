import moment from 'moment'
import { ItineraryContactType } from 'types'

export const isRequired = (value: any) => (value ? undefined : 'Dit veld is verplicht')

export const isEmailAddress = (value: any) => {
  if (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
    return 'Het ingevoerde e-mailadres is ongeldig'
  }
  return null
}

export const isNumber = (value: any) => {
  if (value && !value.match(/^[0-9]*$/)) {
    return 'De ingevoerde waarde mag enkel uit getallen bestaan'
  }
  return null
}

export const isValidDate = (value: any) => {
  const isValid = moment(value, ['DD-MM-YYYY'], true).isValid()

  if (!isValid) {
    return 'Dit veld moet een geldige datum bevatten (bijv. "31-12-1987")'
  }
  return null
}

export const isSingleName = (value: any) => {
  if (value.indexOf(' ') >= 0) {
    return 'Gelieve enkel uw eerste voornaam in te vullen'
  }
}

export const composeValidators = (...validators: any) => (value: any) =>
  validators.reduce((error: string, validator: any) => error || validator(value), undefined)

/*  contactId: string
  firstname: string
  middlename: string
  lastname: string
  birthdate: string
  nationality: string
  documentNumber: string
  note: string
  email: string
  documentExpirationDate: string
  gender?: 'Man' | 'Vrouw' */

export const quickValidateContact = (contact: ItineraryContactType) => {
  const { lastname, firstname, gender, nationality } = contact
  return (lastname && firstname && gender && nationality)
}
