import React from 'react'
import styled from 'styled-components/macro'
import { Container as ContainerDefault } from 'react-grid-system'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { useDispatch, useSelector } from 'react-redux'

import { ApplicationState } from 'reducers'

import { hideNotification } from 'actions/Notifications'
import { NotificationType } from 'reducers/Notifications'

import Toast from 'styles/Toast'

const Container = styled(ContainerDefault)`
  display: flex;
  justify-content: center;
  position: fixed !important;
  z-index: 9000;
  bottom: 0;
`

const NotificationList = styled(TransitionGroup)`
  .notification-enter {
    transform: translateY(100%);
  }
  .notification-enter-active {
    transform: none;
    transition: transform 250ms ease;
  }

  .notification-exit {
    opacity: 1;
  }
  .notification-exit-active {
    opacity: 0;
    transition: opacity 250ms ease;
  }
`

const Notifications = () => {
  const { notifications } = useSelector<ApplicationState, ApplicationState['notifications']>(
    (state) => state.notifications
  )
  const dispatch = useDispatch()

  return (
    <Container>
      <NotificationList>
        {notifications.map((notification: NotificationType) => (
          <CSSTransition key={notification.id} timeout={500} classNames="notification">
            <Toast {...notification} onClose={() => dispatch(hideNotification(notification.id))}>
              {notification.content}
            </Toast>
          </CSSTransition>
        ))}
      </NotificationList>
    </Container>
  )
}

export default Notifications
