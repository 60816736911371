import React, { useCallback, useState } from 'react'
import styled from 'styled-components'
import Moment from 'react-moment'
import { Row, Col } from 'react-grid-system'
import { Swipeable } from 'react-swipeable'
import { TransitionGroup, CSSTransition } from 'react-transition-group'

import Button from 'styles/Button'
import { H2, H3 } from 'styles/Heading'
import Box from 'styles/Box'
import { ArrowLeftIcon, ArrowRightIcon, CarIcon, HotelIcon, DinerIcon, InfoIcon, CompassIcon } from 'styles/Icon'
import TransparentButton from 'styles/TransparentButton'

import HumanJoin from 'components/HumanJoin'

import { DayType, ExcursionOptionType, ExcursionType, QuoteDataType } from 'types'

import 'moment/locale/nl'
import { formatList, uppercaseFirst } from 'helpers/formatter'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'reducers'

type DayProgramProps = {
  days: DayType[]
  excursions: ExcursionType[]
  hideDayProgramOptions: boolean
}

// type DayType = {
//   dayDate: Date
//   locations: string[]
//   distance: number
//   accommodation: string
//   diner: string
//   note: string
//   description: string
//   link: string
//   image: string
// }

const DayImage = styled.div<any>`
  height: 800px;
  position: relative;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: ${({ source }) => `url('${source}')`};
`

const DayCard = styled.div`
  padding: 9rem;

  @media (max-width: 1200px) {
    padding: 6rem;
  }

  @media (max-width: 769px) {
    padding: 0;
    padding-bottom: 6rem;
  }
`

const CSSTransitionStyle = styled(CSSTransition)`
  transition: opacity 0.5s;

  &.day-enter {
    opacity: 0;
    position: absolute;
    width: 100%;
  }

  &.day-enter-active {
    opacity: 1;
  }

  &.day-exit {
    opacity: 1;
  }

  &.day-exit-active {
    opacity: 0;
  }
`

const SubItem = styled.div<any>`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.color.neutral.base};
  font-weight: 600;
  font-size: 0.9rem;
  margin: 0.5rem 0;
  line-height: 1.4rem;
`

const dateToUpperCase = (date: string) => {
  return date.charAt(0).toUpperCase() + date.slice(1)
}

const DateLabel = styled.strong<any>``

const DayProgram: any = ({ days, excursions, hideDayProgramOptions }: DayProgramProps) => {
  const [dayIndex, setDayIndex] = useState(0)
  const { itinerary }: QuoteDataType = useSelector((state: ApplicationState) => state.quote.data)

  const { dayDate, locations, description, distance, travelDuration, accommodation, diner, note, link, image } = days[
    dayIndex
  ]

  const options: ExcursionOptionType[] = excursions
    .filter((excursion: ExcursionType) => excursion.startDate === dayDate)
    .map((excursion: ExcursionType) => excursion.options)
    .flat()
    .filter(({ isInsurance }) => !isInsurance)

  const includedOptionsString: string = formatList(
    options.filter(({ inclusive, selected }) => inclusive || selected).map(({ title }) => title)
  )
  const excludedOptionsString: string = formatList(
    options.filter(({ inclusive, selected }) => !inclusive && !selected).map(({ title }) => title)
  )

  const addDayIndex = useCallback(() => {
    setDayIndex((currentIndex) => currentIndex + 1)
  }, [])

  const removeDayIndex = useCallback(() => {
    setDayIndex((currentIndex) => currentIndex - 1)
  }, [])

  return (
    <Swipeable
      onSwipedLeft={() => {
        if (dayIndex < days.length - 1) {
          addDayIndex()
        }
      }}
      onSwipedRight={() => {
        if (dayIndex) {
          removeDayIndex()
        }
      }}
      delta={50}
      trackMouse
    >
      <div>
        <Row nogutter>
          <Col sm={12} lg={6}>
            <DayCard>
              <Box m={0} mr="1.5rem" inline>
                <H2>
                  Dag {dayIndex + 1} van {days.length}
                </H2>
              </Box>

              {days?.length > 1 && (
                <>
                  <TransparentButton onClick={removeDayIndex} disabled={dayIndex === 0}>
                    <ArrowLeftIcon
                      color={dayIndex === 0 ? 'neutral' : 'primary'}
                      variant={dayIndex === 0 ? 'light' : 'base'}
                    />
                  </TransparentButton>

                  <TransparentButton onClick={addDayIndex} disabled={dayIndex === days.length - 1}>
                    <ArrowRightIcon
                      color={dayIndex !== days.length - 1 ? 'primary' : 'neutral'}
                      variant={dayIndex !== days.length - 1 ? 'base' : 'light'}
                    />
                  </TransparentButton>
                </>
              )}

              {!itinerary?.hideDates && !!dayDate && (
                <div>
                  <Moment filter={dateToUpperCase} element={DateLabel} format="dd. D MMMM YYYY" locale="nl">
                    {dayDate}
                  </Moment>
                </div>
              )}

              {locations && locations.length && (
                <Box m={0} mt="2rem">
                  <H3>
                    <HumanJoin list={locations} />
                  </H3>
                </Box>
              )}

              {!!description && (
                <Box m={0} mt="2rem" mb="1rem">
                  <p>{description}</p>
                </Box>
              )}

              {!!link && (
                <a href={link} target="_blank" rel="noopener noreferrer">
                  <Button type="primary" afterIcon={<ArrowRightIcon color="primary" size="1rem" />} outline>
                    Lees meer
                  </Button>
                </a>
              )}

              <Box mt="2rem" ml={0}>
                {distance && distance > 0 && (
                  <SubItem>
                    <Box m="0" ml={0} mr="1rem" inline>
                      <CarIcon color="neutral" variant="base" />
                    </Box>
                    <span>
                      {distance} kilometer
                      {!!travelDuration && ` (${travelDuration} uur)`}
                    </span>
                  </SubItem>
                )}
                {accommodation && (
                  <SubItem>
                    <Box m="0" ml={0} mr="1rem" inline>
                      <HotelIcon color="neutral" variant="base" />
                    </Box>
                    {accommodation}
                  </SubItem>
                )}

                {(!!includedOptionsString || !!excludedOptionsString) && !hideDayProgramOptions && (
                  <SubItem>
                    <Box vAlignSelf="flex-start" m="0" ml={0} mr="1rem" inline>
                      <CompassIcon color="neutral" variant="base" />
                    </Box>

                    {includedOptionsString && `Inclusief: ${includedOptionsString}`}
                    {includedOptionsString && excludedOptionsString && <br />}
                    {excludedOptionsString && `Optioneel: ${excludedOptionsString}`}
                  </SubItem>
                )}

                {diner && (
                  <SubItem>
                    <Box m="0" ml={0} mr="1rem" inline>
                      <DinerIcon color="neutral" variant="base" />
                    </Box>
                    {diner}
                  </SubItem>
                )}
                {note && (
                  <SubItem>
                    <Box m="0" ml={0} mr="1rem" inline>
                      <InfoIcon color="neutral" variant="base" />
                    </Box>
                    {uppercaseFirst(note)}
                  </SubItem>
                )}
              </Box>
            </DayCard>
          </Col>
          <Col sm={12} lg={6}>
            <TransitionGroup>
              <CSSTransitionStyle classNames="day" key={dayIndex} timeout={500} unmountOnExit>
                <Box m={0}>{image && <DayImage source={image} />}</Box>
              </CSSTransitionStyle>
            </TransitionGroup>
          </Col>
        </Row>
      </div>
    </Swipeable>
  )
}

export default DayProgram
