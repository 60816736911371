import React from 'react'

import Icon, { IconType } from '../Icon'

const DinerIcon: React.FC<IconType> = (props) => (
  <Icon
    {...props}
    viewBox="0 0 24 24"
    width="24"
    height="24"
    preserveAspectRatio="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g transform="translate(-1.000000, 0.000000)">
      <path d="M22.8,0 L22.8,24 L20.4,24 L20.4,14.4 L16.8,14.4 L16.8,6 C16.8,2.6862915 19.4862915,0 22.8,0 Z" />
      <path d="M8.4,14.28 L8.4,24 L6,24 L6,14.28 C3.20651387,13.708832 1.20034898,11.25128 1.2,8.4 L1.2,1.2 L3.6,1.2 L3.6,9.6 L6,9.6 L6,1.2 L8.4,1.2 L8.4,9.6 L10.8,9.6 L10.8,1.2 L13.2,1.2 L13.2,8.4 C13.199651,11.25128 11.1934861,13.708832 8.4,14.28 Z" />
    </g>
  </Icon>
)

export default DinerIcon
