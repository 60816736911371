import styled from 'styled-components'

const TransparentButton = styled.button<any>`
  background: none;
  border: none;
  outline: none;
  margin: 0;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};

  transition: 0.3s;

  &:hover {
    opacity: 0.6;
  }
`

export default TransparentButton
