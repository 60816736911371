import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { createNotification } from 'actions/Notifications'
import currency from 'helpers/currency'
import { QuoteDataType } from 'types'
import { ApplicationState } from 'reducers'

const usePrevious = (value: number) => {
  const ref = useRef(0)

  useEffect(() => {
    ref.current = value
  })

  return ref.current
}

const PriceWatcher = ({ children }: any): any => {
  const dispatch = useDispatch()

  const { itinerary }: QuoteDataType = useSelector((state: ApplicationState) => state.quote.data)

  const currentTotalCosts = itinerary?.totalCosts || 0
  const previousTotalCosts = usePrevious(currentTotalCosts || 0)

  const costPerPerson = currentTotalCosts / (itinerary?.travellers || 1)

  useEffect(() => {
    if (!previousTotalCosts || previousTotalCosts === currentTotalCosts || itinerary?.hidePrices) {
      return
    }

    dispatch(
      createNotification({
        content: (
          <span>
            De totaalprijs van uw reis is gewijzigd naar <strong>{currency.format(costPerPerson)} p.p.</strong>
          </span>
        ),
        status: 'success',
        timeout: 5000,
      })
    )
  })

  return children
}

export default PriceWatcher
