interface StringMap {
  [key: string]: string
}

const classNames: StringMap = {
  EC: 'Economy Class',
  ECC: 'Economy Comfort Class',
  BC: 'Business Class',
  FC: 'First Class',
}

export default classNames
