import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Moment from 'react-moment'

import styled from 'styled-components'

import { Hidden, Row, Col, useScreenClass } from 'react-grid-system'

import { createNotification } from 'actions/Notifications'
import { selectExcursion, fetchQuote, fetchHighlightedExcursionsAndInsurances } from 'actions/Quote'
import currency from 'helpers/currency'
import useCompany from 'hooks/useCompany'

import Text from 'styles/Text'
import Box from 'styles/Box'
import { sameDay } from 'helpers/sameDay'
import { H2 } from 'styles/Heading'
import { AddIcon, InfoIcon } from 'styles/Icon'
import Alignment from 'styles/Alignment'
import Button from 'styles/Button'

import { ExcursionOptionType, QuoteDataType } from 'types'

import { ApplicationState } from 'reducers'
import Tooltip from 'styles/Tooltip'
import { CheckoutCard } from 'components/Checkout/CheckoutCard/CheckoutCard'
import LabelButton from 'styles/LabelButton'
import SelectedButton from 'styles/SelectedButton/SelectedButton'
import Dropdown from 'components/Dropdown'
import Separator from 'styles/Separator'

interface CheckoutExcursionOptionProps {
  option: ExcursionOptionType
}

const ImageCol = styled(Col)`
  overflow: hidden;
  display: flex;
  align-items: center;
  padding: 0px !important;
  justify-content: center;
  img {
    overflow: hidden;
    object-fit: cover;
    height: 100%;
    min-width: 100%;
  }

  @media (max-width: 992px) {
    height: 300px;
  }
`

const NoteWrapper = styled.div<any>`
  display: flex;
  align-items: center;
  padding: 1rem 2rem;
  line-height: 1.6rem;
  color: ${({ theme }) => theme.color.neutral.base};
  background-color: ${({ theme }) => theme.color.neutral.lighter};
  font-weight: 600;
  font-size: 0.9rem;
  margin-left: -1rem;
  margin-right: -1rem;
`

const CheckoutExcursionOption: React.FC<CheckoutExcursionOptionProps> = ({
  option: {
    id,
    image,
    title,
    description,
    sellAmount,
    participants,
    inclusive,
    selected,
    website,
    itineraryService,
    adjustParticipants,
    date,
    duration,
    note,
  },
}: CheckoutExcursionOptionProps) => {
  const dispatch = useDispatch()
  const screenClass = useScreenClass()
  const { itineraryId }: any = useParams()
  const { company } = useCompany()
  const isDesktop = ['md', 'lg', 'xl', 'xxl'].includes(screenClass)
  const { itinerary }: QuoteDataType = useSelector((state: ApplicationState) => state.quote.data)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const onSelectExcursion = async (participantCount: number = participants) => {
    try {
      setIsSubmitting(true)
      await dispatch(
        selectExcursion(
          itineraryService,
          id,
          true,
          participantCount,
          adjustParticipants && participantCount !== participants
        )
      )
      setIsSubmitting(false)
      await dispatch(fetchHighlightedExcursionsAndInsurances(itineraryId, company))
      await dispatch(fetchQuote(itineraryId, company))
    } catch (e) {
      dispatch(
        createNotification({
          content: 'Excursie selectie mislukt. Neem contact met ons op om uw voorkeur door te geven.',
          status: 'danger',
        })
      )
    }
  }
  const startDate = date ? new Date(date) : null
  const endDate = startDate && duration ? new Date(startDate.getTime() + (duration - 1)) : null

  const startDayIndex = itinerary?.days.findIndex(({ dayDate }) => {
    if (!startDate) return false
    return sameDay(new Date(dayDate), new Date(startDate))
  })

  const endDayIndex = itinerary?.days.findIndex(({ dayDate }) => {
    if (!endDate) return false
    return sameDay(new Date(dayDate), new Date(endDate))
  })

  const daysDifference = (endDayIndex ?? -1) - (startDayIndex ?? -1)

  function saveParticipantCount(count: number | any) {
    if (typeof count === 'number') {
      onSelectExcursion(count)
    } else {
      onSelectExcursion()
    }
  }
  return (
    <CheckoutCard>
      <Row style={{ minHeight: '250px' }}>
        <ImageCol lg={3}>
          <img src={image} alt="" />
        </ImageCol>
        <Col lg={9} style={{ padding: '2rem' }}>
          <Row>
            <Col>
              <Hidden xs sm md>
                <Text color="neutral" fontWeight="bold" style={{ marginBottom: '1rem' }}>
                  {title}
                  {!itinerary?.hideDates && startDate && endDate && (
                    <>
                      &nbsp;| <Moment format="D MMMM">{startDate}</Moment>
                      {(duration ?? 0) > 1 && (
                        <>
                          <span> - </span>
                          <Moment format="D MMMM YYYY">{endDate}</Moment>
                          <span> </span>
                          <Text color="neutral" variant="light" inline>
                            ({duration ?? 0} dag{duration !== 1 && 'en'})
                          </Text>
                        </>
                      )}
                    </>
                  )}
                  {itinerary?.hideDates && startDate && endDate && (
                    <>
                      &nbsp;| Dag {startDayIndex}
                      {daysDifference > 1 && (
                        <>
                          <span> - </span>
                          Dag {endDayIndex}
                          <span> </span>
                          <Text color="neutral" variant="light" inline>
                            ({daysDifference} dag{daysDifference !== 1 && 'en'})
                          </Text>
                        </>
                      )}
                    </>
                  )}
                </Text>
              </Hidden>
              <Hidden lg xl xxl>
                <Box m={0} mb="1rem">
                  <H2>{title}</H2>
                </Box>

                <Text color="neutral" fontWeight="bold" style={{ marginBottom: '1rem' }}>
                  {!itinerary?.hideDates && startDate && endDate && (
                    <>
                      <Moment format="D MMMM">{startDate}</Moment>
                      {(duration ?? 0) > 1 && (
                        <>
                          <span> - </span>
                          <Moment format="D MMMM YYYY">{endDate}</Moment>
                          <span> </span>
                          <Text color="neutral" variant="light" inline>
                            ({duration ?? 0} dag{duration !== 1 && 'en'})
                          </Text>
                        </>
                      )}
                    </>
                  )}
                  {itinerary?.hideDates && startDate && endDate && (
                    <>
                      Dag {startDayIndex}
                      {daysDifference > 1 && (
                        <>
                          <span> - </span>
                          Dag {endDayIndex}
                          <span> </span>
                          <Text color="neutral" variant="light" inline>
                            ({daysDifference} dag{daysDifference !== 1 && 'en'})
                          </Text>
                        </>
                      )}
                    </>
                  )}
                </Text>
              </Hidden>

              <Box m={0} mb="1rem">
                <Text color="neutral">{description}</Text>
              </Box>

              {!!website && (
                <Box m={0} mb="1rem">
                  <a href={website} target="blank">
                    <Text textDecoration="underline" color="neutral" inline>
                      {'Meer info >'}
                    </Text>
                  </a>
                </Box>
              )}
              {!!inclusive && (
                <Box m={0} mb="1rem">
                  <Text color="success">Deze excursie is bij het reisaanbod inbegrepen</Text>
                </Box>
              )}

              {!inclusive && itinerary?.status === 'booked' && !selected && (
                <Box m={0} mb="1rem">
                  <Text color="danger">Deze excursie is niet bij het reisaanbod inbegrepen</Text>
                </Box>
              )}
              {!!sellAmount && !inclusive && itinerary?.status === 'open' && (
                <Box m={0} mb="1rem">
                  <Text color="neutral" fontWeight="bold">
                    {selected ? '-' : '+'} {currency.format(sellAmount / (participants || 1))} per persoon
                  </Text>
                </Box>
              )}
              <Hidden lg xl xxl>
                <Separator></Separator>
              </Hidden>
            </Col>
          </Row>
          <Row>
            <Col>
              {!inclusive && (
                <>
                  <Alignment justify={isDesktop ? 'flex-end' : 'center'}>
                    {!adjustParticipants && (
                      <>
                        {selected && itinerary?.status === 'open' && (
                          <SelectedButton
                            style={{ maxWidth: 'fit-content' }}
                            disabled={isSubmitting}
                            onClick={saveParticipantCount}
                          />
                        )}
                        {!selected && itinerary?.status === 'open' && (
                          <LabelButton
                            style={{ marginTop: '1rem' }}
                            disabled={isSubmitting}
                            onClick={saveParticipantCount}
                            small
                          >
                            Toevoegen
                          </LabelButton>
                        )}
                      </>
                    )}

                    {adjustParticipants && (
                      <>
                        <Dropdown
                          disabled={isSubmitting}
                          participantCount={selected ? participants : 0}
                          setParticipantCount={saveParticipantCount}
                          width={200}
                        ></Dropdown>
                      </>
                    )}

                    {!selected && itinerary?.status === 'expired' && (
                      <Tooltip content="Deze offerte is verlopen. Neem contact met uw reisadviseur op om deze offerte bij te werken.">
                        <Button
                          type="neutral"
                          variant="lighter"
                          outline
                          beforeIcon={<AddIcon color="neutral" size="1.2rem" />}
                          disabled
                          small
                        >
                          Toevoegen
                        </Button>
                      </Tooltip>
                    )}
                  </Alignment>
                </>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
      {!!note && (
        <NoteWrapper>
          <Hidden xs>
            <Box m={0} ml={0} mr="1rem" inline>
              <InfoIcon color="neutral" variant="base" />
            </Box>
          </Hidden>
          {note}
        </NoteWrapper>
      )}
    </CheckoutCard>
  )
}

export default CheckoutExcursionOption
