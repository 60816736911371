export const FETCH_QUOTE = 'FETCH_QUOTE'
export const FETCH_QUOTE_FAIL = 'FETCH_QUOTE_FAIL'
export const FETCH_QUOTE_SUCCESS = 'FETCH_QUOTE_SUCCESS'

export const CREATE_PAYMENT = 'CREATE_PAYMENT'
export const CREATE_PAYMENT_FAIL = 'CREATE_PAYMENT_FAIL'
export const CREATE_PAYMENT_SUCCESS = 'CREATE_PAYMENT_SUCCESS'

export const SELECT_FLIGHT = 'SELECT_FLIGHT'
export const SELECT_FLIGHT_FAIL = 'SELECT_FLIGHT_FAIL'
export const SELECT_FLIGHT_SUCCESS = 'SELECT_FLIGHT_SUCCESS'

export const SELECT_ACCOMMODATION = 'SELECT_ACCOMMODATION'
export const SELECT_ACCOMMODATION_FAIL = 'SELECT_ACCOMMODATION_FAIL'
export const SELECT_ACCOMMODATION_SUCCESS = 'SELECT_ACCOMMODATION_SUCCESS'

export const SELECT_EXCURSION = 'SELECT_EXCURSION'
export const SELECT_EXCURSION_FAIL = 'SELECT_EXCURSION_FAIL'
export const SELECT_EXCURSION_SUCCESS = 'SELECT_EXCURSION_SUCCESS'

export const SELECT_RENTAL = 'SELECT_RENTAL'
export const SELECT_RENTAL_FAIL = 'SELECT_RENTAL_FAIL'
export const SELECT_RENTAL_SUCCESS = 'SELECT_RENTAL_SUCCESS'

export const SELECT_SUMMARY_ITEM = 'SELECT_SUMMARY_ITEM'
export const SELECT_SUMMARY_ITEM_FAIL = 'SELECT_SUMMARY_ITEM_FAIL'
export const SELECT_SUMMARY_ITEM_SUCCESS = 'SELECT_SUMMARY_ITEM_SUCCESS'

export const UPDATE_TRAVELLERS = 'UPDATE_TRAVELLERS'
export const UPDATE_TRAVELLERS_FAIL = 'UPDATE_TRAVELLERS_FAIL'
export const UPDATE_TRAVELLERS_SUCCESS = 'UPDATE_TRAVELLERS_SUCCESS'

export const FETCH_HIGHLIGHTED_EXCURSIONS_AND_INSURANCES = 'FETCH_HIGHLIGHTED_EXCURSIONS_AND_INSURANCES'
export const FETCH_HIGHLIGHTED_EXCURSIONS_AND_INSURANCES_FAIL = 'FETCH_HIGHLIGHTED_EXCURSIONS_AND_INSURANCES_FAIL'
export const FETCH_HIGHLIGHTED_EXCURSIONS_AND_INSURANCES_SUCCESS = 'FETCH_HIGHLIGHTED_EXCURSIONS_AND_INSURANCES_SUCCESS'

export const FETCH_IDEAL_ISSUERS = 'FETCH_IDEAL_ISSUERS'
export const FETCH_IDEAL_ISSUERS_FAIL = 'FETCH_IDEAL_ISSUERS_FAIL'
export const FETCH_IDEAL_ISSUERS_SUCCESS = 'FETCH_IDEAL_ISSUERS_SUCCESS'

// Note: To fetch quote from local fixture:
//
// export const fetchQuote = (itineraryId: any) => ({
//   type: FETCH_QUOTE_SUCCESS,
//   payload: {
//     data: quote,
//   },
// })

export const createPaymentLink: any = (values: any) => ({
  type: CREATE_PAYMENT,
  payload: {
    request: {
      method: 'post',
      url: '/payment',
      data: values,
    },
  },
  options: {
    returnRejectedPromiseOnError: true,
  },
})

export const updateTravellers: any = (values: any) => ({
  type: UPDATE_TRAVELLERS,
  payload: {
    request: {
      method: 'patch',
      url: '/travellers',
      data: values,
    },
  },
  options: {
    returnRejectedPromiseOnError: true,
  },
})

export const selectFlight: any = (serviceId: string, optionId: string, luggageCount?: number, update = false) => ({
  type: SELECT_FLIGHT,
  payload: {
    request: {
      method: 'patch',
      url: '/flight',
      data: {
        serviceId,
        optionId,
        luggageCount,
        update,
      },
    },
  },
})

export const selectAccommodation: any = (serviceId: string, optionId: string) => ({
  type: SELECT_ACCOMMODATION,
  payload: {
    request: {
      method: 'patch',
      url: '/accommodation',
      data: {
        serviceId,
        optionId,
      },
    },
  },
})

export const selectExcursion: any = (
  serviceId: string,
  optionId: string,
  onCheckout: boolean,
  participantCount: number,
  update = false
) => ({
  type: SELECT_EXCURSION,
  payload: {
    request: {
      method: 'patch',
      url: '/excursion',
      data: {
        serviceId,
        optionId,
        onCheckout,
        participantCount,
        update,
      },
    },
  },
})

export const selectRental: any = (serviceId: string, optionId: string) => ({
  type: SELECT_RENTAL,
  payload: {
    request: {
      method: 'patch',
      url: '/rental',
      data: {
        serviceId,
        optionId,
      },
    },
  },
})

export const selectSummaryItem: any = (optionId: string) => ({
  type: SELECT_SUMMARY_ITEM,
  payload: {
    request: {
      method: 'patch',
      url: '/summary',
      data: {
        optionId,
      },
    },
  },
})

export const fetchHighlightedExcursionsAndInsurances: any = (itineraryId: string, companyName: string) => ({
  type: FETCH_HIGHLIGHTED_EXCURSIONS_AND_INSURANCES,
  payload: {
    request: {
      method: 'post',
      url: `/highlightedExcursionInsurance/${itineraryId}`,
      data: {
        companyName,
      },
    },
  },
})

export const fetchQuote: any = (itineraryId: string, companyName: string) => ({
  type: FETCH_QUOTE,
  payload: {
    request: {
      method: 'post',
      url: `/${itineraryId}`,
      data: {
        companyName,
      },
    },
  },
})

export const fetchIdealIssuers: any = (short: string) => ({
  type: FETCH_IDEAL_ISSUERS,
  payload: {
    request: {
      method: 'patch',
      url: `/issuers`,
      data: {
        short,
      },
    },
  },
})
